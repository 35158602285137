import React, { useState, useEffect, useCallback } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Select from "react-select";
import { getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, tableCustomStyles } from '../../../../../Common/Utility';
import { AddDeleteUpadate, fetchPostData } from '../../../../../hooks/Api';
import { Comman_changeArrayFormat } from '../../../../../Common/ChangeArrayFormat';
import { RequiredFieldSpaceNotAllow } from '../../../../../Pages/Agency/AgencyValidation/validators';
import { SSN_Field } from '../../../../../Pages/PersonnelCom/Validation/PersonnelValidation';
import { toastifySuccess } from '../../../../../Common/AlertMsg';

const MobileAlias = () => {

  const [AliasesModal, setAliasesModal] = useState(false)
  const [dob, setDob] = useState();
  const [suffixIdDrp, setSuffixIdDrp] = useState([])
  const [aliasesData, setAliasesData] = useState();
  const [nameAliasesID, setNameAliasesID] = useState('');
  const [updateStatus, setUpdateStatus] = useState(0)
  const [editval, setEditval] = useState();
  const [modalStatus, setModalstatus] = useState(false);
  const [nameID, setNameID] = useState('')

  const [value, setValue] = useState({
    'LastName': '', 'FirstName': '', 'MiddleName': '', 'SuffixID': '', 'AliasSSN': '', 'DOB': '', 'ModifiedDtTm': "",
    'NameID': '',
    'CreatedByUserFK': '',
    "ModifiedByUserFK": ""
  })

  const [errors, setErrors] = useState({
    'LastNameErrors': '',
  })

  useEffect(() => {
    get_Aliases_Data();
  }, [])

  const get_Aliases_Data = () => {
    const val = {
      'NameID': '',
    }
    fetchPostData('NameAliases_FRW/GetData_NameAliases_FRW', val).then((res) => {
      if (res) {
        setAliasesData(res)
      } else {
        setAliasesData([]);
      }
    })
  }

  useEffect(() => {
    if (nameAliasesID) {
      GetSingleData(nameAliasesID)
    }
  }, [nameAliasesID, updateStatus])

  const GetSingleData = () => {
    const val = { 'NameAliasesID': nameAliasesID }
    fetchPostData('NameAliases_FRW/GetSingleData_NameAliases_FRW', val)
      .then((res) => {
        if (res) setEditval(res)
        else setEditval()
      }
      )
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      reset()
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    GetSuffixIDDrp();

  }, [])

  useEffect(() => {

    if (nameAliasesID) {
      setValue({
        ...value,
        'NameAliasesID': nameAliasesID,
        'DOB': editval[0]?.DOB ? getShowingDateText(editval[0]?.DOB) : ' ', "LastName": editval[0]?.LastName,
        'FirstName': editval[0]?.FirstName, 'MiddleName': editval[0]?.MiddleName, 'SuffixID': editval[0]?.SuffixID,
        'AliasSSN': editval[0]?.AliasSSN,
        'ModifiedByUserFK': '',


      })
    }
    else {
      setValue({
        ...value,
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'DOB': '', 'SuffixID': '', 'AliasSSN': '', 'ModifiedByUserFK': '',
      })
    }
  }, [editval, updateStatus])

  const reset = () => {
    setValue({
      ...value,
      'LastName': '', 'FirstName': '', 'MiddleName': '', 'DOB': '', 'SuffixID': '', 'AliasSSN': '',

    }); setDob("")
    setErrors({
      'LastNameErrors': '',
    })
  }

  const check_Validation_Error = (e) => {
    if (RequiredFieldSpaceNotAllow(value.LastName)) {
      setErrors(prevValues => { return { ...prevValues, ['LastNameErrors']: RequiredFieldSpaceNotAllow(value.LastName) } })
    }
    if (SSN_Field(value.AliasSSN)) {
      setErrors(prevValues => { return { ...prevValues, ['AliasSSN']: SSN_Field(value.AliasSSN) } })
    }
  }
  const { LastNameErrors, AliasSSN } = errors

  useEffect(() => {
    if (LastNameErrors === 'true' && AliasSSN === 'true') {
      if (nameAliasesID) update_Activity()
      else Add_Type()
    }
  }, [LastNameErrors, AliasSSN])

  const GetSuffixIDDrp = () => {
    const val = {
      AgencyID: '',
    }
    fetchPostData('Suffix/GetDataDropDown_Suffix', val).then((data) => {
      if (data) {
        setSuffixIdDrp(Comman_changeArrayFormat(data, 'SuffixID', 'Description'))
      } else {
        setSuffixIdDrp([]);
      }
    })
  }
  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({
        ...value,
        [name]: e.value
      })
    } else setValue({
      ...value,
      [name]: null
    })
  }

  const handleChange = (e) => {
    if (e.target.name === "IsVerify") {
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
      });
    }
    else if (e.target.name === 'AliasSSN') {
      const ele = e.target.value.replace(/\D/g, '');
      if (ele.length === 9) {
        const cleaned = ('' + ele).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        if (match) {
          setValue({
            ...value,
            [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
          })
        }
      } else {
        ele = e.target.value.split('-').join('').replace(/\D/g, '');
        setValue({
          ...value,
          [e.target.name]: ele
        })
      }
    } else {
      setValue({
        ...value,
        [e.target.name]: e.target.value,
      });
    }
  };


  const Add_Type = (e) => {
    AddDeleteUpadate('NameAliases_FRW/Insert_NameAliases_FRW', value).then((res) => {
      get_Aliases_Data();
      setAliasesModal(false);
      setNameAliasesID('');
      toastifySuccess(res.Message);
      reset();
    })
  }

  const update_Activity = () => {
    AddDeleteUpadate('NameAliases_FRW/Update_NameAliases_FRW', value).then((res) => {
      if (res.success) {
        setNameAliasesID('');
        toastifySuccess(res.Message);
        setAliasesModal(false);
        get_Aliases_Data()
        reset();
      }
    })
  }

  const columns = [
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Action</p>,
      cell: row =>
        <Link to={'#'} onClick={() => { setEditVal(row); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1 new-button">
          <i className="fa fa-edit"></i>
        </Link>

    },
    {
      name: 'DOB',
      selector: (row) => row.DOB ? getShowingWithOutTime(row.DOB) : '',
      sortable: true
    },
    {
      name: 'Last Name',
      selector: (row) => row.LastName,
      sortable: true
    },
    {
      name: 'First Name',
      selector: (row) => row.FirstName,
      sortable: true
    },
    {
      name: 'Middle Name',
      selector: (row) => row.MiddleName,
      sortable: true
    },
    {
      name: 'Aliases SSN',
      selector: (row) => row.AliasSSN,
      sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: 0 }}>Delete</p>,
      cell: row =>
        <div className="div" style={{ position: 'absolute', right: 4 }}>
          <Link to={'#'} onClick={() => { setNameAliasesID(row.NameAliasesID); setModalstatus(true) }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1 new-button" data-toggle="modal" data-target="#myModal2">
            <i className="fa fa-trash"></i>
          </Link>
        </div>

    }

  ];
  const setEditVal = (row) => {
    setNameAliasesID(row.NameAliasesID);
    setAliasesModal(true);
  }

  const CloseModal = () => {
    setModalstatus(false)
  }

  const DeleteNameAliases = () => {
    const val = {
      'NameAliasesID': nameAliasesID,
      'DeletedByUserFK': '',
    }
    AddDeleteUpadate('NameAliases_FRW/Delete_NameAliases_FRW', val).then((res) => {
      if (res) {
        toastifySuccess(res.Message);
        get_Aliases_Data();
        setModalstatus(false)
        setNameAliasesID('')
      } else console.log("Somthing Wrong");
    })
  }


  // const colourStyles = {
  //   control: (styles) => ({
  //     ...styles, backgroundColor: "#fce9bf",
  //     height: 20,
  //     minHeight: 40,
  //     fontSize: 18,
  //     margintop: 2,
  //     boxShadow: 0,
  //   }),
  // }

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 40,
      fontSize: 18,
      margintop: 2,
      boxShadow: 0,
    }),
  };
  const startRef = React.useRef();
  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  return (
    <>
      <div className="col-md-12  pt-2">
        <div className="bg-line text-white py-1 px-2 d-flex justify-content-between align-items-center">
          <p className="p-0 m-0 d-flex align-items-center" style={{ fontSize: '18px' }}>
            Aliases
          </p>
          {
            nameID ?
              <Link to={'#'} onClick={() => { setAliasesModal(true); reset(); setNameAliasesID('') }} className="btn btn-sm bg-green text-white px-2 py-0 new-button">
                <i className="fa fa-plus"></i>
              </Link>
              :
              <></>
          }
        </div>
      </div>
      {
        AliasesModal ?
          <div className="row px-3">
            <div className="col-12">
              <div className="row">

                <div className="col-6 col-md-6 col-lg-4 mt-2 pt-1">
                  <div className="text-mobile">
                    <input type="text" className='requiredColor' name='LastName' value={value?.LastName} onChange={handleChange} required />
                    <label>Last Name</label>
                    {errors.LastNameErrors !== 'true' ? (
                      <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.LastNameErrors}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-4  mt-2 pt-1">
                  <div className="text-mobile">
                    <input type="text" name='FirstName' value={value?.FirstName} onChange={handleChange} required />
                    <label>First Name</label>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4  mt-2 pt-1">
                  <div className="text-mobile">
                    <input type="text" name='MiddleName' value={value?.MiddleName} onChange={handleChange} required />
                    <label>Middle Name</label>
                  </div>
                </div>
                <div className="col-6 col-md-6 mt-3 pt-1 col-lg-4 mb-2">
                  <div className="text-mobile">
                    <input type="text" name='AliasSSN' maxLength={9} value={value?.AliasSSN} onChange={handleChange} required />
                    <label className=''>Aliases SSN</label>
                    {errors.AliasSSN !== 'true' ? (
                      <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.AliasSSN}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-6 col-md-6  mt-3 mb-1 col-lg-4 ">
                  <div className="text__dropdwon">
                    <Select
                      name='SuffixID'
                      className='requiredColor'
                      styles={customStylesWithOutColor}
                      value={suffixIdDrp?.filter((obj) => obj.value === value?.SuffixID)}
                      isClearable
                      options={suffixIdDrp}
                      onChange={(e) => ChangeDropDown(e, 'SuffixID')}
                      placeholder="Select..."
                    />
                    <label htmlFor='' className='mt-1'>Suffix</label>
                  </div>
                </div>
                <div className="col-6 col-md-6  col-lg-4 mt-3">
                  <div className='text__dropdwon'>
                    <DatePicker
                      id='DOB'
                      name='DOB'
                      className='name-datepicker'
                      ref={startRef}
                      onKeyDown={onKeyDown}
                      onChange={(date) => { setDob(date); setValue({ ...value, ['DOB']: date ? getShowingMonthDateYear(date) : null }) }}
                      dateFormat="MM/dd/yyyy"
                      isClearable={value?.DOB ? true : false}
                      // selected={DOB}
                      selected={value?.DOB && new Date(value?.DOB)}
                      showDisabledMonthNavigation
                      autoComplete="nope"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      placeholderText={value?.DOB ? value.DOB : 'Select...'}
                      popperPlacement='left'
                    />
                    <label htmlFor="" className='pt-1'>DOB</label>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right  mt-3" >
                {
                  nameAliasesID ?
                    <button type="button" className="btn btn-lg  btn-success new-button mr-3" onClick={check_Validation_Error}>Update</button>
                    :
                    <button type="button" className="btn btn-lg  btn-success new-button mr-3" onClick={check_Validation_Error} >Save</button>
                }
                <button type="button" onClick={() => { setAliasesModal(false); setNameAliasesID(''); reset(); }} className="btn btn-lg  btn-success new-button">Close</button>
              </div>
            </div>
          </div>
          :
          <div className="col-md-12 px-2 pt-2">
            <DataTable
              columns={columns}
              data={aliasesData}
              dense
              pagination
              paginationPerPage={'5'}
              paginationRowsPerPageOptions={[5, 15, 20]}
              highlightOnHover
              customStyles={tableCustomStyles}
              responsive
              className='mobile-datatable'
              showPaginationBottom={5}
              subHeaderComponent
            />
          </div>
      }
      {
        modalStatus ?
          <div className="modal" id="myModal2" style={{ background: "rgba(0,0,0, 0.5)", transition: '0.5s' }} data-backdrop="false">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="box text-center py-5">
                  <h5 className="modal-title mt-2" id="exampleModalLabel">Do you want to Delete ?</h5>
                  <div className="btn-box mt-3">
                    <button type="button" onClick={DeleteNameAliases} className="btn btn-sm text-white" style={{ background: "#ef233c" }} >Delete</button>
                    <button type="button" onClick={() => { CloseModal(); }} className="btn btn-sm btn-secondary ml-2 " > Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <></>
      }
    </>
  )
}

export default MobileAlias