
import React, { useContext, useEffect, useState } from "react";
import { AgencyContext } from '../../../Context/Agency/Index';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Decrypt_Id_Name } from "../../Common/Utility";
import { get_LocalStoreData } from "../../../redux/actions/Agency";

const SearchSidebar = () => {

    const { nameSearchData, setUpdateCount, setIncStatus, updateCount } = useContext(AgencyContext);
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const [agencyName, setAgencyName] = useState('');

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let openPage = query?.get('page');
    var ModNo = query?.get('ModNo');
    var ArrNo = query?.get("ArrNo");

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setAgencyName(localStoreData?.Agency_Name);
        }
    }, [localStoreData]);

    // const useQuery = () => new URLSearchParams(useLocation().search);
    // let openPage = useQuery().get('page');


    // const [expand, setExpand] = useState()
    // const [plusMinus, setPlusMinus] = useState(false)
    // const [expandList, setExpandList] = useState()
    // const [plusMinus1, setPlusMinus1] = useState(false)
    // const [plusMinus2, setPlusMinus2] = useState(false)
    // const [plusMinus3, setPlusMinus3] = useState(false)
    // const [plusMinus4, setPlusMinus4] = useState(false)

    // const callReportModules = (type, val) => {
    //     if (type === 'List') {
    //         setPlusMinus1(!plusMinus1)
    //         setPlusMinus2(!plusMinus2)
    //         setPlusMinus3(!plusMinus2)
    //         setPlusMinus4(!plusMinus2)
    //         setExpand(expand ?
    //             expand[0].id === val ? { id: val } : '' : { id: val })
    //     } else {
    //         setExpandList(expandList === val ? '' : val)
    //     }
    // }

    return (
        <>
            <div className="row px-1">
                <span className='agency-sidebar'>
                    {/* <p className='agency-name-sidebar'>{openPage ? openPage : ''}</p> */}
                    <i className="fa fa-chevron-right " style={{ fontSize: '14px' }}></i>
                    <span className="ml-2" >
                        {
                            openPage === "MST-Property-Dash" ? `PRO-NO-${ModNo ? ModNo : ""}`
                                :
                                openPage === "MST-Vehicle-Dash" ? `VIC-NO-${ModNo ? ModNo : ""}`
                                    :
                                    openPage === "MST-Arrest-Dash" ? `ARST-NO-${ArrNo ? ArrNo : ""}`
                                        :
                                        openPage === "MST-Name-Dash" ? `NAME-NO-${ModNo ? ModNo : ""}` : ''
                        }
                        <p className='agency-name-sidebar'>{agencyName ? agencyName : ''}</p>
                    </span>
                </span>
            </div>
        </>
        // <>
        //     <div className="row px-1">
        //         <div className="col-12 mt-4">
        //             <input type="text" className='form-control input-fixed mt-1'
        //                 placeholder='Search By List ...' />
        //         </div>
        //     </div>
        //     {/* incident */}
        //     <li className='mt-2 pt-1'>
        //         <Link to="/incident-advanceSearch?page=incidentAdvanceSearch" className="has-arrow arrow-c" aria-expanded={plusMinus} style={{ cursor: 'pointer', background: openPage === 'incidentAdvanceSearch' ? '#EEE' : '' }} >
        //             <i className="fa fa-lock  "></i>
        //             <span>Incident</span>
        //         </Link>
        //     </li>
        //     <li className=''>
        //         <Link to="/name-advanceSearch?page=nameAdvanceSearch" className="has-arrow arrow-c" style={{ cursor: 'pointer', background: openPage === 'nameAdvanceSearch' ? '#EEE' : '' }} aria-expanded={plusMinus2}><i className="fa fa-lock "></i><span>Name</span></Link>
        //     </li>
        //     {/* property */}
        //     <li className=''>
        //         <Link to="/property-advanceSearch?page=propertyAdvanceSearch" className="has-arrow arrow-c" aria-expanded={plusMinus3} style={{ cursor: 'pointer', background: openPage === 'propertyAdvanceSearch' ? '#EEE' : '' }}><i className="fa fa-lock "></i><span>Property</span></Link>

        //     </li>
        //     {/* arrest */}
        //     <li className=''>
        //         <Link to="/arrest-advanceSearch?page=arrestAdvanceSearch" className="has-arrow arrow-c" aria-expanded={plusMinus4} style={{ cursor: 'pointer', background: openPage === 'arrestAdvanceSearch' ? '#EEE' : '' }}><i className="fa fa-lock "></i><span>Arrest</span></Link>
        //     </li>
        //     {/* vehicle */}
        //     <li className=''>
        //         <Link to="/vehicle-advanceSearch?page=vehicleAdvanceSearch" className="has-arrow arrow-c" aria-expanded={plusMinus4} style={{ cursor: 'pointer', background: openPage === 'vehicleAdvanceSearch' ? '#EEE' : '' }}><i className="fa fa-lock "></i><span>Vehicle</span></Link>
        //     </li>
        //     <li className=''>
        //         <Link to="#" className="has-arrow arrow-c" aria-expanded={plusMinus4} onClick={() => callReportModules('Table4', 'Master Table4')}><i className="fa fa-lock "></i><span>Warrant</span></Link>

        //     </li>

        // </>
    )
}

export default SearchSidebar