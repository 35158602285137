export const customTableStyles = {
  cells: {
    style: {
      border: '1px solid #ddd',
      borderBottom: '0px',
      padding: '0px 10px',
      fontWight: '500',
    },
  },
  headCells: {
    style: {
      zIndex: '0',
      backgroundColor: '#F2F2F2',
      fontWight: '500',
      color: '#666666',
      border: '1px solid #ddd',
      borderBottom: '0px',
      padding: '0px 10px'
    },
  },
  rows: {
    style: {
      borderBottom: '1px solid #ddd', // Border for row cells
    },
  },
  table: {
    style: {
      borderCollapse: 'collapse', // Ensure borders collapse into a single border
    },
  },
};

export const coloredStyle_Select = {
  control: (styles) => ({
    ...styles, backgroundColor: "#fce9bf",
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
};
export const colorLessStyle_Select = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
};

export const coloredStyle_Select_Sort = {
  control: (styles) => ({
    ...styles, backgroundColor: "#001f3f", color: "white",
  }),
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
};

export const requiredFieldColourStyles = {
  control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      // height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
  }),
};

export const fieldColourStyles = {
  control: (styles) => ({
      ...styles,
      // height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
  }),
};