import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { tableCustomStyles } from "../../../../Components/Common/Utility";
import { compareStrings } from '../../../../CADUtils/functions/common';
import { useQuery } from "react-query";
import MonitorServices from '../../../../CADServices/APIs/monitor'

const columns = [
  {
    name: 'Resource Type',
    selector: row => row.resourceType,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.resourceType, rowB.resourceType),
  },
  {
    name: 'Resource #',
    selector: row => row.resourceNumber,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.resourceNumber, rowB.resourceNumber),
  },
  {
    name: 'Incidents',
    selector: row => row.incidents,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.incidents, rowB.incidents),
    wrap: true,
  },
  {
    name: 'Status',
    selector: row => row.status,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.status, rowB.status),
  },
  {
    name: 'Status Date & Time',
    selector: row => row.StatusDT,
    sortable: true,
    format: row => new Date(row.StatusDT).toLocaleString(),

  },
  {
    name: 'Location',
    selector: row => row.location,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.location, rowB.location),
  },
  {
    name: 'Zone',
    selector: row => row.zone,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.zone, rowB.zone),
  },
  {
    name: 'Area of Responsibility',
    selector: row => row.areaOfResponsibility,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.areaOfResponsibility, rowB.areaOfResponsibility),
    wrap: true,
  },
  {
    name: 'Disposition Code',
    selector: row => row.dispositionCode,
    sortable: true,
    sortFunction: (rowA, rowB) => compareStrings(rowA.dispositionCode, rowB.dispositionCode),
  },
];

const ResourceHistoryTabSection = () => {
  const [listData, setListData] = useState([]);
  const [filterListData, setFilterListData] = useState([]);

  const getResourceOnDutyHistoryKey = `/CAD/ResourceOnDutyHistory/GetResourceOnDutyHistory`;
  const { data: resourceOnDutyHistoryList, isSuccess: isFetchResourceOnDutyHistoryList, refetch: resourceOnDutyHistoryListRefetch, isError: isNoData } = useQuery(
    [getResourceOnDutyHistoryKey],
    MonitorServices.getResourceOnDutyHistory,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  console.log(">>filterListData", filterListData)
  useEffect(() => {
    if (isFetchResourceOnDutyHistoryList && resourceOnDutyHistoryList) {
      const res = JSON.parse(resourceOnDutyHistoryList?.data?.data);
      const data = res?.Table
      setFilterListData(data || [])
      setListData(data || [])
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [isFetchResourceOnDutyHistoryList, resourceOnDutyHistoryList])

  return (
    <>
      <div>
        <DataTable
          dense
          columns={columns}
          data={filterListData}
          persistTableHead={true}
          customStyles={tableCustomStyles}
          pagination // enable pagination
          responsive // enable responsive design
          striped // add zebra-striping to the table rows
          highlightOnHover // highlight row on hover
          fixedHeader
        // fixedHeaderScrollHeight="200px" 
        />
      </div>
    </>
  );
};

export default ResourceHistoryTabSection;
