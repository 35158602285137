import React from 'react';
import './index.css';

const TitleSection = ({ title }) => {
  return (
    <div className="title-section">
      <hr className="line" />
      <h1 className="title">{title}</h1>
      <hr className="line flex-grow" />
    </div>
  );
};

export default TitleSection;
