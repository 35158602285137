// Import Component
import moment from 'moment'
import { useState } from 'react';
import { fetchPostData } from '../hooks/Api';
// import CryptoJS from 'crypto-js';

const CryptoJS = require("crypto-js");

let randomize = require('randomatic');

export const getShowingDateText = (dateStr) => {
  return moment(dateStr)?.format("MM/DD/yyyy HH:mm")
}

export const getShowingYearMonthDate = (dateStr) => {
  return moment(dateStr).format("yyyy-MM-DD HH:mm:ss")
}

export const currentDate = () => {
  return moment(new Date()).format('YYYY-MM-DD');
}

export const getShowingMonthDateYear = (dateStr) => {
  return moment(dateStr).format("MM/DD/YYYY HH:mm:ss")
}

export const getShowingWithOutTime = (dateStr) => {
  return moment(dateStr).format("MM/DD/YYYY")
}

export const getShowingWithMonthOnly = (dateStr) => {
  return moment(dateStr).format("MM/YYYY")
}

export const getMonthWithOutDateTime = (dateStr) => {
  return moment(dateStr).month()
}

export const getYearWithOutDateTime = (dateStr) => {
  return moment(dateStr).year()
}

export const getShowingWithFixedTime = (dateStr) => {
  const date = moment(dateStr).set({ hour: 23, minute: 59 });
  return date.format("MM/DD/YYYY HH:mm");
};

export const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() > selectedDate.getTime();
};

export const filterFutureTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  // Allow time if it's in the future or if it matches a specific condition
  return selectedDate.getTime() <= currentDate.getTime(); // Filter out times in the past
};

export const formatDate = (date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Concatenate the parts to form a consistent string
  return `${year}-${month}-${day}T${hours}:${minutes}Z`;
}

export const filterPassedDateTime = (time, val, reportDate) => {
  const selectedDate = new Date(time);
  const currentDate = new Date();
  const rpdt = new Date(reportDate).getTime();
  const dd = formatDate(new Date(rpdt))
  if (val && new Date(val)?.getDate() === new Date(reportDate)?.getDate() && new Date(currentDate)?.getDate() != new Date(reportDate)?.getDate()) {
    return new Date(dd).getTime() <= selectedDate.getTime();
  } else if (new Date(currentDate)?.getDate() === new Date(reportDate)?.getDate()) {
    return new Date(dd).getTime() <= selectedDate.getTime() && currentDate.getTime() >= selectedDate.getTime();
  }
  else {
    return currentDate.getTime() >= selectedDate.getTime();
  }
};

export const filterPastDate = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() < selectedDate.getTime();
};

export const filterPassedTime1 = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  // console.log(currentDate.getTime())
  // console.log(selectedDate.getTime())
  return currentDate.getTime() > selectedDate.getTime();
};



// Otp Component
export const get_OTP = () => {
  const OTP = randomize('0', 6);
  return OTP
}

//  Change Array
export const changeArrayFormat = (data, type) => {
  const result = data?.map((sponsor) =>
    ({ value: sponsor.GroupID, label: sponsor.GroupName, })
  )
  return result
}

export const changeArrayFormat_WithFilter = (data, type, id) => {
  if (type === 'group') {
    const result = data?.filter(function (option) { return option.GroupID === id }).map((sponsor) =>
      ({ value: sponsor.GroupID, label: sponsor.GroupName })
    )
    return result[0]
  }
}

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    height: 20,
    minHeight: 35,
    fontSize: 14,
    margintop: 2,
    boxShadow: 0,
  }),
}

export const customStylesWithOutColor = {
  control: base => ({
    ...base,
    height: 20,
    minHeight: 35,
    fontSize: 14,
    margintop: 2,
    boxShadow: 0,
  }),
};

export const tableCustomStyles = {
  headRow: {
    style: {
      color: '#fff',
      backgroundColor: '#001f3f ',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },
}

export const getListFunction = (ListName, DrpDataFunctions) => {
  if (DrpDataFunctions?.length > 0) {
    const reqObj = DrpDataFunctions.filter((item) => item.ListName == ListName)
    if (reqObj[0].ListName === ListName) {
      return reqObj[0].Myfunction
    }
  }
}


// --------------> Encryption // Decryption  <-------------------- //


// const AESEnyDecKey = "8UHjPgXZzXCGkhxV2QCnooyJexUzvJrO";
// const Iv = "zAvR2NI87bBx746n";

// Encrypted And Decrypted -> Key 
const Key = '9z$C&F)J@NcRfTjW'
const Code = 'QUJDREVGR0g='

// Function to convert a string to Base64
export function stringToBase64(str) {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str));
}

// Function to convert Base64 to a string
export function base64ToString(base64) {
  return CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(base64));
}

export function encryptAndEncodeToBase64(stringToEncrypt) {
  const key = CryptoJS.enc.Utf8.parse(Key);
  const iv = CryptoJS.enc.Base64.parse(Code);
  const encoded = CryptoJS.enc.Utf8.parse(stringToEncrypt);

  const bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  const encryptedString = bytes.toString();
  const base64String = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedString));
  return base64String;
}

export function decryptBase64(base64String) {
  const bytes = CryptoJS.AES.decrypt(base64String, '9z$C&F)J@NcRfTjW');
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export const DecryptedList = (response) => {
  const key = CryptoJS?.enc?.Utf8?.parse(Key);
  const iv = CryptoJS?.enc?.Base64?.parse(Code);
  const bytes = CryptoJS?.TripleDES?.decrypt(response, key, {
    mode: CryptoJS?.mode?.CBC,
    iv: iv,
  });
  return bytes?.toString(CryptoJS?.enc?.Utf8);
}

// Encrypt Data 
export const EncryptedList = (text) => {
  const key = CryptoJS.enc.Utf8.parse(Key);
  const iv = CryptoJS.enc.Base64.parse(Code);
  const encoded = CryptoJS.enc.Utf8.parse(text);
  const bytes = CryptoJS.TripleDES.encrypt(encoded, key, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  return bytes.toString();
}

// Decrypt ID And name 
export const Decrypt_Id_Name = (data, key) => {
  const result = JSON.parse(CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8))
  return result
}

export const Decrypt_Id = (data, key) => {
  const bytes = CryptoJS.AES.decrypt(data, key);
  const result = bytes.toString(CryptoJS.enc.Utf8);
  return result
}

export const Encrypted_Id_Name = (data, key) => {
  const result = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
  return result
}

export const Encrypted_Password = (data) => {
  const result = CryptoJS.AES.encrypt(JSON.stringify(data)).toString()
  return result
}

export const CadDashboardTableCustomStyles = {
  headRow: {
    style: {
      color: '#000',
      backgroundColor: '#bebebe ',
      borderBottomColor: '#FFFFFF',
      outline: '1px solid #FFFFFF',
    },
  },
}

//WebSocket data encrypt and decrypt
export function encrypt(plain) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const encryptedData = CryptoJS.AES.encrypt(plain, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(encryptedData.ciphertext.toString()));
}

export function decrypt(secret) {
  const aesKey = '0ca175b9c0f726a831d895e269332461';
  const key = CryptoJS.enc.Utf8.parse(aesKey);
  const decryptedData = CryptoJS.AES.decrypt(secret, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return decryptedData.toString(CryptoJS.enc.Utf8);
}

// Example usage
const AESEnyDecKey = CryptoJS.enc.Utf8.parse('8UHjPgXZzXCGkhxV2QCnooyJexUzvJrO'); // 16 bytes key
const iv = CryptoJS.enc.Utf8.parse('zAvR2NI87bBx746n'); // 16 bytes IV

export const Aes256Encrypt = async (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, AESEnyDecKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const Aes256Decrypt = async (encryptedData) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, AESEnyDecKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  // console.log(decrypted.toString(CryptoJS.enc.Utf8))
  return decrypted.toString(CryptoJS.enc.Utf8);
};

