import axios from "axios";
class CallTakerServices {
  getReceiveSource = async (payload) => {
    if (payload) {
      return await axios.post(
        `/CAD/CallTakerReceiveSource/GetData_ReceiveSource`,
        payload
      );
    }
    console.warn("payload not provided, CallTakerServices.getReceiveSource");
    return null;
  };

  getPropertyVehiclePlateType = async ({ queryKey }) => {
    const [_key, { vehiclePlateTypePayload }] = queryKey;
    if (vehiclePlateTypePayload) {
      return await axios.post(`/CAD/CallTakerVehiclePlateType/GetData_PropertyVehiclePlateType`, vehiclePlateTypePayload);
    }
    console.warn("payload not provided, CallTakerServices.getPropertyVehiclePlateType");
    return null;
  };

  getNameReasonCode = async ({ queryKey }) => {
    const [_key, { payloadReasonCode }] = queryKey;
    if (payloadReasonCode) {
      return await axios.post(
        `/CAD/DispatcherNameReasonCode/GetDataDropDown_NameReasonCode`,
        payloadReasonCode
      );
    }
    console.warn("payload not provided, CallTakerServices.getNameReasonCode");
    return null;
  };

  getTagYear = async () => {
    return await axios.post(`/CAD/CallTakerVehiclePlateType/GetData_TagYear`);
  };

  getState = async () => {
    return await axios.post(`/CAD/CallTakerState/GetData_State`);
  };

  getResources = async () => {
    return await axios.post(`/CAD/CallTackerResourceDp/GetData_Resources`);
  };

  insertIncident = async (payload) => {
    if (payload) {
      return await axios.post(`/CAD/CallTakerIncident/InsertIncident`, payload);
    }
    console.warn("payload not provided, CallTakerServices.insertIncident");
    return null;
  };

  // insertIncident = async (payload) => {
  //   if (payload) {
  //     return await axios.post(`/CAD/CallTakerIncident/InsertIncident`, payload);
  //   }
  //   console.warn("payload not provided, CallTakerServices.insertIncident");
  //   return null;
  // };

  // insertName = async (payload) => {
  //   if (payload) {
  //     return await axios.post(`/CAD/CallTakerMasterName/InsertIncidentNEW`, payload);
  //   }
  //   console.warn("payload not provided, CallTakerServices.insertName");
  //   return null;
  // };



  // searchName = async ({ queryKey }) => {
  //   const [_key, { payload123 }] = queryKey;
  //   if (payload123) {
  //     return await axios.post(
  //       `/CallTakerMasterName/Search_MasterName`,
  //       payload123
  //     );
  //   }
  //   console.warn("payload not provided, CallTakerServices.searchName");
  //   return null;
  // };

  searchName = async (nameCallTaker) => {
    return await axios.post(`/CAD/CallTakerMasterName/Search_MasterName`, nameCallTaker);
  };

  updateName = async (nameCallTaker) => {
    if (nameCallTaker) {
      return await axios.post(`/CAD/CallTakerMasterName/Update_MasterName`, nameCallTaker);
    }
    console.warn("nameCallTaker not provided, CallTakerServices.updateName");
    return null;
  };

  insertVehicle = async (payload) => {
    if (payload) {
      return await axios.post(`/CAD/CallTackerVehicle/InsertVehicleList`, payload);
    }
    console.warn("payload not provided, CallTakerServices.InsertVehicle");
    return null;
  };

  searchVehicle = async ({ queryKey }) => {
    const [_key, { payload }] = queryKey;
    if (payload) {
      return await axios.post(
        `/CAD/CallTackerVehicle/GetData_Vehicle`,
        payload
      );
    }
    console.warn("payload not provided, CallTakerServices.searchVehicle");
    return null;
  };

  updateVehicle = async (payload) => {
    if (payload) {
      return await axios.post(`/CAD/CallTackerVehicle/UpdateVehicle`, payload);
    }
    console.warn("payload not provided, CallTakerServices.updateVehicle");
    return null;
  };

  updateIncident = async (payload) => {
    if (payload) {
      return await axios.post(`/CAD/CallTakerIncident/UpdateIncident`, payload);
    }
    console.warn("payload not provided, CallTakerServices.updateIncident");
    return null;
  };
}

const instance = new CallTakerServices();

export default instance;
