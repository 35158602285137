import React, {useContext} from 'react'
import { AgencyContext } from '../../../../Context/Agency/Index'
import { Link, useNavigate } from 'react-router-dom'
import MobileSubTab from '../../MobileUtility/MobileSubTab'
import { MobileOffenseTabs } from '../../../Utility/Tab/TabsArray'
import MobileBasicInfromation from './OffenseDesignTab/MobileBasicInformation/MobileBasicInfromation'
import MobileOtherCode from './OffenseDesignTab/MobileOtherCode/MobileOtherCode'
import MobileMethodOfOperation from './OffenseDesignTab/MobileMethodOfOperation/MobileMethodOfOperation'
import MobileMethodOfEntry from './OffenseDesignTab/MobileMethodOfEntry/MobileMethodOfEntry'
import MobileCourtDisposition from './OffenseDesignTab/MobileCourtDisposition/MobileCourtDisposition'
import MobileWeapon from './OffenseDesignTab/MobileWeapon/MobileWeapon'
import MobileVictims from './OffenseDesignTab/MobileVictims/MobileVictims'
import MobileOffenders from './OffenseDesignTab/MobileOffenders/MobileOffenders'
import MobileProperty from './OffenseDesignTab/MobileProperty/MobileProperty'

const OffenseMobileTab = () => {

    const navigate = useNavigate()


    const {  setShowPage, showPage } = useContext(AgencyContext);
    // const [showPage, setShowPage] = useState('home');

   

   

    return (
     
            <div>
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12 px-2">
                        <div className="card Agency " style={{ borderRight: 'none', borderLeft: 'none', borderRadius: '0' }}>
                            <div className="card-body ">
                              
                                <div className="card Agency " style={{ borderRight: 'none', borderLeft: 'none', borderRadius: '0', borderTop: 'none', marginTop: '-10px' }}>
                                    <div className="col-12  mobile__tabs " style={{ marginBottom: '5px' }}>
                                        <Link to={'/mobile-offense'}>
                                            <div className="" style={{ padding: '0px' }}>
                                                <button type="button" className="close mr-1 mb-2" style={{ color: 'red', fontSize: '22px', fontWeight: '900' }}>&times;</button>
                                            </div>
                                        </Link>
                                        <MobileSubTab tabs={MobileOffenseTabs} setShowPage={setShowPage} showPage={showPage} status={false} />
                                    </div>

                                </div>
                                <div className={`col-12 col-md-12`}>
                                    <div className=''>
                                        {showPage === 'home' && navigate('/mobile-offense')}
                                        {showPage === 'MobileBasicInformation' && <MobileBasicInfromation />}
                                        {showPage === 'MobileMethodOperation' && <MobileMethodOfOperation />}
                                        {showPage === 'MobileMethodEntry' && <MobileMethodOfEntry />}
                                        {showPage === 'MobileCourtDisposition' && <MobileCourtDisposition />}
                                        {showPage === 'MobileWeapon' && <MobileWeapon />}
                                        {showPage === 'MobileVictims' && <MobileVictims />}
                                        {showPage === 'MobileOffenders' && <MobileOffenders />}
                                        {showPage === 'MobileProperty' && <MobileProperty />}
                                        {showPage === 'MobileOtherCode' && <MobileOtherCode />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

   
    )
}


export default OffenseMobileTab