import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import GeoServices from "../../CADServices/APIs/geo";
import { dropDownDataModel, handleNumberTextKeyDown } from "../../CADUtils/functions/common";
import ModalConfirm from "../Common/ModalConfirm";

const LocationInformationModal = (props) => {
    const { openLocationInformationModal, setOpenLocationInformationModal, setSelectedButton, geoFormValues, setGEOFormValues, isGoogleLocation = true } = props;
    const [premiseDropDown, setPremiseDropDown] = useState([]);
    const [isChangeFields, setIsChangeFields] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);

    function handleClear() {
        setGEOFormValues({})
        setIsChangeFields(false)
    }

    const onCloseLocation = () => {
        setOpenLocationInformationModal(false);
        setSelectedButton(prevSelected =>
            prevSelected.includes(3)
                ? prevSelected.filter(item => item !== 3)
                : [...prevSelected, 3]
        );
        setIsChangeFields(false)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setGEOFormValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setIsChangeFields(true)
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setGEOFormValues((prevState) => ({
            ...prevState,
            [name]: selectedOption,
        }));
        setIsChangeFields(true)
    };

    const getPremiseKey = `/CAD/GeoPremiseType/GetData_Premise`;
    const { data: premiseData, isSuccess: isFetchPremiseData } = useQuery(
        [getPremiseKey, {}],
        GeoServices.getPremise,
        {
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (isFetchPremiseData && premiseData) {
            const data = JSON.parse(premiseData?.data?.data);
            setPremiseDropDown(dropDownDataModel(data?.Table, "ID", "PremiseType"));
        }
    }, [isFetchPremiseData, premiseData]);

    async function handleSave() {
        onCloseLocation();
    }

    const customStylesWithOutColor = {
        control: (base) => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            marginTop: 2,
            boxShadow: 0,
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
        }),
    };

    const handleKeyDown = (e) => {
        const charCode = e.keyCode || e.which;
        const charStr = String.fromCharCode(charCode);
        const controlKeys = [8, 9, 13, 27, 37, 38, 39, 40, 46];
        const numpadKeys = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
        const numpadSpecialKeys = [106, 107, 109, 110, 111];
        if (!charStr.match(/^[a-zA-Z]+$/) && !controlKeys.includes(charCode)) {
            e.preventDefault();
        }
        if (
            (charCode >= 48 && charCode <= 57) ||
            numpadKeys.includes(charCode) ||
            numpadSpecialKeys.includes(charCode)
        ) {
            e.preventDefault();
        }
    };

    function handleCloseConfirm() {
        setShowConfirmModal(false);
        if (confirmAction === "close") {
            onCloseLocation();
        } else if (confirmAction === "clear") {
            handleClear();
        }
    }

    return (
        <>
            {openLocationInformationModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                        id="LocationInformationModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered CAD-sub-modal-width">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    Location Information
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            {/* Row 1 */}
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex align-self-center justify-content-end">
                                                    <label className="tab-form-label">
                                                        Premise #
                                                    </label>
                                                </div>
                                                <div className="col-1 d-flex align-self-center justify-content-end">
                                                    <input
                                                        type="number"
                                                        className="form-control py-1 new-input"
                                                        name="PremiseNo"
                                                        disabled={geoFormValues?.isPremiseNo || !isGoogleLocation}
                                                        value={geoFormValues.PremiseNo}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-2 d-flex align-self-center tab-form-row-gap">
                                                    <label className="tab-form-label d-flex align-self-center justify-content-end">
                                                        St.Direction
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input w-100"
                                                        name="stDirection"
                                                        onKeyDown={handleKeyDown}
                                                        disabled={geoFormValues?.isStDirection || !isGoogleLocation}
                                                        value={geoFormValues.stDirection}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-4 d-flex align-self-center tab-form-row-gap">
                                                    <label className="tab-form-label d-flex align-self-center justify-content-end">
                                                        St.Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input"
                                                        name="Street"
                                                        onKeyDown={handleNumberTextKeyDown}
                                                        disabled={geoFormValues?.isStreet || !isGoogleLocation}
                                                        value={geoFormValues.Street}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-2 d-flex align-self-center tab-form-row-gap">
                                                    <label className="tab-form-label d-flex align-self-center justify-content-end">
                                                        St.Direction
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input"
                                                        name="stDirection2"
                                                        onKeyDown={handleKeyDown}
                                                        disabled={geoFormValues?.isStDirection2 || !isGoogleLocation}
                                                        value={geoFormValues.stDirection2}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-2 d-flex align-items-center tab-form-row-gap">
                                                    <div className="flex-shrink-0">
                                                        <label className="tab-form-label d-flex justify-content-end">
                                                            Apt/Suite #
                                                        </label>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <input
                                                            type="number"
                                                            className="form-control py-1 new-input w-100"
                                                            name="ApartmentNo"
                                                            value={geoFormValues.ApartmentNo}
                                                            disabled={geoFormValues?.isApartmentNo || !isGoogleLocation}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Row 2 */}
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex align-items-center justify-content-end">
                                                    <label className="tab-form-label mr-2">
                                                        City
                                                    </label>
                                                </div>
                                                <div className="col-2 d-flex align-self-center justify-content-end">
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input"
                                                        name="City"
                                                        value={geoFormValues.City}
                                                        disabled={geoFormValues?.isCity || !isGoogleLocation}
                                                        onKeyDown={handleKeyDown}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-1 d-flex align-items-center justify-content-end">
                                                    <label className="tab-form-label mr-2">Zip</label>
                                                </div>
                                                <div className="col-1 d-flex align-self-center justify-content-end">
                                                    <input
                                                        type="number"
                                                        className="form-control py-1 new-input"
                                                        name="ZipCode"
                                                        value={geoFormValues.ZipCode}
                                                        disabled={geoFormValues?.isZipCode || !isGoogleLocation}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-2 d-flex align-self-center justify-content-end text-nowrap">
                                                    <label className="tab-form-label">
                                                        Common Place Name
                                                    </label>
                                                </div>
                                                <div className="col-5 d-flex align-self-center justify-content-end">
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input"
                                                        name="commonPlaceName"
                                                        onKeyDown={handleNumberTextKeyDown}
                                                        value={geoFormValues.commonPlaceName}
                                                        disabled={geoFormValues?.isCommonPlaceName || !isGoogleLocation}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            {/* Row 3 */}
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex align-self-center justify-content-end">
                                                    <label className="tab-form-label">
                                                        Coordinate:X
                                                    </label>
                                                </div>
                                                <div className="col-3 d-flex align-items-center justify-content-end">
                                                    <input
                                                        type="number"
                                                        className="form-control py-1 new-input w-100"
                                                        name="coordinateX"
                                                        value={geoFormValues.coordinateX}
                                                        disabled={geoFormValues?.isCoordinateX || !isGoogleLocation}
                                                        onChange={handleInputChange}
                                                    />
                                                    <label className="tab-form-label mx-2">Y</label>
                                                    <input
                                                        type="number"
                                                        className="form-control py-1 new-input w-100"
                                                        name="coordinateY"
                                                        value={geoFormValues.coordinateY}
                                                        disabled={geoFormValues?.isCoordinateY || !isGoogleLocation}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-6 d-flex align-items-center tab-form-row-gap pr-3">
                                                    <div className="flex-shrink-0">
                                                        <label className="tab-form-label d-flex justify-content-end">
                                                            Alt.St.Name
                                                        </label>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input"
                                                        name="AltStreet"
                                                        onKeyDown={handleNumberTextKeyDown}
                                                        disabled={geoFormValues?.isAltStreet || !isGoogleLocation}
                                                        value={geoFormValues.AltStreet}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-2 d-flex align-self-center">
                                                    <label className="tab-form-label col-5 d-flex align-self-center justify-content-end text-nowrap">
                                                        Mile Marker
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control py-1 new-input col-7"
                                                        name="mileMarker"
                                                        disabled={geoFormValues?.isMileMarker || !isGoogleLocation}
                                                        value={geoFormValues.mileMarker}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            {/* Line 4 */}
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex align-items-center justify-content-end tab-form-row-gap">
                                                    <label className="tab-form-label d-flex justify-content-end text-nowrap">
                                                        Premise Type
                                                    </label>
                                                </div>
                                                <div className="col-2 flex-grow-1">
                                                    <Select
                                                        name="premiseType"
                                                        menuPlacement="top"
                                                        styles={{
                                                            ...customStylesWithOutColor,
                                                            menuList: (provided) => ({
                                                                ...provided,
                                                                maxHeight: 150,
                                                                overflowY: 'auto',
                                                            }),
                                                        }}
                                                        isClearable
                                                        options={premiseDropDown}
                                                        placeholder="Select..."
                                                        onChange={handleSelectChange}
                                                        value={geoFormValues.premiseType}
                                                        isDisabled={!isGoogleLocation}
                                                        onInputChange={(inputValue, actionMeta) => {
                                                            if (inputValue.length > 12) {
                                                                return inputValue.slice(0, 12);
                                                            }
                                                            return inputValue;
                                                        }}
                                                    />

                                                </div>
                                                <div className="col-7 d-flex align-items-center justify-content-end ml-2">
                                                    <label className="tab-form-label text-nowrap mr-2">
                                                        Intersection St/St
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control py-1 new-input mr-1"
                                                        name="intersection1"
                                                        value={geoFormValues.intersection1}
                                                        disabled={geoFormValues?.isIntersection1 || !isGoogleLocation}
                                                        onKeyDown={handleNumberTextKeyDown}
                                                        onChange={handleInputChange}
                                                    />
                                                    {"/"}
                                                    <input
                                                        type="text"
                                                        className="form-control ml-1 py-1 new-input"
                                                        name="intersection2"
                                                        value={geoFormValues.intersection2}
                                                        disabled={geoFormValues?.isIntersection2 || !isGoogleLocation}
                                                        onKeyDown={handleNumberTextKeyDown}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        className="save-button ml-2"
                                                        disabled={!isGoogleLocation}
                                                        onClick={() => handleSave()}
                                                    >
                                                        {"Save"}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="cancel-button"
                                                        onClick={() => {
                                                            if (isChangeFields) {
                                                                setConfirmAction("close");
                                                                setShowConfirmModal(true);
                                                            } else {
                                                                onCloseLocation();
                                                            }
                                                        }
                                                        }
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog >
                </>
            ) : (
                <> </>
            )
            }
            <ModalConfirm showModal={showConfirmModal} setShowModal={setShowConfirmModal} confirmAction={confirmAction === "close" ? "close" : "clear"} handleConfirm={handleCloseConfirm} />
        </>
    );
};

export default memo(LocationInformationModal);
