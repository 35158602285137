import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { AddDeleteUpadate, fetchPostData } from '../../../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { Comman_changeArrayFormat, Comman_changeArrayFormatwithoutcode, Comman_changeArrayFormatBasicInfowithoutcode, Comman_changeArrayFormatBasicInfo, Comman_changeArrayFormatJustfiableHomicide, threeColArray, threeColVictimInjuryArray } from '../../../../../../Common/ChangeArrayFormat';
import { toastifySuccess } from '../../../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../../../Common/DeleteModal';
import { RequiredFieldIncident } from '../../../../../Utility/Personnel/Validation';
import { AgencyContext } from '../../../../../../../Context/Agency/Index';
import { components } from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../../../redux/api';

import { Decrypt_Id_Name } from '../../../../../../Common/Utility';
import NameListing from '../../../../../ShowAllList/NameListing';

const Home = (props) => {


    const { DecNameID, DecMasterNameID, DecIncID, victimID } = props
    const { get_NameVictim_Count, get_Name_Count } = useContext(AgencyContext);

    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';

    const SelectedValue = useRef();
    const [propertyDrp, setPropertyDrp] = useState();
    const [justifiableHomiDrp, setJustifiableHomiDrp] = useState();
    const [justifiableHomiVal, setJustifiableHomiVal] = useState();
    const [officerDrpData, setOfficerDrpData] = useState();
    const [injuryTypeDrp, setInjuryTypeDrp] = useState();
    const [assaultDrp, setAssaultDrp] = useState();
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [nameID, setNameID] = useState('');
    const [incidentID, setIncidentID] = useState();

    const [injuryTypeData, setInjuryTypeData] = useState();
    const [loder, setLoder] = useState(false)
    const [disabled, setDisabled] = useState(false);
    const [value, setValue] = useState()
    const [filterData, setFilterData] = useState()
    const [nameEventAssaultID, setNameEventAssaultID] = useState();

    const [typeOfSecurityEditVal, setTypeOfSecurityEditVal] = useState();
    const [propertyEditVal, setPropertyEditVal] = useState();
    const [injuryTypeEditVal, setInjuryTypeEditVal] = useState();
    const [assaultEditVal, setAssaultEditVal] = useState();

    //ids
    const [officerID, setofficerID] = useState();
    const [justifiyID, setJustifiyID] = useState();
    const [victimInjuryID, setVictimInjuryID] = useState();
    const [assaultID, setAssaultID] = useState();
    const [propertyID, setPropertyID] = useState();

    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    const [multiSelected, setMultiSelected] = useState({
        AssaultID: null, OfficerID: null, PropertyID: null, VictimInjuryID: null, JustifiableHomicideID: null,
    })

    const [errors, setErrors] = useState({
        'DropError': '',
    })

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    useEffect(() => {
        if (DecNameID) { setNameID(DecNameID); setIncidentID(DecIncID); }
    }, [DecNameID, loginPinID,]);

    useEffect(() => {
        if (victimID) { get_Property_Data(); get_Victim_Officer_Data(); get_RelationShip_Data(); get_InjuryType_Data(); get_Assults_Data() }
        get_NameVictim_Count(victimID);
    }, [victimID])

    useEffect(() => {
        if (typeOfSecurityEditVal) { setofficerID(typeOfSecurityEditVal) }
    }, [typeOfSecurityEditVal])

    useEffect(() => {
        if (justifiableHomiVal) { setJustifiyID(justifiableHomiVal) }
    }, [justifiableHomiVal])

    useEffect(() => {
        if (injuryTypeEditVal) { setVictimInjuryID(injuryTypeEditVal) }
    }, [injuryTypeEditVal])

    useEffect(() => {
        if (assaultEditVal) { setAssaultID(assaultEditVal) }
    }, [assaultEditVal])

    useEffect(() => {
        if (propertyEditVal) { setPropertyID(propertyEditVal) }
    }, [propertyEditVal])

    // useEffect(() => {
    //     if (typeOfSecurityEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['OfficerID']: typeOfSecurityEditVal } }) }
    //     if (justifiableHomiVal) { setMultiSelected(prevValues => { return { ...prevValues, ['JustifiableHomicideID']: justifiableHomiVal } }) }
    //     if (injuryTypeEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['VictimInjuryID']: injuryTypeEditVal } }) }
    //     if (assaultEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['AssaultID']: assaultEditVal } }) }
    //     if (propertyEditVal) { setMultiSelected(prevValues => { return { ...prevValues, ['PropertyID']: propertyEditVal } }) }
    // }, [typeOfSecurityEditVal, propertyEditVal, justifiableHomiVal, injuryTypeEditVal, assaultEditVal])


    useEffect(() => {
        get_Property_DropDown(incidentID, victimID);
        get_Victim_Officer_Drp(victimID, loginAgencyID);
        get_Data_RelationShip_Drp(victimID);
        get_Data_InjuryType_Drp(victimID);
        get_Assults_Drp(victimID);
    }, [loginAgencyID, victimID, incidentID ,DecIncID])

    const get_InjuryType_Data = () => {
        const val = { 'VictimID': victimID, }
        fetchPostData('InjuryVictim/GetData_InjuryVictim', val).then((res) => {
            if (res) {
                setInjuryTypeEditVal(Comman_changeArrayFormatBasicInfowithoutcode(res, 'VictimInjuryID', 'NameID', 'PretendToBeID', 'NameEventInjuryID', 'VictimInjury_Description'));
            } else {
                setInjuryTypeEditVal([]);
            }
        })
    }

    const get_Data_InjuryType_Drp = (incidentID, victimID) => {
        const val = { 'IncidentID': incidentID, 'VictimID': victimID }
        fetchPostData('InjuryVictim/GetData_InsertVictimInjury', val).then((data) => {
            if (data) {
                setInjuryTypeDrp(threeColVictimInjuryArray(data, 'VictimInjuryID', 'Description', 'InjuryCode'))
            } else {
                setInjuryTypeDrp([])
            }
        })
    }

    const get_RelationShip_Data = () => {
        const val = { 'VictimID': victimID, }
        fetchPostData('VictimJustifiableHomicide/GetData_VictimJustifiableHomicide', val).then((res) => {
            if (res) {
                setJustifiableHomiVal(Comman_changeArrayFormatJustfiableHomicide(res, 'VictimJustifiableHomicideID', 'JustifiableHomicideID', 'PretendToBeID', 'JustifiableHomicide_Description'));

            } else {
                setJustifiableHomiVal([]);
            }
        })
    }

    const get_Data_RelationShip_Drp = (victimID) => {
        const val = { 'VictimID': victimID, }
        fetchPostData('VictimJustifiableHomicide/GetData_InsertJustifiableHomicide', val).then((data) => {
            if (data) {
                setJustifiableHomiDrp(threeColVictimInjuryArray(data, 'JustifiableHomicideID', 'Description', "JustifiableHomicideCode",))

            } else {
                setJustifiableHomiDrp([])
            }
        })
    }

    const get_Victim_Officer_Data = () => {
        const val = { 'VictimID': victimID, }
        fetchPostData('VictimOfficer/GetData_VictimOfficer', val).then((res) => {
            if (res) {
                console.log(res);
                setTypeOfSecurityEditVal(Comman_changeArrayFormatwithoutcode(res, 'VictimOfficerID', 'NameID', 'PretendToBeID', 'OfficerID', 'Officer_Name'));
            } else {
                setTypeOfSecurityEditVal([]);
            }
        })
    }

    const get_Victim_Officer_Drp = (victimID, loginAgencyID) => {
        const val = { 'VictimID': victimID, 'AgencyID': loginAgencyID,'IncidentID' : DecIncID}
        fetchPostData('VictimOfficer/GetData_InsertVictimOfficer', val).then((data) => {
            if (data) {
                setOfficerDrpData(Comman_changeArrayFormat(data, 'PINID', 'OfficerName'))
            } else {
                setOfficerDrpData([])
            }
        })
    }

    const get_Assults_Data = () => {
        const val = { 'VictimID': victimID, }
        fetchPostData('VictimAssaultType/GetData_VictimAssaultType', val).then((res) => {
            if (res) {
                setAssaultEditVal(Comman_changeArrayFormatwithoutcode(res, 'NameEventAssaultID', 'NameID', 'PretendToBeID', 'AssaultID', 'Assault_Description'));
            } else {
                setAssaultEditVal([]);
            }
        })
    }

    const get_Assults_Drp = () => {
        const val = { 'VictimID': victimID, }
        fetchPostData('VictimAssaultType/GetData_InsertVictimAssaultType', val).then((data) => {
            if (data) {
                setAssaultDrp(threeColArray(data, 'AssaultTypeID', 'Description'))
            } else {
                setAssaultDrp([])
            }
        })
    }

    const get_Property_Data = () => {
        const val = { 'VictimID': victimID, }
        fetchPostData('VictimProperty/GetData_VictimProperty', val).then((res) => {
            if (res) {
                setPropertyEditVal(Comman_changeArrayFormatwithoutcode(res, 'VictimPropertyID', 'NameID', 'PretendToBeID', 'PropertyID', 'Description')); setLoder(true)
            } else {
                setPropertyEditVal([]); setLoder(true)
            }
        })
    }

    const get_Property_DropDown = (incidentID, victimID) => {
        const val = { 'IncidentID': incidentID, 'VictimID': victimID, }
        fetchPostData('VictimProperty/GetData_InsertVictimProperty', val).then((data) => {
            if (data) {
                setFilterData(data)
                setPropertyDrp(threeColArray(data, 'PropertyID', 'Description'))
            } else {
                setPropertyDrp([])
            }
        })
    }

    const InSertBasicInfo = (id, col1, url) => {
        setDisabled(true)
        const val = {
            'NameID': nameID,
            'VictimID': victimID,
            [col1]: id,
            'CreatedByUserFK': loginPinID,
        }
        AddDeleteUpadate(url, val).then((res) => {
            if (res) {
                get_Name_Count(DecNameID, DecMasterNameID);
                setDisabled(false);
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                // toastifySuccess(res.Message);
                col1 === 'OfficerID' && get_Victim_Officer_Data(); get_Victim_Officer_Drp(victimID, loginAgencyID);
                col1 === 'PropertyID' && get_Property_Data(); get_Property_DropDown(incidentID, victimID);
                col1 === 'JustifiableHomicideID' && get_RelationShip_Data(); get_Data_RelationShip_Drp(victimID);
                col1 === 'VictimInjuryID' && get_InjuryType_Data();
                col1 === 'AssaultID' && get_Assults_Data(); get_Assults_Drp()
            } else {
                console.log("Somthing Wrong");
            }
        })
    }

    const DelSertBasicInfo = (victimOfficerID, col1, url) => {
        setDisabled(true)
        const val = {
            [col1]: victimOfficerID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate(url, val).then((res) => {
            if (res) {
                get_Name_Count(DecNameID, DecMasterNameID);
                setDisabled(false);
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                // toastifySuccess(res.Message);
                if (col1 === 'VictimOfficerID') {
                    get_Victim_Officer_Data(victimID);
                    get_Victim_Officer_Drp(victimID, loginAgencyID);
                }
                // Call other relevant functions based on col1
                else if (col1 === 'VictimPropertyID') {
                    get_Property_Data();
                    get_Property_DropDown(incidentID, victimID);
                } else if (col1 === 'VictimJustifiableHomicideID') {
                    get_RelationShip_Data();
                    get_Data_RelationShip_Drp(victimID);
                } else if (col1 === 'NameEventInjuryID') {
                    get_InjuryType_Data();
                } else if (col1 === 'NameEventAssaultID') {
                    get_Assults_Data();
                    get_Assults_Drp();
                }
            } else {
                console.log("Somthing Wrong");
            }
        })
    }

    const officerOnChange = (multiSelected) => {
        // setMultiSelected({
        //     ...multiSelected,
        //     OfficerID: multiSelected
        // })
        setofficerID(multiSelected)
        const len = multiSelected.length - 1
        if (multiSelected?.length < typeOfSecurityEditVal?.length) {
            let missing = null;
            let i = typeOfSecurityEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(typeOfSecurityEditVal[--i])) ? missing : typeOfSecurityEditVal[i];
            }
            DelSertBasicInfo(missing.value, 'VictimOfficerID', 'VictimOfficer/Delete_VictimOfficer')
        } else {
            InSertBasicInfo(multiSelected[len].value, 'OfficerID', 'VictimOfficer/Insert_VictimOfficer')
        }
    }

    const JustifuableOnChange = (multiSelected) => {
        // setMultiSelected({
        //     ...multiSelected,
        //     JustifiableHomicideID: multiSelected
        // })
        setJustifiyID(multiSelected)
        const len = multiSelected.length - 1
        if (multiSelected?.length < justifiableHomiVal?.length) {
            let missing = null;
            let i = justifiableHomiVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(justifiableHomiVal[--i])) ? missing : justifiableHomiVal[i];
            }
            DelSertBasicInfo(missing.id, 'VictimJustifiableHomicideID', 'VictimJustifiableHomicide/Delete_VictimJustifiableHomicide')
        } else {
            InSertBasicInfo(multiSelected[len].value, 'JustifiableHomicideID', 'VictimJustifiableHomicide/Insert_VictimJustifiableHomicide')
        }
    }

    const InjuryTypeOnChange = (multiSelected) => {
        // setMultiSelected({
        //     ...multiSelected,
        //     VictimInjuryID: multiSelected
        // })
        setVictimInjuryID(multiSelected)
        const len = multiSelected.length - 1
        if (multiSelected?.length < injuryTypeEditVal?.length) {
            let missing = null;
            let i = injuryTypeEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(injuryTypeEditVal[--i])) ? missing : injuryTypeEditVal[i];
            }
            DelSertBasicInfo(missing.id, 'NameEventInjuryID', 'InjuryVictim/Delete_VictimInjury')
        } else {
            InSertBasicInfo(multiSelected[len].value, 'VictimInjuryID', 'InjuryVictim/Insert_VictimInjury')
        }
    }

    const assaultOnChange = (multiSelected) => {
        // setMultiSelected({
        //     ...multiSelected,
        //     AssaultID: multiSelected
        // })
        setAssaultID(multiSelected)
        const len = multiSelected.length - 1
        if (multiSelected?.length < assaultEditVal?.length) {
            let missing = null;
            let i = assaultEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(assaultEditVal[--i])) ? missing : assaultEditVal[i];
            }
            DelSertBasicInfo(missing.value, 'NameEventAssaultID', 'VictimAssaultType/Delete_VictimAssaultType')
        } else {
            InSertBasicInfo(multiSelected[len].value, 'AssaultID', 'VictimAssaultType/Insert_VictimAssaultType')
        }
    }

    const PropertyOnChange = (multiSelected) => {
        // setMultiSelected({
        //     ...multiSelected,
        //     PropertyID: multiSelected
        // })
        setPropertyID(multiSelected)
        const len = multiSelected.length - 1
        if (multiSelected?.length < propertyEditVal?.length) {
            let missing = null;
            let i = propertyEditVal.length;
            while (i) {
                missing = (~multiSelected.indexOf(propertyEditVal[--i])) ? missing : propertyEditVal[i];
            }
            DelSertBasicInfo(missing.value, 'VictimPropertyID', 'VictimProperty/Delete_VictimProperty')
        } else {
            InSertBasicInfo(multiSelected[len].value, 'PropertyID', 'VictimProperty/Insert_VictimProperty')
        }
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            minHeight: 60,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    return (
        <>
            <NameListing />
            <div className="col-12 " id='display-not-form'>
                <div className="col-12 col-md-12  p-0" >
                    <div className="bg-line  py-1 px-2 d-flex justify-content-between align-items-center">
                        <p className="p-0 m-0">Other Victim</p>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Officer{errors.DropError !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4  mt-2" >
                        <Select
                            name='OfficerID'
                            // value={multiSelected.OfficerID}
                            value={officerID}
                            options={officerDrpData}
                            isClearable
                            closeMenuOnSelect={false}
                            placeholder="Select.."
                            ref={SelectedValue}
                            className="basic-multi-select"
                            isMulti
                            styles={customStylesWithOutColor}
                            components={{ MultiValue, }}
                            onChange={(e) => officerOnChange(e)}
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-4">
                        <label htmlFor="" className='new-label'>Justifiable Homicide {errors.DropError !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4  mt-2" >
                        <Select
                            name='JustifiableHomicideID'
                            // value={multiSelected.JustifiableHomicideID}
                            value={justifiyID}
                            isClearable
                            options={justifiableHomiDrp}
                            closeMenuOnSelect={false}
                            placeholder="Select.."
                            ref={SelectedValue}
                            components={{ MultiValue, }}
                            onChange={(e) => JustifuableOnChange(e)}
                            className="basic-multi-select"
                            isMulti
                            styles={customStylesWithOutColor}
                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <Link to={'/ListManagement?page=Injury%20Type&call=/Name-Home'} className='new-link'>
                            Injury Type  {errors.DropError !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</p>
                            ) : null}
                        </Link>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4  mt-2" >
                        <Select
                            name='VictimInjuryID'
                            // value={multiSelected.VictimInjuryID}
                            value={victimInjuryID}
                            isClearable
                            options={injuryTypeDrp}
                            closeMenuOnSelect={false}
                            // onChange={(e) => { ChangeDropDown(e, 'VictimInjuryID'); }}
                            placeholder="Select.."
                            ref={SelectedValue}
                            components={{ MultiValue, }}
                            onChange={(e) => InjuryTypeOnChange(e)}
                            className="basic-multi-select"
                            isMulti
                            styles={customStylesWithOutColor}
                            menuPlacement='top'

                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Assault Type  {errors.DropError !== 'true' ? (
                            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.DropError}</span>
                        ) : null}</label>
                    </div>
                    <div className="col-4 col-md-4 col-lg-4  mt-2" >
                        <Select
                            name='AssaultID'
                            // value={multiSelected.AssaultID}
                            value={assaultID}
                            isClearable
                            options={assaultDrp}
                            closeMenuOnSelect={false}
                            // onChange={(e) => { ChangeDropDown(e, 'AssaultID'); }}
                            placeholder="Select.."
                            ref={SelectedValue}
                            className="basic-multi-select"
                            isMulti
                            styles={customStylesWithOutColor}
                            components={{ MultiValue, }}
                            onChange={(e) => assaultOnChange(e)}
                            menuPlacement='top'

                        />
                    </div>
                    <div className="col-2 col-md-2 col-lg-2 mt-3">
                        <label htmlFor="" className='label-name '>Property</label>
                    </div>
                    <div className="col-6 col-md-6 col-lg-10 mt-2">
                        <Select
                            options={propertyDrp}
                            // value={multiSelected.PropertyID}
                            value={propertyID}
                            isClearable
                            closeMenuOnSelect={false}
                            placeholder="Select.."
                            ref={SelectedValue}
                            className="basic-multi-select"
                            isMulti
                            styles={customStylesWithOutColor}
                            components={{ MultiValue, }}
                            onChange={(e) => PropertyOnChange(e)}
                            name='PropertyID'
                            menuPlacement='top'
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default Home