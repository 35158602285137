import React, { useState } from 'react';
import './Tooltip.css';

const Tooltip = ({ text, maxLength }) => {
    const [isHovered, setIsHovered] = useState(false);
    const isTruncated = text.length > maxLength;
    const displayText = isTruncated ? text.substring(0, maxLength) + '...' : text;

    return (
        <div
            className="tooltip-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <span className="truncated-text">{displayText}</span>
            {isHovered && isTruncated && (
                <div className="tooltip-box">
                    <div className="tooltip-arrow" />
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
