import React, { memo, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { useSelector } from "react-redux";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import MonitorServices from "../../CADServices/APIs/monitor";
import useObjState from "../../CADHook/useObjState";
import MapComponent from "../MapComponent";
import { fieldColourStyles, requiredFieldColourStyles } from "../Utility/CustomStylesForReact";
import { IncidentContext } from "../../CADContext/Incident";
import { useLocation } from "react-router-dom";
import { base64ToString } from "../../Components/Common/Utility";
import { toastifySuccess } from "../../Components/Common/AlertMsg";
import { isEmpty, isEmptyObject } from "../../CADUtils/functions/common";

const ClearModal = (props) => {
    const { openClearModal, setOpenClearModal, incidentID } = props;
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const { assignedIncidentData, resourceData, resourceRefetch, incidentRefetch } = useContext(IncidentContext);
    const [resourceDropDown, setResourceDropDown] = useState([])
    const [loginAgencyID, setLoginAgencyID] = useState();
    const [loginPinID, setLoginPinID,] = useState('');
    const [agencyID, setAgencyID] = useState(0);

    const [dispositionDropDown, setDispositionDropDown] = useState([]);
    const useRouteQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };
    const query = useRouteQuery();

    let IncID = query?.get("IncId");
    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID)
            setLoginPinID(parseInt(localStoreData?.PINID));
            setAgencyID(localStoreData?.AgencyID)

        }
    }, [localStoreData]);
    const [
        clearState,
        setClearState,
        handleClearState,
        clearClearState,
    ] = useObjState({
        incidentID: "",
        resources: "",
        CFSCode: "",
        CFSDesc: "",
        primaryResource: "",
        RMSIncident: "",
        masterIncident: "",
        CADDisposition: "",
        reportDateTime: "",
        Location: "",
        APTSuit: "",
        Comments: "",
        coordinateX: "",
        coordinateY: "",

    });

    const [
        errorClearState,
        _setErrorClearState,
        handleErrorClearState,
        clearErrorClearState,
    ] = useObjState({
        CADDisposition: false,
    });

    const getSingleIncidentKey = `/CAD/Monitor/MonitorIncidentByID`;
    const { data: singleIncidentData, isSuccess: isFetchSingleIncidentData, refetch: refetchGetSingleIncident } = useQuery(
        [getSingleIncidentKey, {
            IncidentID: clearState?.incidentID, AgencyID: loginAgencyID,
        }],
        MonitorServices.getSingleIncident,
        {
            refetchOnWindowFocus: false,
            enabled: !!clearState?.incidentID && !!loginAgencyID && openClearModal,
        }
    );

    useEffect(() => {
        if (singleIncidentData && isFetchSingleIncidentData) {
            const parsedData = JSON.parse(singleIncidentData?.data?.data)?.Table || [];
            setClearState({
                incidentID: parsedData[0]?.IncidentID,
                resources: resourceDropDown.filter((item) => { return parsedData[0]?.resources === item?.resources }),
                CFSCode: parsedData[0]?.CFSCODE,
                CFSDesc: parsedData[0]?.CFSCodeDescription,
                primaryResource: resourceDropDown.filter((item) => { return parsedData[0]?.PrimaryResourceName === item?.ResourceNumber }),
                RMSIncident: parsedData[0]?.RMSIncidentID,
                masterIncident: parsedData[0]?.MasterIncidentNumber,
                CADDisposition: parsedData[0]?.CADDisposition,
                reportDateTime: parsedData[0]?.ReportedDate,
                Location: parsedData[0]?.CrimeLocation,
                APTSuit: parsedData[0]?.ApartmentNo,
                Comments: parsedData[0]?.Comments,
                coordinateX: parsedData[0]?.Longitude,
                coordinateY: parsedData[0]?.Latitude,
            })
        }
    }, [singleIncidentData, isFetchSingleIncidentData])

    const getDispositionskey = `/CAD/MasterIncidentDispositions/GetData_IncidentDispositions`;
    const { data: dispositionData, isSuccess: isFetchDispositionData, refetch } = useQuery(
        [getDispositionskey, {
            "IsActive": 1,
            "IsSuperAdmin": 1,
            "AgencyID": agencyID,
            "PINID": loginPinID
        },],
        MasterTableListServices.getIncidentDispositions,
        {
            refetchOnWindowFocus: false,
            enabled: !!agencyID && !!loginPinID
        }
    );

    useEffect(() => {
        const filteredData = resourceData.filter(
            (incident) => incident.IncidentID === clearState?.incidentID
        );
        setResourceDropDown(filteredData);
    }, [resourceData, clearState]);

    useEffect(() => {
        if (isFetchDispositionData && dispositionData) {
            const data = JSON.parse(dispositionData?.data?.data);
            setDispositionDropDown(data?.Table)
        }
    }, [isFetchDispositionData, dispositionData]);



    const onCloseLocation = () => {
        setOpenClearModal(false);
        clearClearState()
        clearErrorClearState()
        // setIncidentID("")
    };
    useEffect(() => {
        // Fetch data when the modal opens with the incident ID from the URL.
        if (IncID) {
            handleClearState("incidentID", IncID);
            refetchGetSingleIncident();
        }
        if (incidentID) {
            handleClearState("incidentID", incidentID);
            refetchGetSingleIncident();
        }
    }, [openClearModal]);

    // Fetch data when a new incident is selected from the dropdown.
    const handleSelectCADEvent = (selectedOption) => {
        clearClearState()
        clearErrorClearState()
        handleClearState("incidentID", selectedOption?.IncidentID);
        refetchGetSingleIncident();  // Triggers the API call with the new ID
    };

    const validateDispatch = () => {
        let isError = false;
        const keys = Object.keys(errorClearState);

        keys.forEach((field) => {
            if (field === "CADDisposition" && isEmptyObject(clearState[field])) {
                handleErrorClearState(field, true);
                isError = true;
            } else {
                handleErrorClearState(field, false);
            }
        });

        return !isError;
    };


    async function handleSave() {
        if (!validateDispatch()) return;
        const resourceIDs = clearState.resources.map(item => item.ResourceID).join(',');

        const data = {
            IncidentID: clearState?.incidentID,
            PrimaryResourceId: clearState?.primaryResource?.ResourceID,
            IncidentDispositionsID: clearState?.CADDisposition?.IncidentDispositionsID,
            Comments: clearState?.Comments,
            Resources: resourceIDs,
            CreatedByUserFK: loginPinID,
        }
        const response = await MonitorServices.insertFinishClear(data);
        if (response?.status === 200) {
            toastifySuccess("Data Saved Successfully");
            incidentRefetch();
            resourceRefetch();
            onCloseLocation();

        }
    }

    return (
        <>
            {openClearModal ? (
                <>
                    <dialog
                        className="modal fade"
                        style={{ background: "rgba(0,0,0, 0.5)", zIndex: "9999" }}
                        id="clearModal"
                        tabIndex="-1"
                        aria-hidden="true"
                        data-backdrop="false"
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content modal-content-cad">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-12 p-0 pb-2">
                                            <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                                <p
                                                    className="p-0 m-0 font-weight-medium"
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 500,
                                                        letterSpacing: 0.5,
                                                    }}
                                                >
                                                    Finish/Clear CAD Event
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-1">
                                        <fieldset style={{ border: "1px solid gray" }}>
                                            <div className="d-flex card-body">
                                                <div
                                                    className="col-8"
                                                    style={{ display: "grid", gap: "5px" }}
                                                >
                                                    {/* Line 1 */}
                                                    <div className="tab-form-row">
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">CAD Event#</label>
                                                        </div>
                                                        <div className="col-3">
                                                            <Select
                                                                name="incidentID"
                                                                styles={fieldColourStyles}
                                                                // isClearable
                                                                options={assignedIncidentData}
                                                                value={assignedIncidentData?.find((i) => i?.IncidentID === clearState?.incidentID)}
                                                                getOptionLabel={(v) => v?.CADIncidentNumber}
                                                                getOptionValue={(v) => v?.IncidentID}
                                                                onChange={handleSelectCADEvent}
                                                                placeholder="Select..."
                                                                className="w-100"
                                                            />
                                                        </div>
                                                        <div className="col-1 d-flex align-self-center justify-content-end mt-1">
                                                            <label htmlFor="" className="new-label">
                                                                Resource#
                                                            </label>
                                                        </div>
                                                        <div className="col-6 d-flex align-items-center justify-content-end">
                                                            <Select
                                                                className="w-100"
                                                                isClearable
                                                                options={resourceDropDown}
                                                                placeholder="Select..."
                                                                name="Resources"
                                                                value={clearState.resources}
                                                                onChange={(selectedOptions) => {
                                                                    handleClearState("resources", selectedOptions);
                                                                }}
                                                                styles={fieldColourStyles}
                                                                maxMenuHeight={180}
                                                                getOptionLabel={(v) => v?.ResourceNumber}
                                                                getOptionValue={(v) => v?.ResourceID}
                                                                onInputChange={(inputValue, actionMeta) => {
                                                                    if (inputValue.length > 12) {
                                                                        return inputValue.slice(0, 12);
                                                                    }
                                                                    return inputValue;
                                                                }}
                                                                isMulti
                                                                isSearchable={true}
                                                                isDisabled
                                                            />

                                                        </div>
                                                    </div>
                                                    {/* Line 4 */}
                                                    <div className="tab-form-row">
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">CFS Code</label>
                                                        </div>
                                                        <div className="col-2">
                                                            <input
                                                                type="text"
                                                                className="form-control py-1 new-input"
                                                                name="CFSCode"
                                                                placeholder="CFS Code"
                                                                value={clearState.CFSCode}
                                                                onChange={(v) => handleClearState("CFSCode", v.target.value)}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-3">
                                                            <input
                                                                type="text"
                                                                className="form-control py-1 new-input"
                                                                name="CFSDesc"
                                                                placeholder="CFS Description"
                                                                value={clearState.CFSDesc}
                                                                onChange={(v) => handleClearState("CFSDesc", v.target.value)}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Primary Resource#</label>
                                                        </div>
                                                        <div className="col-3">

                                                            <Select
                                                                className="w-100"
                                                                isClearable
                                                                options={resourceDropDown}
                                                                placeholder="Select..."
                                                                name="primaryResource"
                                                                value={clearState?.primaryResource}
                                                                onChange={(selectedOptions) => {
                                                                    handleClearState("primaryResource", selectedOptions);
                                                                }}
                                                                styles={fieldColourStyles}
                                                                maxMenuHeight={180}
                                                                getOptionLabel={(v) => v?.ResourceNumber}
                                                                getOptionValue={(v) => v?.ResourceID}
                                                                onInputChange={(inputValue, actionMeta) => {
                                                                    if (inputValue.length > 12) {
                                                                        return inputValue.slice(0, 12);
                                                                    }
                                                                    return inputValue;
                                                                }}
                                                                // isMulti
                                                                isSearchable={true}
                                                            />

                                                        </div>

                                                    </div>
                                                    {/* Line 1 */}
                                                    <div className="tab-form-row">
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">RMS Incident#</label>
                                                        </div>
                                                        <div className="col-4">
                                                            <input
                                                                type="text"
                                                                className="form-control py-1 new-input"
                                                                name="RMSIncident"
                                                                placeholder="RMS Incident"
                                                                value={clearState.RMSIncident}
                                                                onChange={(v) => handleClearState("RMSIncident", v.target.value)}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-2 d-flex align-self-center justify-content-end mt-1">
                                                            <label htmlFor="" className="new-label">
                                                                Master Incident#
                                                            </label>
                                                        </div>
                                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                                            <input
                                                                type="text"
                                                                className="form-control py-1 new-input"
                                                                name="masterIncident"
                                                                placeholder="Master Incident"
                                                                value={clearState.masterIncident}
                                                                onChange={(v) => handleClearState("masterIncident", v.target.value)}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="tab-form-row">
                                                        <div className="col-2 d-flex align-self-center justify-content-end">
                                                            <label htmlFor="" className="new-label">
                                                                CAD Disposition{errorClearState.CADDisposition && isEmptyObject(clearState?.CADDisposition) && (
                                                                    <p className="text-nowrap" style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select CAD Disposition"}</p>
                                                                )}
                                                            </label>
                                                        </div>
                                                        <div className="col-4 d-flex align-items-center" style={{ gap: "15px" }}>
                                                            <Select
                                                                isClearable
                                                                placeholder="Select..."
                                                                options={dispositionDropDown}
                                                                getOptionLabel={(v) => v?.Description}
                                                                getOptionValue={(v) => v?.IncidentDispositionsID}
                                                                styles={requiredFieldColourStyles}
                                                                className="w-100"
                                                                value={clearState?.CADDisposition}
                                                                onChange={(e) => handleClearState("CADDisposition", e)}
                                                                name="CADDisposition" />
                                                        </div>
                                                        <div className="col-2 d-flex align-self-center justify-content-end mt-1">
                                                            <label htmlFor="" className="new-label">
                                                                Report Date & Time
                                                            </label>
                                                        </div>
                                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                                            <input
                                                                name="reportDateTime"
                                                                type="date"
                                                                className="form-control py-1 new-input"
                                                                placeholder="Reported Date & Time"
                                                                value={clearState.reportDateTime ? clearState.reportDateTime.split('T')[0] : ""}
                                                                onChange={(v) => handleClearState("reportDateTime", v.target.value)}
                                                                readonly=""
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Line 5 */}
                                                    <div className="tab-form-row">
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Location</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <input
                                                                type="text"
                                                                className="form-control py-1 new-input"
                                                                name="Location"
                                                                placeholder="Location"
                                                                value={clearState.Location}
                                                                onChange={(v) => handleClearState("Location", v.target.value)}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">APT#/ Suit</label>
                                                        </div>
                                                        <div className="col-2">
                                                            <input
                                                                type="text"
                                                                className="form-control py-1 new-input"
                                                                name="APTSuit"
                                                                placeholder="APT#/Suit"
                                                                value={clearState.APTSuit}
                                                                onChange={(v) => handleClearState("APTSuit", v.target.value)}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Line 6 */}
                                                    <div className="tab-form-row">
                                                        <div className="col-2 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Comments</label>
                                                        </div>
                                                        <div className="col-10">
                                                            <textarea name="Comments" rows="3"
                                                                placeholder="Comments"
                                                                className="form-control py-1 new-input" value={clearState?.Comments}
                                                                style={{ height: "auto", overflow: "hidden" }}
                                                                onChange={(e) => {
                                                                    handleClearState("Comments", e.target.value);
                                                                    e.target.style.height = "auto"; // Reset height
                                                                    e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scroll height
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4" style={{ zIndex: "0" }}>
                                                    <MapComponent latitude={clearState.coordinateY} longitude={clearState.coordinateX} openClearModal={openClearModal} />

                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    {/* Buttons */}
                                    <div className="row">
                                        <div className="col-12 p-0">
                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                    <button
                                                        type="button"
                                                        className="save-button ml-2"
                                                        onClick={handleSave}
                                                    >
                                                        {"Save"}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        data-dismiss="modal"
                                                        className="cancel-button"
                                                        onClick={onCloseLocation}
                                                    >
                                                        {"Cancel"}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dialog>
                </>
            ) : (
                <> </>
            )
            }
        </>
    );
};

export default memo(ClearModal);
