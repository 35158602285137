
import Select from "react-select";
import { filterPassedTime, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useState } from 'react';
import { AddDeleteUpadate, fetchPostData } from "../../../../hooks/Api";
import { Comman_changeArrayFormat } from "../../../../Common/ChangeArrayFormat";
import { get_ArresteeName_Data } from "../../../../../redux/actions/DropDownsData";
import { RequiredFieldSpaceNotAllow } from "../../../Agency/AgencyValidation/validators";
import { toastifySuccess } from "../../../../Common/AlertMsg";
import { RequiredFieldIncident } from "../../../Utility/Personnel/Validation";
import DeletePopUpModal from "../../../../Common/DeleteModal";
import MasterNameModel from "../../../MasterNameModel/MasterNameModel";
import { AgencyContext } from "../../../../../Context/Agency/Index";
import ChangesModal from "../../../../Common/ChangesModal";


const LastSeenInformation = (props) => {

    const { DecMissPerID, DecIncID } = props
    const dispatch = useDispatch();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const arresteeNameData = useSelector((state) => state.DropDown.arresteeNameData);
    const { setChangesStatus, get_MissingPerson_Count } = useContext(AgencyContext);

    const [effectiveScreenPermission, setEffectiveScreenPermission] = useState([]);
    const [missingIdDrp, setmissingIdDrp] = useState([]);
    const [occuredFromDate, setOccuredFromDate] = useState();
    const [LastSeenInfoID, setLastSeenInfoID] = useState();
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID,] = useState('');
    const [lastseenData, setlastseenData] = useState();
    const [clickedRow, setClickedRow] = useState(null);
    const [status, setStatus] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [dob, setDob] = useState();

    const [editval, setEditval] = useState();
    const [type, setType] = useState("LastSeenInfo");
    const [possessionID, setPossessionID] = useState();
    const [possenSinglData, setPossenSinglData] = useState([]);
    const [nameModalStatus, setNameModalStatus] = useState(false);
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID); setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    const [value, setValue] = useState({
        'AgencyID': '',
        'MissingPersonID': '', 'LastSeenByID': '', 'PlaceLastSeen': '', 'LastSeenWearing': '', 'LastSeenDtTm': '', 'CreatedByUserFK': '',
    });

    const [errors, setErrors] = useState({
        'LastNameErrors': '',
    })

    useEffect(() => {
        if (loginAgencyID) {
            setValue(prevValue => ({
                ...prevValue,
                'CreatedByUserFK': loginPinID,
                'MissingPersonID': DecMissPerID,
                'AgencyID': loginAgencyID,
            }));
            get_MissingPerson_Count(DecMissPerID)
        }
        get_Data();
    }, [loginAgencyID, DecMissPerID, loginPinID, nameModalStatus]);



    useEffect(() => {
        if (clickedRow?.LastSeenInfoID && status) {
            GetSingleData(clickedRow?.LastSeenInfoID)
        }
    }, [clickedRow])

    useEffect(() => {
        if (DecIncID) {
            if (arresteeNameData?.length === 0) { dispatch(get_ArresteeName_Data('', '', DecIncID)) };
        }
    }, [DecIncID, possessionID]);

    useEffect(() => {
        if (possessionID) { setValue({ ...value, ['LastSeenByID']: parseInt(possessionID) }) }
    }, [possessionID, arresteeNameData]);

    const GetSingleData = (LastSeenInfoID) => {
        fetchPostData('LastSeenInformation/GetSingleData_LastSeenInformation', { 'LastSeenInfoID': LastSeenInfoID }).then((res) => {
            if (res) setEditval(res)
            else setEditval()
        })
    }

    useEffect(() => {
        if (DecMissPerID && status) {
            setValue(pre => { return { ...pre, 'CreatedByUserFK': loginPinID, 'MissingPersonID': DecMissPerID, 'AgencyID': loginAgencyID, } });
            get_Data();
        }

    }, [DecMissPerID, loginPinID]);

    const get_Data = () => {
        const val = {
            'MissingPersonID': DecMissPerID,
        }
        // const val = { NameID: DecNameID, MasterNameID: DecMasterNameID, }
        // const val2 = { MasterNameID: DecMasterNameID, NameID: 0, 'IsMaster': MstPage === "MST-Name-Dash" ? true : false, }
        fetchPostData('LastSeenInformation/GetData_LastSeenInformation', val).then((res) => {
            if (res) {
                setlastseenData(res)
            } else {
                setlastseenData([]);
            }
        })
    }


    const ChangeDropDown = (e, name) => {
        setStatesChangeStatus(true)
        if (e) {
            setStatesChangeStatus(true)
            if (name === 'LastSeenByID') {
                setValue({ ...value, [name]: e.value }); setPossessionID(e.value); setPossenSinglData([])
                setChangesStatus(true)

            }
        } else {
            if (name === 'LastSeenByID') {
                setValue({ ...value, [name]: null });
                setPossessionID(''); setPossenSinglData([])
                setChangesStatus(true)

            } else {
                setValue({ ...value, [name]: null });
                setChangesStatus(false)

            }
        }
    }


    const check_Validation_Error = (e) => {
        if (RequiredFieldIncident(value.LastSeenByID)) {
            setErrors(prevValues => { return { ...prevValues, ['LastNameErrors']: RequiredFieldIncident(value.LastSeenByID) } })
        }

    }

    const { LastNameErrors } = errors

    useEffect(() => {
        if (LastNameErrors === 'true') {
            if (LastSeenInfoID) { update_Activity() }
            else { Add_Type() }
        }
    }, [LastNameErrors, LastSeenInfoID])

    useEffect(() => {
        if (editval) {
            setValue({
                ...value,
                'AgencyID': editval[0]?.AgencyID,
                'LastSeenInfoID': LastSeenInfoID,
                'LastSeenDtTm': editval[0]?.LastSeenDtTm ? getShowingDateText(editval[0]?.LastSeenDtTm) : '',
                'PlaceLastSeen': editval[0]?.PlaceLastSeen,
                'LastSeenWearing': editval[0]?.LastSeenWearing,
                'ModifiedByUserFK': loginPinID,

                'LastSeenByID': editval[0]?.LastSeenByID,
                'MissingPersonID': editval[0]?.MissingPersonID
            });
            setPossessionID(editval[0]?.LastSeenByID);
            setDob(editval[0]?.LastSeenDtTm ? new Date(editval[0]?.LastSeenDtTm) : '');
            //   setDob(editval[0]?.DOB ? new Date(editval[0]?.DOB) : '');
        } else {
            setValue({
                ...value,
                'LastSeenInfoID': '', 'LastSeenDtTm': '', 'PlaceLastSeen': '', 'LastSeenWearing': '', 'ModifiedByUserFK': '',
            })
        }
    }, [editval])


    const Add_Type = () => {

        const MissingPersonID = DecMissPerID
        const CreatedByUserFK = loginPinID
        const { AgencyID, NameID, LastSeenByID, PlaceLastSeen, LastSeenWearing, LastSeenDtTm } = value
        const val = { AgencyID, MissingPersonID, NameID, LastSeenByID, PlaceLastSeen, LastSeenWearing, LastSeenDtTm, CreatedByUserFK }

        AddDeleteUpadate('LastSeenInformation/Insert_LastSeenInformation', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message);
            reset();
            get_Data();
            get_MissingPerson_Count(DecMissPerID)
            setStatesChangeStatus(false)
            //   get_Name_Count(DecNameID);
            //   setModal(false)
            setChangesStatus(false)

        })
    }

    const update_Activity = () => {
        const MissingPersonID = DecMissPerID
        const ModifiedByUserFK = loginPinID
        const { AgencyID, NameID, LastSeenByID, PlaceLastSeen, LastSeenWearing, LastSeenDtTm, LastSeenInfoID } = value
        const val = { AgencyID, MissingPersonID, NameID, LastSeenByID, PlaceLastSeen, LastSeenWearing, LastSeenDtTm, ModifiedByUserFK, LastSeenInfoID }
        AddDeleteUpadate('LastSeenInformation/Update_LastSeenInformation', val).then((res) => {
            const parsedData = JSON.parse(res.data);
            const message = parsedData.Table[0].Message;
            toastifySuccess(message);
            //   setModal(false);
            setChangesStatus(false)
            get_Data(DecMissPerID);
            setErrors({
                ...errors,
                'LastNameErrors': '',
            })
            reset();
            setStatus(false);
            setStatesChangeStatus(false)
        })
    }

    const DeleteLastSeen = () => {
        const val = {
            'LastSeenInfoID': LastSeenInfoID,
            'DeletedByUserFK': loginPinID,
        }
        AddDeleteUpadate('LastSeenInformation/Delete_LastSeenInformation', val).then((res) => {
            if (res) {
                const parsedData = JSON.parse(res.data);
                const message = parsedData.Table[0].Message;
                toastifySuccess(message);
                // get_Name_Count(DecNameID);
                get_Data(DecMissPerID);
                get_MissingPerson_Count(DecMissPerID)
                // setStatus(false);
                reset()
            } else console.log("Somthing Wrong");
        })
    }

    const columns = [
        {
            name: 'Last Seen By',
            selector: (row) => row.Arrestee_Name ? row.Arrestee_Name : '',
            sortable: true
        },
        {
            name: 'Last Seen Date/Time',
            selector: (row) => row.LastSeenDtTm ? getShowingDateText(row.LastSeenDtTm) : '',
            sortable: true
        },
        {
            name: 'Place Last Seen',
            selector: (row) => row.PlaceLastSeen ? row.PlaceLastSeen : '',
            sortable: true
        },
        {
            name: 'Last Seen Wearing',
            selector: (row) => row.LastSeenWearing ? row.LastSeenWearing : '',
            sortable: true
        },

        {
            name: <p className='text-end' style={{ position: 'absolute', top: 8, right: 10 }}>Delete</p>,
            cell: row =>
                <div className="div" style={{ position: 'absolute', top: 4, right: 10 }}>

                    <span onClick={() => { setLastSeenInfoID(row.LastSeenInfoID); }} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                        <i className="fa fa-trash"></i>
                    </span>
                </div>
        }
    ]

    const set_Edit_Value = (row) => {
        reset();
        GetSingleData(row.LastSeenInfoID)
        setLastSeenInfoID(row.LastSeenInfoID);
        setStatus(true);
        // setModal(true)
        setUpdateStatus(updateStatus + 1);
        setErrors({
            ...errors,
            'LastNameErrors': '',
        })
    }

    const conditionalRowStyles = [
        {
            when: row => row === clickedRow,
            style: {
                backgroundColor: '#001f3fbd',
                color: 'white',
                cursor: 'pointer',
            },
        },
    ];

    const setStatusFalse = (e) => {
        setStatus(false); reset();
        setUpdateStatus(updateStatus + 1);
        setClickedRow(null);
        setChangesStatus(false)
        setLastSeenInfoID('');
    }

    const handleChange = (event) => {
        setStatesChangeStatus(true)
        setChangesStatus(true)
        const { name, value } = event.target;
        setValue((prevState) => ({ ...prevState, [name]: value, }));
    };


    const reset = () => {
        setValue({
            ...value,
            'AgencyID': '',
            'MissingPersonID': '', 'LastSeenByID': '', 'NameID': '', 'PlaceLastSeen': '', 'LastSeenWearing': '', 'LastSeenDtTm': '', 'CreatedByUserFK': '',
        });
        setLastSeenInfoID(''); setPossessionID(''); setPossenSinglData([]);
        setDob("")
        setErrors({ ...errors, 'LastNameErrors': '', })
        setStatesChangeStatus(false)
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    }

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 35,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const GetSingleDataPassion = (nameID, masterNameID) => {
        const val = { 'NameID': nameID, 'MasterNameID': masterNameID }
        fetchPostData('MasterName/GetSingleData_MasterName', val).then((res) => {
            if (res) {
                get_MissingPerson_Count(DecMissPerID);
                setPossenSinglData(res);
            } else { setPossenSinglData([]); }
        })
    }

    return (
        <>
            <fieldset className='mt-2'>
                <legend>Last Seen Info</legend>
                <div className="col-12">
                    <div className="row">
                        <div className="col-3 col-md-3 col-lg-1 mt-2 pt-1">
                            <label htmlFor="" className='new-label'>Last&nbsp;Seen&nbsp;By</label>
                        </div>
                        <div className="col-6 col-md-6 col-lg-4  mt-1">
                            <Select
                                name="NameID"
                                styles={colourStyles}
                                options={arresteeNameData}
                                value={arresteeNameData?.filter((obj) => obj.value === value?.LastSeenByID)}
                                isClearable
                                // isDisabled={ArrestID ? true : false}
                                onChange={(e) => ChangeDropDown(e, 'LastSeenByID')}
                                placeholder="Select..."
                            />
                            {errors.LastNameErrors !== 'true' ? (
                                <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{errors.LastNameErrors}</p>
                            ) : null}
                        </div>
                        <div className="col-3 col-md-3 col-lg-1 pt-1" data-toggle="modal" data-target="#MasterModal"  >
                            <button
                                onClick={() => {
                                    if (possessionID) { GetSingleDataPassion(possessionID); } setNameModalStatus(true);
                                }}
                                className=" btn btn-sm bg-green text-white py-1"
                            >
                                <i className="fa fa-plus" > </i>
                            </button>
                        </div>
                        {/* <div className="col-3 col-md-3 col-lg-1 pt-1" data-toggle="modal" data-target="#MasterModal"  >
                            <span className=" btn btn-sm bg-green text-white"    >
                                <i className="fa fa-plus" > </i>
                            </span>
                        </div> */}
                        <div className="col-2 col-md-3 col-lg-3 mt-2 pt-1">
                            <label htmlFor="" className='new-label'>Last Seen Date/Time</label>
                        </div>
                        <div className="col-4 col-md-7 col-lg-3">
                            <DatePicker
                                // ref={startRef1}
                                // onKeyDown={onKeyDown}
                                onKeyDown={(e) => {
                                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                                        e?.preventDefault();
                                    }
                                }}
                                name='OccurredFrom'
                                id='OccurredFrom'
                                onChange={(date) => {
                                    setDob(date); setValue({ ...value, ['LastSeenDtTm']: date ? getShowingMonthDateYear(date) : null, });
                                    setOccuredFromDate(date); setStatesChangeStatus(true)
                                    setOccuredFromDate(date);
                                    setChangesStatus(true)
                                }}
                                isClearable={dob ? true : false}
                                selected={dob}
                                placeholderText={dob ? dob : 'Select...'}
                                dateFormat="MM/dd/yyyy HH:mm"
                                timeFormat="HH:mm "
                                is24Hour
                                filterTime={filterPassedTime}
                                timeInputLabel
                                showTimeSelect
                                timeIntervals={1}
                                timeCaption="Time"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                showDisabledMonthNavigation
                                autoComplete='off'
                                maxDate={new Date()}
                            />
                        </div>
                        <div className="col-2 col-md-3 col-lg-1 mt-2 ">
                            <label htmlFor="" className='new-label'>Place&nbsp;Last&nbsp;Seen</label>
                        </div>
                        <div className="col-4 col-md-3 col-lg-4 mt-1 text-field">
                            <input type="text" className='' value={value.PlaceLastSeen}
                                onChange={(e) => { handleChange(e) }} name="PlaceLastSeen" required />
                        </div>
                        <div className="col-2 col-md-3 col-lg-4 mt-2 ">
                            <label htmlFor="" className='new-label'>Last Seen Wearing</label>
                        </div>
                        <div className="col-4 col-md-3 col-lg-3 mt-1 text-field">
                            <input type="text" className='' value={value.LastSeenWearing}
                                onChange={handleChange} name="LastSeenWearing" required />
                        </div>
                    </div>
                </div>
            </fieldset>
            <div className="col-12 text-right mt-2 p-0">
                <button type="button" className="btn btn-sm btn-success mr-1 " onClick={() => { setStatusFalse(); conditionalRowStyles(''); setUpdateStatus(updateStatus + 1); }}>New</button>
                {
                    LastSeenInfoID && status ?
                        <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={(e) => { check_Validation_Error(); }} >Update</button>
                        :
                        <button type="button" className="btn btn-sm btn-success mr-1" onClick={(e) => { check_Validation_Error(); }} >Save</button>
                }
            </div >
            <div className="col-12 mt-2">
                <DataTable
                    dense
                    columns={columns}
                    data={lastseenData}
                    customStyles={tableCustomStyles}
                    selectableRowsHighlight
                    highlightOnHover
                    responsive
                    fixedHeaderScrollHeight=''
                    fixedHeader
                    persistTableHead={true}
                    conditionalRowStyles={conditionalRowStyles}

                    onRowClicked={(row) => {
                        setClickedRow(row);
                        set_Edit_Value(row);
                        setStatesChangeStatus(false)
                    }}
                />
            </div>
            <DeletePopUpModal func={DeleteLastSeen} clearID={setLastSeenInfoID} />
            <MasterNameModel {...{ value, setValue, nameModalStatus, setNameModalStatus, loginPinID, loginAgencyID, type, possenSinglData, setPossessionID, possessionID, setPossenSinglData, GetSingleDataPassion, }} />
            <ChangesModal func={check_Validation_Error} />
        </>
    )
}

export default LastSeenInformation