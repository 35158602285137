import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CadDashboardTableCustomStyles, getShowingYearMonthDate, stringToBase64 } from '../../../Components/Common/Utility';
import { useNavigate } from 'react-router-dom';
import DateTimeCounter from '../../Common/DateTimeCounter';
import { IncidentContext } from '../../../CADContext/Incident';
import CommentsModal from '../../CommentsModal';
import { compareStrings } from '../../../CADUtils/functions/common';
import Tooltip from '../../Common/Tooltip';
import MasterTableListServices from '../../../CADServices/APIs/masterTableList'
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';


const IncidentTableSection = ({ isIncidentDispatch }) => {
  const { incidentData } = useContext(IncidentContext);
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [editValue, setEditValue] = useState("");
  const [incidentID, setIncidentID] = useState("");
  const [incidentNumber, setIncidentNumber] = useState("");
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [statusColorData, setStatusColorData] = useState([]);
  const [loginAgencyID, setLoginAgencyID] = useState('');


  const navigate = useNavigate();

  // useEffect(() => {
  //   navigate(`/cad/dashboard-page?IncId=${stringToBase64(incidentID)}&IncNo=${incidentNumber}&isResourceView=${isResourceView}&IncSta=true`);
  // }, [incidentID, incidentNumber, isResourceView, editValue]);

  // const conditionalRowStyles = [
  //   {
  //     when: (row) => row.IncidentID === incidentID,
  //     style: {
  //       backgroundColor: '#001f3fbd',
  //       color: 'white',
  //       cursor: 'pointer',
  //       '&:hover': {
  //         backgroundColor: '#001f3fbd',
  //         color: 'white',
  //       },
  //     },
  //   },
  // ];
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 670);

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);

    }
  }, [localStoreData]);


  const getPriorityKey = `/CAD/MasterPriority/GetMasterPriority`;
  const { data: priorityList, isSuccess: isFetchPriorityList } = useQuery(
    [getPriorityKey, {
      IsActive: 1,
      AgencyID: loginAgencyID,
    }],
    MasterTableListServices.getMasterPriority,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
  useEffect(() => {
    if (isFetchPriorityList && priorityList) {
      const res = JSON.parse(priorityList?.data?.data);
      const data = res?.Table
      setStatusColorData(data || [])
    } else {
      setStatusColorData([])
    }
  }, [isFetchPriorityList, priorityList])

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1400);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getStatusColors = (statusCode) => {
    const statusItem = statusColorData.find(item => item.PriorityCode === statusCode);
    return statusItem
      ? { backgroundColor: statusItem.BackColor }
      : {}; // Default to empty if no match found
  };
  const conditionalRowStyles = [
    {
      when: () => true,
      style: (row) => getStatusColors(row.PriorityCode),
    },
  ];

  const [columns, setColumns] = useState([
    {
      name: <p className='text-center' style={{ position: 'absolute', top: '7px' }} >Comments</p>,
      cell: (row) => (
        <>
          <span
            className="btn btn-sm text-white p-1 py-0 mr-2"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            <button className="d-flex justify-content-end btn btn-sm px-1 py-0" data-toggle="modal"
              data-target="#CommentModal" onClick={() => { setOpenCommentModal(true); setIncidentID(row?.IncidentID); setIncidentNumber(row?.CADIncidentNumber); }} >
              <i className="fa fa-comment"></i>
            </button>
          </span>

        </>
      ),
      width: isSmallScreen ? "70px" : "70px",
    },
    {
      name: <p className='text-center' style={{ position: 'absolute', top: '7px' }} >View</p>,
      cell: (row) => (
        <>

          <span
            className="btn btn-sm text-white p-1 py-0"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            <button className="d-flex justify-content-end btn btn-sm px-1 py-0" data-toggle="modal"
              data-target="#CommentModal" onClick={() => navigate(`/cad/dispatcher?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&IncSta=true`)
              } >
              <i className="fa fa-eye"></i>
            </button>
          </span>
        </>
      ),
      width: isSmallScreen ? "60px" : "60px",
    },
    {
      name: 'CAD Event #',
      selector: (row) => row?.CADIncidentNumber || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CADIncidentNumber, rowB.CADIncidentNumber),
      width: isSmallScreen ? "130px" : "130px",
    },
    {
      name: 'RMS Incident #',
      selector: (row) => row?.IncidentNumber || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.IncidentNumber, rowB.IncidentNumber),
      width: isSmallScreen ? "130px" : "130px",
    },
    {
      name: 'Location',
      selector: (row) => row?.CrimeLocation || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CrimeLocation, rowB.CrimeLocation),
      width: isSmallScreen ? "350px" : "350px",
    },
    {
      name: 'Incident DT/TM',
      selector: (row) => row?.ReportedDate || '',
      // sortable: true,
      // sortFunction: (rowA, rowB) => compareStrings(rowA.ReportedDate, rowB.ReportedDate),
      width: isSmallScreen ? "190px" : "190px",
    },
    {
      name: 'CFS Code',
      selector: (row) => row?.CFSCODE || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCODE, rowB.CFSCODE),
      width: isSmallScreen ? "100px" : "100px",
    },
    {
      name: 'CFS Description',
      selector: (row) => row?.CFSCodeDescription ? row?.CFSCodeDescription : '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.CFSCodeDescription, rowB.CFSCodeDescription),
      width: isSmallScreen ? "140px" : "140px",
      cell: (row) => (
        <Tooltip text={row?.CFSCodeDescription || ''} maxLength={15} />
      ),
    },
    {
      name: 'Priority',
      selector: (row) => row?.PriorityCode || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.PriorityCode, rowB.PriorityCode),
      width: isSmallScreen ? "100px" : "100px",
    },
    {
      name: 'Resources#',
      selector: (row) => row?.Resources || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Resources, rowB.Resources),
      width: isSmallScreen ? "290px" : "290px",
    },
    {
      name: 'E Timer',
      selector: (row) => row.ReportedDate ? <DateTimeCounter data={row.ReportedDate} /> : '',
      sortable: true,
      width: isSmallScreen ? "90px" : "90px",
    },
    {
      name: 'Primary Resource',
      selector: (row) => row?.PrimaryResourceName || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.PrimaryResourceName, rowB.PrimaryResourceName),
      width: isSmallScreen ? "145px" : "145px",
    },
   
    {
      name: 'Source',
      selector: (row) => row?.Source || '',
      sortable: true,
      sortFunction: (rowA, rowB) => compareStrings(rowA.Source, rowB.Source),
      // width: "12%"
      width: isSmallScreen ? "85px" : undefined,
    },
    {
      name: 'Operator',
      // selector: (row) => row?.CADIncidentNumber || '',
      // sortable: true,
      // sortFunction: (rowA, rowB) => compareStrings(rowA.CADIncidentNumber, rowB.CADIncidentNumber),
      width: isSmallScreen ? "105px" : undefined,
    },
    {
      name: 'Apt#',
      // selector: (row) => row?.CADIncidentNumber || '',
      // sortable: true,
      // sortFunction: (rowA, rowB) => compareStrings(rowA.CADIncidentNumber, rowB.CADIncidentNumber),
      width: isSmallScreen ? "105px" : undefined,
    },
    {
      name: 'Zone',
      // selector: (row) => row?.CADIncidentNumber || '',
      // sortable: true,
      // sortFunction: (rowA, rowB) => compareStrings(rowA.CADIncidentNumber, rowB.CADIncidentNumber),
      width: isSmallScreen ? "105px" : undefined,
    },
    // {
    //   name: 'Agency Code',
    //   selector: (row) => row?.AgencyCode || '',
    //   sortable: true,
    //   sortFunction: (rowA, rowB) => compareStrings(rowA.AgencyCode, rowB.AgencyCode),
    //   width: isSmallScreen ? "100px" : undefined,
    // }
  ])
  
  const onIncidentRowClick = row => {
    setIncidentID(row?.IncidentID)
    setIncidentNumber(row?.CADIncidentNumber)
    setEditValue(row);

    navigate(`/cad/dashboard-page?IncId=${stringToBase64(row?.IncidentID)}&IncNo=${row?.CADIncidentNumber}&isResourceView=false&IncSta=true`);

  }

  const [draggingColumn, setDraggingColumn] = useState(null); // Track the dragged column
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null); // Track the selected column for dragging

  // Function to handle column selection (click event)
  const handleColumnSelect = (index) => {
    setSelectedColumnIndex(index);
  };

  const handleColumnDragStart = (event, index) => {
    setDraggingColumn(index);
    event.dataTransfer.setData("text", index); // Store the index of the column being dragged
  };

  const handleColumnDrop = (event, index) => {
    const draggedIndex = event.dataTransfer.getData("text");
    if (draggedIndex !== index) {
      const newColumns = [...columns];
      const [movedColumn] = newColumns.splice(draggedIndex, 1);
      newColumns.splice(index, 0, movedColumn);
      setColumns(newColumns);
    }
    setDraggingColumn(null);
  };


  return (
    <>
      <div className='table-container'>
        <DataTable
          dense
          columns={columns.map((column, index) => ({
            ...column,
            name: (
              <div
                className={selectedColumnIndex === index ? "selected-column" : ""}
                draggable
                onDragStart={(e) => handleColumnDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleColumnDrop(e, index)}
                onClick={() => handleColumnSelect(index)}
              >
                {column.name}
              </div>
            ),
          }))}
          data={incidentData}
          onRowClicked={onIncidentRowClick}
          customStyles={CadDashboardTableCustomStyles}
          conditionalRowStyles={conditionalRowStyles}
          // conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationPerPage={'50'}
          paginationRowsPerPageOptions={[50, 100, 150, 200, 500]}
          showPaginationBottom={50}
          responsive
          striped
          highlightOnHover
          fixedHeader
          persistTableHead={true}
        />
      </div>
      <CommentsModal
        {...{
          openCommentModal,
          setOpenCommentModal,
          incidentID,
          incidentNumber
        }}
      />
    </>
  );
};

export default IncidentTableSection;