import React from 'react'
import Dashboard from '../Components/Pages/Dashboard'

const DashboardPage = () => {
    return (
        <>
            <Dashboard />
        </>
    )
}

export default DashboardPage