// Lewisville boundary coordinates
export const mapBoundary = [
    [
      33.07100916297986,
      -97.04451084136964
    ],
    [
      33.05968013891247,
      -97.04461812973024
    ],
    [
      33.05788174701476,
      -97.04466104507446
    ],
    [
      33.057414159103416,
      -97.04448938369752
    ],
    [
      33.0483316705652,
      -97.04453229904176
    ],
    [
      33.04415880732112,
      -97.04461812973024
    ],
    [
      33.043547250407755,
      -97.04463958740234
    ],
    [
      33.042827766249935,
      -97.04461812973024
    ],
    [
      33.042288149274796,
      -97.04455375671388
    ],
    [
      33.04241406019801,
      -97.04337358474733
    ],
    [
      33.042288149274796,
      -97.03603506088258
    ],
    [
      33.038420797549016,
      -97.03609943389894
    ],
    [
      33.0280590557869,
      -97.03618526458742
    ],
    [
      33.0269256663613,
      -97.03635692596437
    ],
    [
      33.02118653426295,
      -97.03670024871828
    ],
    [
      33.02077272659778,
      -97.0367217063904
    ],
    [
      33.02071875154171,
      -97.03524112701416
    ],
    [
      33.01654458044831,
      -97.03524112701416
    ],
    [
      33.01659855805986,
      -97.03397512435915
    ],
    [
      33.01352178146273,
      -97.03399658203126
    ],
    [
      33.01352178146273,
      -97.02777385711671
    ],
    [
      33.0077097994295,
      -97.02768802642824
    ],
    [
      32.9991080829912,
      -97.02783823013307
    ],
    [
      32.99880214876104,
      -97.02813863754274
    ],
    [
      32.9988741333812,
      -97.028546333313
    ],
    [
      32.99633664006803,
      -97.02858924865723
    ],
    [
      32.99084748645159,
      -97.02861070632935
    ],
    [
      32.98935365768133,
      -97.02858924865723
    ],
    [
      32.98928166529478,
      -97.02826738357544
    ],
    [
      32.98787780202023,
      -97.02831029891968
    ],
    [
      32.98782380683301,
      -97.02678680419923
    ],
    [
      32.9885257416907,
      -97.02631473541261
    ],
    [
      32.989083685928954,
      -97.02571392059328
    ],
    [
      32.98985760274303,
      -97.02500581741334
    ],
    [
      32.990757497482285,
      -97.02446937561035
    ],
    [
      32.99167538066394,
      -97.02404022216797
    ],
    [
      32.9924132798292,
      -97.0240831375122
    ],
    [
      32.99284521794124,
      -97.02393293380739
    ],
    [
      32.99349312114543,
      -97.02337503433228
    ],
    [
      32.99365509620324,
      -97.02255964279176
    ],
    [
      32.99360110455033,
      -97.02191591262819
    ],
    [
      32.99329515122658,
      -97.02163696289062
    ],
    [
      32.99277322840271,
      -97.02174425125123
    ],
    [
      32.99244927475262,
      -97.02200174331665
    ],
    [
      32.99216131495425,
      -97.0221519470215
    ],
    [
      32.99167538066394,
      -97.02208757400514
    ],
    [
      32.99124343682642,
      -97.02174425125123
    ],
    [
      32.991225439120626,
      -97.02118635177614
    ],
    [
      32.99118944369809,
      -97.02067136764528
    ],
    [
      32.99088348201361,
      -97.02037096023561
    ],
    [
      32.990523525732755,
      -97.02039241790773
    ],
    [
      32.990181565906155,
      -97.02071428298952
    ],
    [
      32.98983960475467,
      -97.02082157135011
    ],
    [
      32.98946164615107,
      -97.02077865600587
    ],
    [
      32.98938965385259,
      -97.02028512954712
    ],
    [
      32.989335659590196,
      -97.01964139938356
    ],
    [
      32.98796779392557,
      -97.01953411102295
    ],
    [
      32.98730185165359,
      -97.01934099197389
    ],
    [
      32.98672589752893,
      -97.0190191268921
    ],
    [
      32.98638392298934,
      -97.01869726181032
    ],
    [
      32.986113942153864,
      -97.01818227767944
    ],
    [
      32.98627593075425,
      -97.01745271682739
    ],
    [
      32.986779893387755,
      -97.01706647872925
    ],
    [
      32.987697817934354,
      -97.01695919036867
    ],
    [
      32.98796779392557,
      -97.01646566390993
    ],
    [
      32.98796779392557,
      -97.01550006866456
    ],
    [
      32.98731985015941,
      -97.01328992843628
    ],
    [
      32.9869598793453,
      -97.01168060302736
    ],
    [
      32.99379907378283,
      -97.00515747070314
    ],
    [
      32.99455495222187,
      -96.99296951293947
    ],
    [
      32.98935365768133,
      -96.99316263198854
    ],
    [
      32.98935365768133,
      -96.99002981185915
    ],
    [
      32.98638392298934,
      -96.98990106582642
    ],
    [
      32.987049872186454,
      -96.99314117431642
    ],
    [
      32.98564597341633,
      -96.9933557510376
    ],
    [
      32.985339992515854,
      -96.99153184890748
    ],
    [
      32.9852140000721,
      -96.98801279067995
    ],
    [
      32.98582596168588,
      -96.9858241081238
    ],
    [
      32.98877771694472,
      -96.98020219802858
    ],
    [
      32.99174737109801,
      -96.9741940498352
    ],
    [
      32.99054152358165,
      -96.97370052337648
    ],
    [
      32.991027464114886,
      -96.97136163711548
    ],
    [
      32.991045461861034,
      -96.96906566619874
    ],
    [
      32.99039754068763,
      -96.96762800216676
    ],
    [
      32.98973161674735,
      -96.96664094924928
    ],
    [
      32.988345758926,
      -96.9666624069214
    ],
    [
      32.98647391641768,
      -96.96232795715332
    ],
    [
      32.98620393585743,
      -96.95868015289308
    ],
    [
      32.99072150186889,
      -96.95865869522096
    ],
    [
      32.99199933715477,
      -96.95900201797487
    ],
    [
      32.99541880536709,
      -96.95921659469604
    ],
    [
      32.99525683354652,
      -96.95329427719118
    ],
    [
      32.991909349359936,
      -96.95307970046998
    ],
    [
      32.99054152358165,
      -96.95303678512575
    ],
    [
      32.99088348201361,
      -96.94717884063722
    ],
    [
      32.991081457342325,
      -96.94812297821046
    ],
    [
      32.991225439120626,
      -96.94956064224245
    ],
    [
      32.99174737109801,
      -96.94996833801271
    ],
    [
      32.9928992100566,
      -96.94953918457033
    ],
    [
      32.99423100511219,
      -96.94825172424318
    ],
    [
      32.996012699498976,
      -96.95136308670045
    ],
    [
      32.99869417172062,
      -96.95546150207521
    ],
    [
      32.99997189156199,
      -96.95616960525514
    ],
    [
      33.000007883402226,
      -96.95344448089601
    ],
    [
      33.00344503648948,
      -96.95301532745363
    ],
    [
      33.00333706513135,
      -96.94477558135988
    ],
    [
      33.00488464197009,
      -96.94449663162231
    ],
    [
      33.00486664704656,
      -96.93966865539551
    ],
    [
      33.0057124044831,
      -96.94080591201784
    ],
    [
      33.00628823469342,
      -96.94224357604982
    ],
    [
      33.00648617546001,
      -96.94477558135988
    ],
    [
      33.00610828815651,
      -96.94702863693239
    ],
    [
      33.00526253451575,
      -96.94938898086548
    ],
    [
      33.005424487968284,
      -96.95097684860231
    ],
    [
      33.00645018626276,
      -96.95147037506104
    ],
    [
      33.00745787823522,
      -96.95104122161867
    ],
    [
      33.00803369705061,
      -96.95007562637329
    ],
    [
      33.00902337685643,
      -96.94932460784914
    ],
    [
      33.01030094709371,
      -96.94895982742311
    ],
    [
      33.01120063333848,
      -96.9491744041443
    ],
    [
      33.01219027760684,
      -96.94998979568483
    ],
    [
      33.0134138224396,
      -96.95082664489746
    ],
    [
      33.01487125809996,
      -96.95101976394655
    ],
    [
      33.01605878045722,
      -96.95059061050416
    ],
    [
      33.017588141749016,
      -96.95009708404541
    ],
    [
      33.018667674924494,
      -96.94990396499635
    ],
    [
      33.01872165123629,
      -96.94904565811157
    ],
    [
      33.018199878838004,
      -96.94846630096437
    ],
    [
      33.01809192554247,
      -96.94634199142457
    ],
    [
      33.020304941680756,
      -96.94453954696655
    ],
    [
      33.021366450033284,
      -96.94426059722902
    ],
    [
      33.022535893590124,
      -96.944797039032
    ],
    [
      33.02334549927223,
      -96.94505453109743
    ],
    [
      33.02492870667317,
      -96.94449663162231
    ],
    [
      33.02597216873085,
      -96.94438934326173
    ],
    [
      33.02687169507272,
      -96.94471120834352
    ],
    [
      33.030739553729994,
      -96.9445824623108
    ],
    [
      33.03324007897561,
      -96.94449663162231
    ],
    [
      33.03363583905725,
      -96.94413185119629
    ],
    [
      33.03403159736134,
      -96.93591356277467
    ],
    [
      33.03967995396034,
      -96.93599939346315
    ],
    [
      33.039608002650226,
      -96.93110704421998
    ],
    [
      33.041154942866875,
      -96.93102121353151
    ],
    [
      33.04084915451313,
      -96.92636489868164
    ],
    [
      33.03752138909382,
      -96.92621469497682
    ],
    [
      33.03748541256464,
      -96.91842555999757
    ],
    [
      33.03583047634076,
      -96.91840410232545
    ],
    [
      33.03593840791161,
      -96.91025018692018
    ],
    [
      33.03460724263058,
      -96.91014289855958
    ],
    [
      33.03341997014217,
      -96.91063642501831
    ],
    [
      33.03289828474737,
      -96.91100120544434
    ],
    [
      33.03241257487925,
      -96.91089391708375
    ],
    [
      33.031890883520305,
      -96.91121578216554
    ],
    [
      33.03153109457672,
      -96.91198825836183
    ],
    [
      33.03086548115845,
      -96.91243886947632
    ],
    [
      33.03097341881105,
      -96.91336154937746
    ],
    [
      33.030739553729994,
      -96.91462755203248
    ],
    [
      33.03032579091234,
      -96.91477775573732
    ],
    [
      33.03050568802829,
      -96.9153141975403
    ],
    [
      33.030451718932056,
      -96.91561460494997
    ],
    [
      33.02993001596459,
      -96.91546440124513
    ],
    [
      33.02832890826376,
      -96.91664457321167
    ],
    [
      33.02823895752994,
      -96.91739559173585
    ],
    [
      33.02730346445595,
      -96.91713809967042
    ],
    [
      33.02719552230843,
      -96.9002938270569
    ],
    [
      33.02717753193768,
      -96.89943552017213
    ],
    [
      33.024191079490265,
      -96.89941406250001
    ],
    [
      33.024550898399404,
      -96.89831972122194
    ],
    [
      33.02458688020955,
      -96.89454317092897
    ],
    [
      33.02787915367661,
      -96.89505815505983
    ],
    [
      33.02793312434824,
      -96.89336299896242
    ],
    [
      33.02908449080332,
      -96.89329862594606
    ],
    [
      33.02921042059679,
      -96.88780546188356
    ],
    [
      33.03879854636211,
      -96.88703298568727
    ],
    [
      33.04444659734049,
      -96.8881916999817
    ],
    [
      33.04493224086592,
      -96.89239740371706
    ],
    [
      33.0492669403753,
      -96.89259052276613
    ],
    [
      33.05358344154796,
      -96.8927836418152
    ],
    [
      33.05761198506132,
      -96.89216136932373
    ],
    [
      33.05746811168148,
      -96.89100265502931
    ],
    [
      33.053727321276156,
      -96.8917965888977
    ],
    [
      33.05379926105208,
      -96.88694715499878
    ],
    [
      33.04867340491667,
      -96.88699007034303
    ],
    [
      33.04725250074941,
      -96.88694715499878
    ],
    [
      33.048061879351025,
      -96.88209772109985
    ],
    [
      33.04667693810716,
      -96.8811321258545
    ],
    [
      33.04692874722598,
      -96.87896490097047
    ],
    [
      33.04694673356406,
      -96.87862157821657
    ],
    [
      33.0457236542068,
      -96.87860012054443
    ],
    [
      33.04565170783332,
      -96.8773126602173
    ],
    [
      33.04838562870885,
      -96.8773126602173
    ],
    [
      33.0494647846392,
      -96.87724828720094
    ],
    [
      33.0494288129879,
      -96.8741798400879
    ],
    [
      33.060974958329176,
      -96.87332153320312
    ],
    [
      33.06137059379638,
      -96.86645507812501
    ],
    [
      33.06608211584629,
      -96.86615467071535
    ],
    [
      33.06604615098446,
      -96.87121868133545
    ],
    [
      33.06971449118448,
      -96.87117576599123
    ],
    [
      33.07043375564479,
      -96.8776559829712
    ],
    [
      33.063780335057686,
      -96.8931269645691
    ],
    [
      33.05964417143456,
      -96.90085172653198
    ],
    [
      33.060885040929534,
      -96.90168857574464
    ],
    [
      33.06527290289964,
      -96.90151691436769
    ],
    [
      33.06671149854953,
      -96.90083026885988
    ],
    [
      33.06701719709558,
      -96.90121650695801
    ],
    [
      33.068779438596415,
      -96.90046548843385
    ],
    [
      33.06904916632419,
      -96.90113067626955
    ],
    [
      33.06933687498922,
      -96.90102338790895
    ],
    [
      33.070074124149585,
      -96.90132379531862
    ],
    [
      33.07138677199852,
      -96.90181732177736
    ],
    [
      33.07268141918812,
      -96.90218210220338
    ],
    [
      33.07343662125131,
      -96.90228939056396
    ],
    [
      33.072429683726526,
      -96.90282583236696
    ],
    [
      33.072429683726526,
      -96.90471410751343
    ],
    [
      33.073400659395304,
      -96.90503597259523
    ],
    [
      33.07417383606029,
      -96.90417766571046
    ],
    [
      33.07498296715438,
      -96.90389871597291
    ],
    [
      33.07498296715438,
      -96.90138816833498
    ],
    [
      33.075540364245725,
      -96.90149545669557
    ],
    [
      33.07616967929783,
      -96.90250396728517
    ],
    [
      33.07728445491087,
      -96.90226793289185
    ],
    [
      33.078039617461165,
      -96.90252542495729
    ],
    [
      33.07750021630104,
      -96.90329790115356
    ],
    [
      33.07866891463423,
      -96.90456390380861
    ],
    [
      33.077967697497584,
      -96.9054651260376
    ],
    [
      33.07883073317964,
      -96.90685987472534
    ],
    [
      33.079388105890764,
      -96.90713882446289
    ],
    [
      33.07917234913123,
      -96.907696723938
    ],
    [
      33.07782385739403,
      -96.90718173980713
    ],
    [
      33.07766203699639,
      -96.90773963928224
    ],
    [
      33.07723051448064,
      -96.90816879272462
    ],
    [
      33.078093557395256,
      -96.9102931022644
    ],
    [
      33.07924426810989,
      -96.91042184829713
    ],
    [
      33.07983759744004,
      -96.91121578216554
    ],
    [
      33.080215168566305,
      -96.91205263137817
    ],
    [
      33.07999941383548,
      -96.91359758377077
    ],
    [
      33.07926224784537,
      -96.91505670547487
    ],
    [
      33.07902851099754,
      -96.9158935546875
    ],
    [
      33.07839921639704,
      -96.91494941711427
    ],
    [
      33.078039617461165,
      -96.91349029541016
    ],
    [
      33.077787897331376,
      -96.91299676895143
    ],
    [
      33.07764405693381,
      -96.91246032714844
    ],
    [
      33.07723051448064,
      -96.9122886657715
    ],
    [
      33.07681697008336,
      -96.91256761550905
    ],
    [
      33.07417383606029,
      -96.9083619117737
    ],
    [
      33.075144792480195,
      -96.90726757049562
    ],
    [
      33.07489306406697,
      -96.90666675567628
    ],
    [
      33.073922104868835,
      -96.90660238265993
    ],
    [
      33.074209797600275,
      -96.90525054931642
    ],
    [
      33.07296911597655,
      -96.90572261810304
    ],
    [
      33.072285834567964,
      -96.90606594085695
    ],
    [
      33.07205207918368,
      -96.90452098846437
    ],
    [
      33.07156658524737,
      -96.90417766571046
    ],
    [
      33.07127888387283,
      -96.9048857688904
    ],
    [
      33.07187226692704,
      -96.90662384033205
    ],
    [
      33.073958066511715,
      -96.91087245941164
    ],
    [
      33.07408393214602,
      -96.91153764724733
    ],
    [
      33.072807286648796,
      -96.91179513931276
    ],
    [
      33.072609494844016,
      -96.91226720809938
    ],
    [
      33.07336469752457,
      -96.9121813774109
    ],
    [
      33.07474921893603,
      -96.91297531127931
    ],
    [
      33.075864012549054,
      -96.9147562980652
    ],
    [
      33.0765113055837,
      -96.91634416580202
    ],
    [
      33.075198734189314,
      -96.91645145416261
    ],
    [
      33.07388614321127,
      -96.9162368774414
    ],
    [
      33.07374229643397,
      -96.9171166419983
    ],
    [
      33.073814219852025,
      -96.91769599914552
    ],
    [
      33.07465931560975,
      -96.91754579544069
    ],
    [
      33.076133718558985,
      -96.91840410232545
    ],
    [
      33.07728445491087,
      -96.91917657852174
    ],
    [
      33.07679898984809,
      -96.91954135894777
    ],
    [
      33.07705071280766,
      -96.92074298858643
    ],
    [
      33.0767630293665,
      -96.92213773727418
    ],
    [
      33.07670908861653,
      -96.9226098060608
    ],
    [
      33.075684208083096,
      -96.92273855209352
    ],
    [
      33.0751807536233,
      -96.92374706268312
    ],
    [
      33.074875083438464,
      -96.92443370819093
    ],
    [
      33.07349056400778,
      -96.92415475845338
    ],
    [
      33.07277132453555,
      -96.92331790924074
    ],
    [
      33.07162052915036,
      -96.92263126373292
    ],
    [
      33.070074124149585,
      -96.9218373298645
    ],
    [
      33.06975045454715,
      -96.92254543304443
    ],
    [
      33.06937283850621,
      -96.92286729812622
    ],
    [
      33.070559626320886,
      -96.92413330078126
    ],
    [
      33.07185428568114,
      -96.92516326904298
    ],
    [
      33.074065951352146,
      -96.92696571350099
    ],
    [
      33.074515470096614,
      -96.92709445953369
    ],
    [
      33.073994028139886,
      -96.92745923995972
    ],
    [
      33.073526525827035,
      -96.92803859710695
    ],
    [
      33.07309498302566,
      -96.92836046218873
    ],
    [
      33.07323883086132,
      -96.92893981933595
    ],
    [
      33.07221390990049,
      -96.93074226379396
    ],
    [
      33.071404753339934,
      -96.93069934844972
    ],
    [
      33.071117051436254,
      -96.9316864013672
    ],
    [
      33.07043375564479,
      -96.93162202835083
    ],
    [
      33.0691750389809,
      -96.93127870559692
    ],
    [
      33.068941275332286,
      -96.93194389343262
    ],
    [
      33.06908512995876,
      -96.93318843841554
    ],
    [
      33.068959257173475,
      -96.93436861038208
    ],
    [
      33.06865356537382,
      -96.93587064743043
    ],
    [
      33.07235775917663,
      -96.94878816604616
    ],
    [
      33.07232179687965,
      -96.95099830627443
    ],
    [
      33.071117051436254,
      -96.9565773010254
    ],
    [
      33.06487728497327,
      -96.98852777481079
    ],
    [
      33.065740449065295,
      -96.99000835418703
    ],
    [
      33.06561457149579,
      -96.99103832244874
    ],
    [
      33.06527290289964,
      -96.99080228805543
    ],
    [
      33.065111059417454,
      -96.99138164520265
    ],
    [
      33.06554264137527,
      -96.99281930923463
    ],
    [
      33.06678342771472,
      -96.99638128280641
    ],
    [
      33.06730491240415,
      -96.9971752166748
    ],
    [
      33.06775446569061,
      -96.99666023254396
    ],
    [
      33.06825796264496,
      -96.99640274047853
    ],
    [
      33.0690311845014,
      -96.99687480926514
    ],
    [
      33.06949871069991,
      -96.99651002883911
    ],
    [
      33.06982238122834,
      -96.9967031478882
    ],
    [
      33.069624582713566,
      -96.99614524841309
    ],
    [
      33.06899522084479,
      -96.99612379074097
    ],
    [
      33.06892329348746,
      -96.99563026428224
    ],
    [
      33.068473746172096,
      -96.99543714523315
    ],
    [
      33.06797025045236,
      -96.99584484100342
    ],
    [
      33.06762859100188,
      -96.99578046798706
    ],
    [
      33.06798823249197,
      -96.99535131454469
    ],
    [
      33.06793428636216,
      -96.99466466903688
    ],
    [
      33.06805116960161,
      -96.99504017829896
    ],
    [
      33.06825796264496,
      -96.99521183967592
    ],
    [
      33.068186034685,
      -96.99485778808595
    ],
    [
      33.068446773260135,
      -96.99508309364319
    ],
    [
      33.068473746172096,
      -96.99482560157777
    ],
    [
      33.068554664858326,
      -96.99465394020082
    ],
    [
      33.06822199867233,
      -96.99449300765993
    ],
    [
      33.06815007068298,
      -96.99392437934877
    ],
    [
      33.068473746172096,
      -96.99386000633241
    ],
    [
      33.068608610607846,
      -96.99368834495544
    ],
    [
      33.06802419656014,
      -96.99288368225099
    ],
    [
      33.06772749255818,
      -96.99289441108705
    ],
    [
      33.06759262677204,
      -96.99273347854616
    ],
    [
      33.06787134916891,
      -96.99220776557924
    ],
    [
      33.06850971004182,
      -96.99153184890748
    ],
    [
      33.06924696613247,
      -96.99148893356325
    ],
    [
      33.06969650949766,
      -96.99138164520265
    ],
    [
      33.07064953383641,
      -96.99196100234985
    ],
    [
      33.070847330047194,
      -96.99223995208742
    ],
    [
      33.07066751532847,
      -96.99288368225099
    ],
    [
      33.071063107224596,
      -96.99359178543092
    ],
    [
      33.071332827952226,
      -96.99344158172609
    ],
    [
      33.07189024816924,
      -96.9940209388733
    ],
    [
      33.072483627100375,
      -96.9942355155945
    ],
    [
      33.072933153929434,
      -96.9940209388733
    ],
    [
      33.07332873563915,
      -96.99372053146362
    ],
    [
      33.07356248763162,
      -96.9941282272339
    ],
    [
      33.07338267846177,
      -96.99466466903688
    ],
    [
      33.07338267846177,
      -96.99537277221681
    ],
    [
      33.07334671658371,
      -96.99593067169191
    ],
    [
      33.07372431557025,
      -96.99618816375732
    ],
    [
      33.0741918168321,
      -96.99608087539674
    ],
    [
      33.07489306406697,
      -96.9956946372986
    ],
    [
      33.075540364245725,
      -96.99578046798706
    ],
    [
      33.07611573818406,
      -96.99696063995363
    ],
    [
      33.07780587736453,
      -96.99777603149415
    ],
    [
      33.077626076867574,
      -96.99833393096925
    ],
    [
      33.07633150244072,
      -96.99902057647706
    ],
    [
      33.0751807536233,
      -96.99975013732912
    ],
    [
      33.07615169893025,
      -97.00056552886964
    ],
    [
      33.077302435046924,
      -97.00069427490234
    ],
    [
      33.077626076867574,
      -97.00168132781984
    ],
    [
      33.077626076867574,
      -97.00275421142578
    ],
    [
      33.078201437164225,
      -97.00258255004884
    ],
    [
      33.07791375748632,
      -97.00326919555665
    ],
    [
      33.07766203699639,
      -97.00425624847414
    ],
    [
      33.07755415656588,
      -97.00515747070314
    ],
    [
      33.076978792035554,
      -97.005672454834
    ],
    [
      33.07737435555441,
      -97.00635910034181
    ],
    [
      33.07780587736453,
      -97.00760364532472
    ],
    [
      33.07888467262863,
      -97.00889110565187
    ],
    [
      33.08071859421348,
      -97.00996398925783
    ],
    [
      33.08112313059196,
      -97.01004445552827
    ],
    [
      33.08144675835512,
      -97.01021611690523
    ],
    [
      33.081671499156705,
      -97.01022148132326
    ],
    [
      33.08197714572529,
      -97.01031804084779
    ],
    [
      33.08230077034606,
      -97.0107901096344
    ],
    [
      33.08260641472784,
      -97.01120853424072
    ],
    [
      33.08276822602936,
      -97.01143383979799
    ],
    [
      33.082957005504845,
      -97.01159477233888
    ],
    [
      33.082822163063696,
      -97.01180934906007
    ],
    [
      33.082597425202344,
      -97.0118522644043
    ],
    [
      33.08235470766708,
      -97.01176643371583
    ],
    [
      33.081995124902114,
      -97.01182007789613
    ],
    [
      33.08178836414659,
      -97.01158404350282
    ],
    [
      33.081509685837446,
      -97.01134800910951
    ],
    [
      33.08118605830584,
      -97.01115489006042
    ],
    [
      33.08088040898816,
      -97.01090812683107
    ],
    [
      33.080610717531655,
      -97.01053261756898
    ],
    [
      33.08013426060427,
      -97.0102107524872
    ],
    [
      33.07981062801186,
      -97.01016783714294
    ],
    [
      33.07946002469302,
      -97.01035022735597
    ],
    [
      33.07946002469302,
      -97.01065063476564
    ],
    [
      33.07972072985821,
      -97.01091885566713
    ],
    [
      33.079828607631576,
      -97.01120853424072
    ],
    [
      33.08035001500434,
      -97.01165914535524
    ],
    [
      33.080439912514834,
      -97.01183080673218
    ],
    [
      33.08032304573328,
      -97.01210975646974
    ],
    [
      33.080628696987816,
      -97.01295733451843
    ],
    [
      33.08068263533425,
      -97.01346158981325
    ],
    [
      33.080583748340544,
      -97.01420187950136
    ],
    [
      33.08071859421348,
      -97.01449155807497
    ],
    [
      33.08070960449506,
      -97.01468467712402
    ],
    [
      33.08064667644031,
      -97.01499581336975
    ],
    [
      33.08113212026812,
      -97.01539278030396
    ],
    [
      33.081581602904976,
      -97.01507091522218
    ],
    [
      33.08186927058708,
      -97.01523184776308
    ],
    [
      33.08204007282814,
      -97.01537132263184
    ],
    [
      33.08211198946199,
      -97.01585412025453
    ],
    [
      33.08257944614864,
      -97.0167338848114
    ],
    [
      33.08273226798806,
      -97.01707720756531
    ],
    [
      33.08266035186142,
      -97.01745271682739
    ],
    [
      33.08265136234144,
      -97.01780676841736
    ],
    [
      33.082525508965425,
      -97.01805353164674
    ],
    [
      33.08219289560478,
      -97.01841831207277
    ],
    [
      33.081914218577424,
      -97.0187294483185
    ],
    [
      33.081747910898436,
      -97.01883137226106
    ],
    [
      33.081617561416714,
      -97.01877772808076
    ],
    [
      33.08148271692197,
      -97.01874554157259
    ],
    [
      33.08147372728164,
      -97.01883137226106
    ],
    [
      33.08137933600266,
      -97.0188742876053
    ],
    [
      33.08132539808349,
      -97.01895475387575
    ],
    [
      33.08125797563798,
      -97.01906740665436
    ],
    [
      33.08116807896362,
      -97.01904594898224
    ],
    [
      33.08097929564834,
      -97.01879382133485
    ],
    [
      33.0808489450275,
      -97.01886892318726
    ],
    [
      33.08090288323887,
      -97.01901376247406
    ],
    [
      33.08088040898816,
      -97.01911032199861
    ],
    [
      33.08081298620154,
      -97.01912105083467
    ],
    [
      33.08084445017506,
      -97.01924443244934
    ],
    [
      33.08098379049389,
      -97.01929807662965
    ],
    [
      33.08106469767448,
      -97.01935708522797
    ],
    [
      33.08119055314083,
      -97.01987743377686
    ],
    [
      33.081096161557966,
      -97.01995790004732
    ],
    [
      33.080893893539276,
      -97.020081281662
    ],
    [
      33.08079500678307,
      -97.02020466327669
    ],
    [
      33.08071859421348,
      -97.02029049396516
    ],
    [
      33.08066465588911,
      -97.0204085111618
    ],
    [
      33.080628696987816,
      -97.02053725719453
    ],
    [
      33.080300571334405,
      -97.02045679092409
    ],
    [
      33.08027809692978,
      -97.02056944370271
    ],
    [
      33.08016572482057,
      -97.02070355415346
    ],
    [
      33.08001289852166,
      -97.02073574066164
    ],
    [
      33.079882546468625,
      -97.02073037624359
    ],
    [
      33.079770173854094,
      -97.02082157135011
    ],
    [
      33.07986007195721,
      -97.02091276645662
    ],
    [
      33.079923000574745,
      -97.02081620693207
    ],
    [
      33.080030878100025,
      -97.02085375785829
    ],
    [
      33.080233148103325,
      -97.0208376646042
    ],
    [
      33.08046238687812,
      -97.02092349529268
    ],
    [
      33.080538799670265,
      -97.02103078365327
    ],
    [
      33.080480366364604,
      -97.02111661434174
    ],
    [
      33.08037248939058,
      -97.02120244503023
    ],
    [
      33.080305066214635,
      -97.02135264873506
    ],
    [
      33.080237642987015,
      -97.02145993709564
    ],
    [
      33.080233148103325,
      -97.02154576778413
    ],
    [
      33.080300571334405,
      -97.0215940475464
    ],
    [
      33.08035900475953,
      -97.02168524265291
    ],
    [
      33.08039046889543,
      -97.02190518379213
    ],
    [
      33.08048486123565,
      -97.02210903167726
    ],
    [
      33.080610717531655,
      -97.02215731143951
    ],
    [
      33.080705109635495,
      -97.02221632003786
    ],
    [
      33.08087591413732,
      -97.02190518379213
    ],
    [
      33.08094333687569,
      -97.02113807201385
    ],
    [
      33.081073687356614,
      -97.02095031738281
    ],
    [
      33.08186477578677,
      -97.02025830745698
    ],
    [
      33.08221087473751,
      -97.01955556869507
    ],
    [
      33.08248056128751,
      -97.01926589012147
    ],
    [
      33.08288958431011,
      -97.01892256736757
    ],
    [
      33.08311881616132,
      -97.01884746551514
    ],
    [
      33.08340198409381,
      -97.01863288879396
    ],
    [
      33.08374358229111,
      -97.0185309648514
    ],
    [
      33.08397730660896,
      -97.0184773206711
    ],
    [
      33.084175072854315,
      -97.01848268508911
    ],
    [
      33.08431440789651,
      -97.01845049858095
    ],
    [
      33.08437283865493,
      -97.01853632926941
    ],
    [
      33.08433688126972,
      -97.01879382133485
    ],
    [
      33.084431269374555,
      -97.01915323734285
    ],
    [
      33.084660497207395,
      -97.01965749263765
    ],
    [
      33.08463352925806,
      -97.0199364423752
    ],
    [
      33.08458408799616,
      -97.02020466327669
    ],
    [
      33.084853767268875,
      -97.02056944370271
    ],
    [
      33.08583808959624,
      -97.02123999595644
    ],
    [
      33.084548130697335,
      -97.0215940475464
    ],
    [
      33.08475488496491,
      -97.02218949794769
    ],
    [
      33.08555043489568,
      -97.02214658260345
    ],
    [
      33.085662800125064,
      -97.02220022678375
    ],
    [
      33.08552796183256,
      -97.02228605747224
    ],
    [
      33.08510996181195,
      -97.0226937532425
    ],
    [
      33.08461555062061,
      -97.02296733856203
    ],
    [
      33.08411214727902,
      -97.02311754226686
    ],
    [
      33.0838964021077,
      -97.02333748340608
    ],
    [
      33.083927864978136,
      -97.02342331409456
    ],
    [
      33.08351884678431,
      -97.02351450920106
    ],
    [
      33.08295251076013,
      -97.02377200126648
    ],
    [
      33.08284014206778,
      -97.02390074729921
    ],
    [
      33.08287160531613,
      -97.02412068843843
    ],
    [
      33.08296599499355,
      -97.02417433261873
    ],
    [
      33.08345592073949,
      -97.02406167984009
    ],
    [
      33.08395033845015,
      -97.0238846540451
    ],
    [
      33.08408517916156,
      -97.02379345893861
    ],
    [
      33.08416158880626,
      -97.02370762825012
    ],
    [
      33.08433238659554,
      -97.0236647129059
    ],
    [
      33.08448071072235,
      -97.02372372150423
    ],
    [
      33.08462453993978,
      -97.0237398147583
    ],
    [
      33.08530323088563,
      -97.0237022638321
    ],
    [
      33.08548301568912,
      -97.02352523803712
    ],
    [
      33.085568413342024,
      -97.02351450920106
    ],
    [
      33.085775165210876,
      -97.02326238155366
    ],
    [
      33.086296537330405,
      -97.02303707599641
    ],
    [
      33.08649879292393,
      -97.02301025390625
    ],
    [
      33.08678194997331,
      -97.02303171157837
    ],
    [
      33.086912291800736,
      -97.02293515205385
    ],
    [
      33.08704263343493,
      -97.0230156183243
    ],
    [
      33.08706510611094,
      -97.02311754226686
    ],
    [
      33.08724039278685,
      -97.02344477176668
    ],
    [
      33.08726286541233,
      -97.02357888221742
    ],
    [
      33.087244887312416,
      -97.02385246753694
    ],
    [
      33.087375228453624,
      -97.02394902706146
    ],
    [
      33.087608943119584,
      -97.02401340007783
    ],
    [
      33.08769433870795,
      -97.0241689682007
    ],
    [
      33.087644899166904,
      -97.02427089214325
    ],
    [
      33.087680855199515,
      -97.02468395233156
    ],
    [
      33.087802206701056,
      -97.02475905418396
    ],
    [
      33.08832356680229,
      -97.0247107744217
    ],
    [
      33.08843143402351,
      -97.02478587627412
    ],
    [
      33.08840896169665,
      -97.02490389347078
    ],
    [
      33.088202216020264,
      -97.02500581741334
    ],
    [
      33.08801344780422,
      -97.02524185180665
    ],
    [
      33.08790108557847,
      -97.02522575855257
    ],
    [
      33.087667371689,
      -97.02503800392152
    ],
    [
      33.08746961329732,
      -97.02515065670015
    ],
    [
      33.0875145584254,
      -97.02569782733917
    ],
    [
      33.08752804195935,
      -97.02618598937988
    ],
    [
      33.08758647058253,
      -97.02671706676483
    ],
    [
      33.08963594233811,
      -97.0272159576416
    ],
    [
      33.08991010050744,
      -97.02688336372375
    ],
    [
      33.089842684643415,
      -97.02673316001894
    ],
    [
      33.0898606622122,
      -97.02655613422395
    ],
    [
      33.08970335836065,
      -97.02626645565034
    ],
    [
      33.08959099829437,
      -97.02606797218324
    ],
    [
      33.08977077433149,
      -97.02613234519958
    ],
    [
      33.09064268289702,
      -97.02675998210908
    ],
    [
      33.09090784925131,
      -97.02688336372375
    ],
    [
      33.09106515094795,
      -97.0269799232483
    ],
    [
      33.0914022250645,
      -97.02710866928102
    ],
    [
      33.091631434725585,
      -97.02731788158418
    ],
    [
      33.09172132070407,
      -97.02729105949403
    ],
    [
      33.09176626365886,
      -97.02711939811708
    ],
    [
      33.091698849218076,
      -97.02697455883028
    ],
    [
      33.091707837813175,
      -97.02686190605164
    ],
    [
      33.09126739557294,
      -97.02613770961763
    ],
    [
      33.09077751334659,
      -97.02579438686372
    ],
    [
      33.09066515465298,
      -97.02544569969177
    ],
    [
      33.09013481968087,
      -97.024764418602
    ],
    [
      33.08996403316144,
      -97.02468395233156
    ],
    [
      33.089874145386396,
      -97.02449619770051
    ],
    [
      33.09007639275102,
      -97.02360033988954
    ],
    [
      33.090017965782366,
      -97.02318727970123
    ],
    [
      33.089986505090835,
      -97.02301025390625
    ],
    [
      33.08985616782036,
      -97.02293515205385
    ],
    [
      33.089730324755195,
      -97.02257573604585
    ],
    [
      33.0893977386445,
      -97.02241480350496
    ],
    [
      33.08933931122484,
      -97.02223777770998
    ],
    [
      33.08911908444806,
      -97.02206075191499
    ],
    [
      33.08909211786604,
      -97.0219534635544
    ],
    [
      33.08921796184465,
      -97.02177643775941
    ],
    [
      33.089487626906575,
      -97.02146530151369
    ],
    [
      33.08970785276031,
      -97.02142775058746
    ],
    [
      33.08984717903598,
      -97.02136337757112
    ],
    [
      33.08995055000104,
      -97.02124536037446
    ],
    [
      33.09008987589214,
      -97.02123999595644
    ],
    [
      33.09053032403111,
      -97.02139019966127
    ],
    [
      33.09051234659926,
      -97.02165305614473
    ],
    [
      33.090422459384754,
      -97.02231287956238
    ],
    [
      33.090498863522924,
      -97.02313899993898
    ],
    [
      33.09106065661765,
      -97.02392756938934
    ],
    [
      33.09220220912804,
      -97.02450156211854
    ],
    [
      33.09212580647008,
      -97.02464640140533
    ],
    [
      33.09199547237092,
      -97.02475905418396
    ],
    [
      33.0919505295333,
      -97.0250326395035
    ],
    [
      33.09194603524827,
      -97.02520966529848
    ],
    [
      33.092103335087465,
      -97.02542424201967
    ],
    [
      33.09304263398347,
      -97.0258802175522
    ],
    [
      33.093442619461925,
      -97.0258641242981
    ],
    [
      33.094692000584544,
      -97.02670633792877
    ],
    [
      33.095051531430514,
      -97.0268350839615
    ],
    [
      33.09545150776879,
      -97.02674925327302
    ],
    [
      33.09582451777013,
      -97.026743888855
    ],
    [
      33.09635032436098,
      -97.0264917612076
    ],
    [
      33.09660199135988,
      -97.02630937099457
    ],
    [
      33.09718172152649,
      -97.02629864215852
    ],
    [
      33.09730755392585,
      -97.02632546424867
    ],
    [
      33.09727160183013,
      -97.02659904956819
    ],
    [
      33.09714576937932,
      -97.02670633792877
    ],
    [
      33.097109817217415,
      -97.02690482139587
    ],
    [
      33.09728058985545,
      -97.02726423740388
    ],
    [
      33.09768505004316,
      -97.0274144411087
    ],
    [
      33.09800861685338,
      -97.02740371227264
    ],
    [
      33.098435543460894,
      -97.02760756015779
    ],
    [
      33.09877708325389,
      -97.02752709388734
    ],
    [
      33.09893886479791,
      -97.02754318714143
    ],
    [
      33.09912311563812,
      -97.0274144411087
    ],
    [
      33.09993201719577,
      -97.02735006809236
    ],
    [
      33.100147723020505,
      -97.0274841785431
    ],
    [
      33.10024209415238,
      -97.02784895896913
    ],
    [
      33.10014322915457,
      -97.0280957221985
    ],
    [
      33.09887145585742,
      -97.02829957008362
    ],
    [
      33.09800412287806,
      -97.02931344509126
    ],
    [
      33.097586182169145,
      -97.02936708927156
    ],
    [
      33.097523266191416,
      -97.02952802181244
    ],
    [
      33.09720419161103,
      -97.02939391136171
    ],
    [
      33.09606270405138,
      -97.02951192855836
    ],
    [
      33.09503804905031,
      -97.02945828437807
    ],
    [
      33.092701071909694,
      -97.02938318252565
    ],
    [
      33.09048987480424,
      -97.02899694442749
    ],
    [
      33.090013471398535,
      -97.02881455421449
    ],
    [
      33.087029149826556,
      -97.02826738357544
    ],
    [
      33.08604034624416,
      -97.02938318252565
    ],
    [
      33.08595045445805,
      -97.03060626983644
    ],
    [
      33.08503355299124,
      -97.03103542327881
    ],
    [
      33.0845301520424,
      -97.03135728836061
    ],
    [
      33.084134620703935,
      -97.03176498413086
    ],
    [
      33.083217700304836,
      -97.0322370529175
    ],
    [
      33.08388291801692,
      -97.03300952911378
    ],
    [
      33.0836132357671,
      -97.03444719314577
    ],
    [
      33.083163763513056,
      -97.03524112701416
    ],
    [
      33.08249854036144,
      -97.03599214553833
    ],
    [
      33.081096161557966,
      -97.03740835189821
    ],
    [
      33.08111414091489,
      -97.03794479370119
    ],
    [
      33.08159958216269,
      -97.03880310058595
    ],
    [
      33.0818512913845,
      -97.0391035079956
    ],
    [
      33.08186927058708,
      -97.03985452651979
    ],
    [
      33.082067041572735,
      -97.04032659530641
    ],
    [
      33.081887249785964,
      -97.0407557487488
    ],
    [
      33.08194118736059,
      -97.04120635986328
    ],
    [
      33.08163554066705,
      -97.04152822494507
    ],
    [
      33.081311913598526,
      -97.04191446304321
    ],
    [
      33.08118605830584,
      -97.04453229904176
    ],
    [
      33.07149465999192,
      -97.044575214386
    ],
    [
      33.07147667866888,
      -97.045476436615
    ],
    [
      33.070991181557595,
      -97.04453229904176
    ]
  ];