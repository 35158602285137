import React, { useRef } from 'react'
import img from '../../../../../src/img/images1.jpg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, colourStyles, customStylesWithOutColor, getShowingDateText, getShowingWithOutTime } from '../../../Common/Utility';
import Select from "react-select";
import { useEffect } from 'react';
import { fetchData, fetchPostData } from '../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../redux/actions/Agency';
import { toastifyError } from '../../../Common/AlertMsg';
import { Comman_changeArrayFormat } from '../../../Common/ChangeArrayFormat';
import { get_Incident_Drp_Data } from '../../../../redux/actions/DropDownsData';
import Loader from '../../../Common/Loader';


const ArrestSummary = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const fbiCodesDrpData = useSelector((state) => state.DropDown.fbiCodesDrpData);

    const [reportData, setReportData] = useState([]);
    const [verifyArrestMaster, setverifyArrestMaster] = useState(false);
    const [Arrestfromdate, setArrestfromdate] = useState('')
    const [ArrestTodate, setArrestTodate] = useState('')
    const [arestReportData, setArestReportData] = useState([]);
    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [zipList, setZipList] = useState([]);
    const [multiImage, setMultiImage] = useState([]);
    const [headOfAgency, setHeadOfAgency] = useState([]);
    const [rmsCfsID, setRmsCfsID] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [loder, setLoder] = useState(false);

    const [value, setValue] = useState({
        AgencyID: '', ArrestNumber: '', ArrestNumberTo: '', ArrestDtTmTo: '', ArrestDtTm: '', NameIDNumber: '', PrimaryOfficerID: '',
        LastName: '', FirstName: '', MiddleName: '', SexID: '', RMSCFSCodeList: '', FBIID: '', AgeFrom: '', AgeTo: '',
    });
    const [searchValue, setSearchValue] = useState({
        ArrestNumber: '',
        ArrestNumberTo: '',
        ArrestDtTmTo: '',
        ArrestDtTm: '',
        NameIDNumber: '',
        PrimaryOfficerID: null,
        LastName: '',
        FirstName: '',
        MiddleName: '',
        SexID: null,
        RMSCFSCodeList: null,
        FBIID: null,
        AgeFrom: '',
        AgeTo: '',
    });

    const [showFields, setShowFields] = useState({
        showArrestNumber: false,
        showArrestNumberTo: false,
        showArrestDtTm: false,
        showArrestDtTmTo: false,
        showNameIDNumber: false,
        showPrimaryOfficerID: false,
        showLastName: false,
        showFirstName: false,
        showMiddleName: false,
        showSexID: false,
        showRMSCFSCodeList: false,
        showFBIID: false,
        showAgeFrom: false,
        showAgeTo: false,
    });


    useEffect(() => {
        setShowFields({
            showArrestNumber: searchValue.ArrestNumber,
            showArrestNumberTo: searchValue.ArrestNumberTo,
            showArrestDtTm: searchValue.ArrestDtTm,
            showArrestDtTmTo: searchValue.ArrestDtTmTo,
            showNameIDNumber: searchValue.NameIDNumber,
            showPrimaryOfficerID: searchValue.PrimaryOfficerID !== null,
            showLastName: searchValue.LastName,
            showFirstName: searchValue.FirstName,
            showMiddleName: searchValue.MiddleName,
            showSexID: searchValue.SexID !== null,
            showRMSCFSCodeList: searchValue.RMSCFSCodeList !== null,
            showFBIID: searchValue.FBIID !== null,
            showAgeFrom: searchValue.AgeFrom,
            showAgeTo: searchValue.AgeTo,
        });
    }, [searchValue]);
    const get_Head_Of_Agency = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setHeadOfAgency(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            } else {
                setHeadOfAgency([])
            }
        })
    };
    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);
    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);
    useEffect(() => {
        if (LoginAgencyID) {
            // setLoginAgencyID(localStoreData?.AgencyID);
            // setLoginPinID(localStoreData?.PINID);
            get_Head_Of_Agency(LoginAgencyID);
            GetSexIDDrp(LoginAgencyID);
            getAgencyImg(LoginAgencyID);
            if (fbiCodesDrpData?.length === 0) { dispatch(get_Incident_Drp_Data(LoginAgencyID)) }

        }
    }, [LoginAgencyID])


    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         if (e.target.name === 'ArrestNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele[0]?.match(/[a-zA-Z\s]/g)) {
    //                 let subs = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '').substring(0, 4);
    //                 let subs2 = ele.replace(/[^0-9]/g, '');
    //                 if (ele?.length <= 4) {
    //                     const alphabet = ele.toUpperCase().replace(/[^a-zA-Z\s]/g, '') || '';
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: alphabet
    //                     })
    //                 } else if (ele.length >= 15) {
    //                     e?.preventDefault()
    //                 } else if (subs2?.length >= 11) {
    //                     // console.log(subs2, 'asdasdas')
    //                 } else {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: subs + '-' + subs2
    //                     })
    //                 }
    //             } else {
    //                 let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //                 if (ele[0]?.match(/[0-9]/g)) {
    //                     const digits = ele.replace(/[^0-9]/g, '');
    //                     if (ele?.length <= 2) {
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: digits
    //                         })
    //                     } else if (ele.length >= 9) {
    //                         e.preventDefault();
    //                     } else {
    //                         let subs = ele.substring(0, 2);
    //                         let subs2 = ele.substring(2).replace(/[^0-9]/g, '');
    //                         setValue({
    //                             ...value,
    //                             [e.target.name]: subs + '-' + subs2
    //                         })
    //                     }
    //                 } else {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: e.target.value
    //                     })
    //                 }

    //             }
    //         }
    //     }
    // }
    // for new change
    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         const { name, value } = e.target;
    //         if (name === 'ArrestNumber' || name === 'ArrestNumberTo') {
    //             let sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
    //             const alphaPart = sanitizedValue.substring(0, 4).toUpperCase();
    //             const numericPart = sanitizedValue.substring(4);
    //             let formattedValue = alphaPart;
    //             if (numericPart.length > 0) {
    //                 if (numericPart.length <= 2) {
    //                     formattedValue += '-' + numericPart;
    //                 } else if (numericPart.length <= 8) {
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const sixDigitPart = numericPart.substring(2, 8);
    //                     formattedValue += '-' + twoDigitPart;
    //                     if (sixDigitPart.length > 0) {
    //                         formattedValue += '-' + sixDigitPart;
    //                     }
    //                 } else {
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const eightDigitPart = numericPart.substring(2, 10);
    //                     formattedValue += '-' + twoDigitPart + eightDigitPart;
    //                 }
    //             }

    //             setValue({
    //                 ...value,
    //                 [name]: formattedValue
    //             });
    //         }
    //     }
    // }

    // const handleChange = (e) => {
    //     if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
    //         var ele = e.target.value.replace(/[^a-zA-Z\s^0-9\s]/g, '');
    //         if (ele.length === 8) {
    //             var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
    //             var match = cleaned.match(/^(\d{2})(\d{6})$/);
    //             if (match) {
    //                 // console.log(match)
    //                 setValue({
    //                     ...value,
    //                     [e.target.name]: match[1] + '-' + match[2]
    //                 })
    //             }
    //         } else {
    //             ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: ele
    //             })
    //             if (ele?.length == 0) { e.target.name == 'AgeFrom' && setValue({ ...value, ['AgeTo']: "", [e.target.name]: ele }) }

    //         }
    //     } else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }
    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     if (name === 'ArrestNumber' || name === 'ArrestNumberTo') {
    //         // Sanitize input: Remove any characters that are not alphanumeric
    //         let sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');

    //         if (sanitizedValue.length === 8) {
    //             // For exactly 8 characters, format as `ADUL-24-000058`
    //             let cleaned = sanitizedValue;
    //             let match = cleaned.match(/^(\d{2})(\d{6})$/);
    //             if (match) {
    //                 setValue({
    //                     ...value,
    //                     [name]: match[1] + '-' + match[2]
    //                 });
    //             }
    //         } else {
    //             // Handle other cases with different formats
    //             let formattedValue = sanitizedValue.substring(0, 4).toUpperCase();
    //             let numericPart = sanitizedValue.substring(4);

    //             if (numericPart.length > 0) {
    //                 if (numericPart.length <= 2) {
    //                     // For up to 2 numeric characters, format as `ADUL-24`
    //                     formattedValue += '-' + numericPart;
    //                 } else if (numericPart.length <= 8) {
    //                     // For up to 8 numeric characters, format as `ADUL-24-000058`
    //                     let twoDigitPart = numericPart.substring(0, 2);
    //                     let sixDigitPart = numericPart.substring(2, 8);
    //                     formattedValue += '-' + twoDigitPart;
    //                     if (sixDigitPart.length > 0) {
    //                         formattedValue += '-' + sixDigitPart;
    //                     }
    //                 } else {
    //                     // For more than 8 numeric characters, handle as `AJUV-2400000074`
    //                     let twoDigitPart = numericPart.substring(0, 2);
    //                     let eightDigitPart = numericPart.substring(2, 10);
    //                     formattedValue += '-' + twoDigitPart + eightDigitPart;
    //                 }
    //             }

    //             setValue({
    //                 ...value,
    //                 [name]: formattedValue
    //             });
    //             // if (ele?.length == 0) { e.target.name == 'ArrestNumber' && setValue({ ...value, ['ArrestNumberTo']: "", [e.target.name]: ele }) }
    //         }
    //     } else {
    //         // Handle other inputs
    //         setValue({
    //             ...value,
    //             [name]: value
    //         });
    //     }
    // };

    const Handlechange = (e) => {
        if (e.target.name === 'SSN') {
            let ele = e.target.value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    })
                }
            } else {
                ele = e.target.value.split('-').join('').replace(/\D/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
            if (e.target.name === 'SSN') {
                return 'true';
            }
            if (e.target.name.length === 11) {
                return 'true'
            }
        }
        else if (e.target.name === 'IncidentNumber') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
            if (ele.length === 8) {
                const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                const match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
            }
        }
        // else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
        //     let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        //     if (ele.length === 8) {
        //         const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
        //         const match = cleaned.match(/^(\d{2})(\d{6})$/);
        //         if (match) {
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: match[1] + '-' + match[2]
        //             })
        //         }
        //     } else {
        //         ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
        //         setValue({
        //             ...value,
        //             [e.target.name]: ele
        //         })
        //         if (ele?.length == 0) { e.target.name == 'ArrestNumber' && setValue({ ...value, ['ArrestNumberTo']: "", [e.target.name]: ele }) }
        //     }
        // }
        else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
            let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

            if (ele.length >= 14) {
                const cleaned = ele.slice(0, 14);
                const match = cleaned.match(/^(\w{4})(\w{2})(\w{8})$/);
                if (match) {
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2] + '-' + match[3]
                    });
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                });

                if (ele.length === 0) {
                    e.target.name === 'ArrestNumber' && setValue({
                        ...value,
                        ['ArrestNumberTo']: "",
                        [e.target.name]: ele
                    });
                }
            }
        }
        // else if (e.target.name === 'ArrestNumber' || e.target.name === 'ArrestNumberTo') {
        //     let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
        //     if (ele.length === 8) {
        //         const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
        //         const match = cleaned.match(/^(\d{2})(\d{6})$/);
        //         if (match) {
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: match[1] + '-' + match[2]
        //             })
        //         }
        //     } else {
        //         ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
        //         setValue({
        //             ...value,
        //             [e.target.name]: ele
        //         })
        //     if (ele?.length == 0) { e.target.name == 'ArrestNumber' && setValue({ ...value, ['ArrestNumberTo']: "", [e.target.name]: ele }) }
        //     }
        // } 
        else if (e.target.name === 'AgeFrom' || e.target.name === 'AgeTo') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({ ...value, ['Years']: 0, ['DateOfBirth']: 0 })
            setValue({ ...value, [e.target.name]: checkNumber })
            if (checkNumber?.length == 0) { e.target.name == 'AgeFrom' && setValue({ ...value, ['AgeTo']: "", [e.target.name]: checkNumber }) }

        }
        //  else if (e.target.name === 'NameIDNumber') {
        //     let ele = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
        //     if (ele.length <= 11) {
        //         const alphabet = ele[0]?.toUpperCase() || '';
        //         const digits = ele.slice(1).replace(/[^0-9]/g, '');
        //         // console.log(match)
        //         if (digits.length === 9) {
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: alphabet + '-' + digits
        //             });
        //         } else {
        //             setValue({
        //                 ...value,
        //                 [e.target.name]: alphabet + digits
        //             });
        //         }
        //     } else {
        //         ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
        //         setValue({
        //             ...value,
        //             [e.target.name]: ele
        //         })
        //     }
        // } 
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }
    const onChangeNameIDNum = (e) => {
        if (e) {
            if (e.target.name === 'NameIDNumber') {
                let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                if (ele.length === 10) {
                    const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
                    // const match = cleaned.match(/^(\w{1})(\d{9})$/);
                    const match = cleaned.match(/^([AJ]{1})(\d{9})$/);
                    // console.log(match)
                    if (match) {
                        setValue({
                            ...value,
                            [e.target.name]: match[1] + '-' + match[2]
                        })
                    }
                } else {
                    ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
                    setValue({
                        ...value,
                        [e.target.name]: ele
                    })
                }
            } else {
                setValue({
                    ...value,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }
    const GetSexIDDrp = (LoginAgencyID) => {
        const val = { AgencyID: LoginAgencyID }
        fetchPostData('DropDown/GetData_SexType', val).then((data) => {
            if (data) {
                setSexIdDrp(Comman_changeArrayFormat(data, 'SexCodeID', 'Description'))
            } else {
                setSexIdDrp([]);
            }
        })
    }
    const get_ArrestSearchData = async () => {
        setLoder(true);
    
        // Check if any fields in value are filled
        const hasValidInput = 
            value?.ArrestNumber?.trim() || 
            value?.ArrestNumberTo?.trim() || 
            value?.ArrestDtTm?.trim() || 
            value?.ArrestDtTmTo?.trim() || 
            value?.FirstName?.trim() || 
            value?.MiddleName?.trim() || 
            value?.LastName?.trim() || 
            value?.NameIDNumber?.trim() || 
            value?.SexID !== null || 
            value?.FBIID !== null || 
            value?.RMSCFSCodeList !== null || 
            value?.AgeFrom !== null || 
            value?.AgeTo !== null || 
            value?.PrimaryOfficerID !== null;
    
        if (hasValidInput) {
            const { 
                ArrestNumber, 
                ArrestNumberTo, 
                ArrestDtTm, 
                ArrestDtTmTo, 
                NameIDNumber, 
                LastName, 
                FirstName, 
                MiddleName, 
                SexID, 
                RMSCFSCodeList, 
                FBIID, 
                AgeFrom, 
                AgeTo, 
                PrimaryOfficerID 
            } = value;
    
            const val = {
                AgencyID: LoginAgencyID,
                ArrestNumber,
                ArrestNumberTo,
                ArrestDtTm,
                ArrestDtTmTo,
                NameIDNumber,
                LastName,
                FirstName,
                MiddleName,
                SexID,
                RMSCFSCodeList,
                FBIID,
                AgeFrom,
                AgeTo,
                PrimaryOfficerID,
            };
    
            try {
                const res = await fetchPostData('ArrestReport/ArrestSummaryReport', val);
                if (res.length > 0) {
                    setArestReportData(res[0]);
                    setReportData(res[0]);
                    setverifyArrestMaster(true);
                    getAgencyImg(LoginAgencyID);
                    setSearchValue(value);
                } else {
                    toastifyError("Data Not Available");
                    setReportData([]);
                    setverifyArrestMaster(false);
                }
            } catch (error) {
                toastifyError("Data Not Available");
                setverifyArrestMaster(false);
                setReportData([]);
            }
        } else {
            toastifyError("Please Enter Details");
        }
    
        setLoder(false);
    };
    
    // const get_ArrestSearchData = async () => {
    //     setLoder(true);
    //     if ( value?.ArrestNumber?.trim()?.length > 0 || value?.ArrestNumberTo?.trim()?.length > 0 ||value?.ArrestDtTmTo?.trim()?.length > 0 || value?.ArrestDtTm?.trim()?.length > 0 || value?.FirstName?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.NameIDNumber?.trim()?.length > 0 || value?.SexID !== null || value?.FBIID !== null || value?.RMSCFSCodeList !== null || value?.AgeFrom !== null || value?.AgeTo !== null || value?.PrimaryOfficerID !== null) {
    //         const { ArrestNumber, ArrestNumberTo, ArrestDtTmTo, ArrestDtTm, NameIDNumber, LastName, FirstName, MiddleName, SexID, RMSCFSCodeList, FBIID, AgeFrom, AgeTo, PrimaryOfficerID
    //         } = value
    //         const val = {
    //             'AgencyID': LoginAgencyID, 'ArrestNumber': ArrestNumber, 'ArrestNumberTo': ArrestNumberTo, 'ArrestDtTmTo': ArrestDtTmTo, 'ArrestDtTm': ArrestDtTm, 'NameIDNumber': NameIDNumber, 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'SexID': SexID, 'RMSCFSCodeList': RMSCFSCodeList, 'FBIID': FBIID, 'AgeFrom': AgeFrom, 'AgeTo': AgeTo, 'PrimaryOfficerID': PrimaryOfficerID,
    //         }
    //         try {
    //             const res = await fetchPostData('ArrestReport/ArrestSummaryReport', val);
    //             if (res.length > 0) {
    //                 setArestReportData(res[0])
    //                 setReportData(res[0]);
    //                 setverifyArrestMaster(true)
    //                 getAgencyImg(LoginAgencyID);
    //                 setSearchValue(value);
    //                 setLoder(false);
    //             }
    //             else {
    //                 toastifyError("Data Not Available");
    //                 setReportData(res[0]);
    //                 setverifyArrestMaster(false);
    //                 setLoder(false);

    //             }
    //         } catch (error) {
    //             toastifyError("Data Not Available");
    //             setverifyArrestMaster(false);
    //             setReportData([]);
    //             setLoder(false);
    //         }

    //     } else {
    //         toastifyError("Please Enter Details");
    //         setLoder(false);

    //     }
    // }
    // const get_ArrestSearchData = async () => {
    //     if (value?.ArrestDtTmTo?.trim()?.length > 0 || value?.ArrestDtTm?.trim()?.length > 0 || value?.ArrestNumber?.trim()?.length > 0 || value?.ArrestNumberTo?.trim()?.length > 0 || value?.FirstName?.trim()?.length > 0 || value?.MiddleName?.trim()?.length > 0 || value?.LastName?.trim()?.length > 0 || value?.NameIDNumber?.trim()?.length > 0 || value?.SexID !== null || value?.FBIID !== null || value?.RMSCFSCodeList !== null || value?.AgeFrom !== null || value?.AgeTo !== null || value?.PrimaryOfficerID !== null) {
    //         const { AgencyID, ArrestNumber, ArrestNumberTo, ArrestDtTmTo, ArrestDtTm, NameIDNumber, LastName, FirstName, MiddleName, SexID, RMSCFSCodeList, FBIID, AgeFrom, AgeTo, PrimaryOfficerID
    //         } = value
    //         const val = {
    //             'AgencyID': LoginAgencyID, 'ArrestNumber': ArrestNumber, 'ArrestNumberTo': ArrestNumberTo, 'ArrestDtTmTo': ArrestDtTmTo, 'ArrestDtTm': ArrestDtTm, 'NameIDNumber': NameIDNumber, 'LastName': LastName, 'FirstName': FirstName, 'MiddleName': MiddleName, 'SexID': SexID, 'RMSCFSCodeList': RMSCFSCodeList, 'FBIID': FBIID, 'AgeFrom': AgeFrom, 'AgeTo': AgeTo, 'PrimaryOfficerID': PrimaryOfficerID,
    //         }
    //         fetchPostData('ArrestReport/ArrestSummaryReport', val).then((res) => {
    //             if (res.length > 0) {
    //                 console.log(res)
    //                 setArestReportData(res[0])
    //                 setReportData(res[0]);
    //                 setverifyArrestMaster(true)
    //                 getAgencyImg(LoginAgencyID);
    //                 setSearchValue(value);

    //             } else {
    //                 toastifyError("Data Not Available");
    //                 setReportData([]);
    //                 setverifyArrestMaster(false);
    //             }
    //         });
    //     }
    // }

    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);
            }
            else { console.log("errror") }
        })
    }


    const resetFields = () => {
        setValue({
            ...value,
            AgencyID: '', ArrestNumber: '', ArrestNumberTo: '', ArrestDtTmTo: '', ArrestDtTm: '', NameIDNumber: '',
            LastName: '', FirstName: '', MiddleName: '', SexID: '', RMSCFSCodeList: '', FBIID: '', AgeFrom: '', AgeTo: '', PrimaryOfficerID: '',
        })
        setverifyArrestMaster(false)
        setArestReportData([]);
    }

    const componentRef = useRef();
    // const printForm = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: 'Data',
    //     onAfterPrint: () => { '' }
    // })
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onBeforeGetContent: () => {
            setLoder(true);
        },
        onAfterPrint: () => {
            setLoder(false);
        }
    });
    const getRMSCFSCodeList = (LoginAgencyID,FBIID) => {
        const val = { 'FBIID': FBIID, 'AgencyID': LoginAgencyID, }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setRmsCfsID([]);
            }
        })
    }
    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'FBIID') {
                getRMSCFSCodeList(LoginAgencyID,e.value)
                setValue({
                    ...value,
                    [name]: e.value,
                    ['RMSCFSCodeList']: "",
                })
            } else {
                setValue({
                    ...value,
                    [name]: e.value
                })
            }
        } else {
            if (name === 'FBIID') {
                setRmsCfsID([]);
                setValue({
                    ...value,
                    ['FBIID']: "",
                    ['RMSCFSCodeList']: "",
                })
            } else {
                setValue({
                    ...value,
                    [name]: null
                })
            }
        }
    }
    // new by aman
    // const onChangeArrestNumber = (e) => {
    //     if (e) {
    //         const { name, value } = e.target;
    //         if (name === 'ArrestNumber' || name === 'ArrestNumberTo') {
    //             // Remove any characters that are not alphanumeric
    //             let sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');

    //             // Extract the different parts of the input
    //             const alphaPart = sanitizedValue.substring(0, 4).toUpperCase();
    //             const numericPart = sanitizedValue.substring(4);

    //             let formattedValue = alphaPart;

    //             if (numericPart.length > 0) {
    //                 if (numericPart.length <= 2) {
    //                     // For up to 2 numeric characters, format as `ADUL-24`
    //                     formattedValue += '-' + numericPart;
    //                 } else if (numericPart.length <= 8) {
    //                     // For up to 8 numeric characters, format as `ADUL-24-000058`
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const sixDigitPart = numericPart.substring(2, 8);
    //                     formattedValue += '-' + twoDigitPart;
    //                     if (sixDigitPart.length > 0) {
    //                         formattedValue += '-' + sixDigitPart;
    //                     }
    //                 } else {
    //                     // For more than 8 numeric characters, handle the first 10 as `AJUV-2400000074`
    //                     const twoDigitPart = numericPart.substring(0, 2);
    //                     const eightDigitPart = numericPart.substring(2, 10);
    //                     formattedValue += '-' + twoDigitPart + eightDigitPart;
    //                 }
    //             }

    //             // Update the state with the formatted value
    //             setValue({
    //                 ...value,
    //                 [name]: formattedValue
    //             });
    //         }
    //     }
    // }
    // const onChangeNameIDNum = (e) => {
    //     if (e) {
    //         if (e.target.name === 'NameIDNumber') {
    //             let ele = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
    //             if (ele.length === 10) {
    //                 const cleaned = ('' + ele).replace(/[^a-zA-Z0-9\s]/g, '');
    //                 // const match = cleaned.match(/^(\w{1})(\d{9})$/);
    //                 const match = cleaned.match(/^([AJ]{1})(\d{9})$/);
    //                 // console.log(match)
    //                 if (match) {
    //                     setValue({
    //                         ...value,
    //                         [e.target.name]: match[1] + '-' + match[2]
    //                     })
    //                 }
    //             } else {
    //                 ele = e.target.value.split("'").join('').replace(/[^a-zA-Z0-9\s]/g, '');
    //                 setValue({
    //                     ...value,
    //                     [e.target.name]: ele
    //                 })
    //             }
    //         } else {
    //             setValue({
    //                 ...value,
    //                 [e.target.name]: e.target.value
    //             })
    //         }
    //     } else {
    //         setValue({
    //             ...value,
    //             [e.target.name]: e.target.value
    //         })
    //     }
    // }
    return (
        <>
            <div class="section-body view_page_design pt-3">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Arrest Summary Report</legend>
                                    <div className="row">
                                        {/* <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest Number From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-2">
                                            <input type="text" name='ArrestNumber' id='ArrestNumber' className='' value={value?.ArrestNumber} onChange={onChangeArrestNumber} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest Number To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='ArrestNumberTo'
                                                // disabled={!value.IncidentNumber}
                                                disabled={!value?.ArrestNumber}
                                                className={!value?.ArrestNumber ? 'readonlyColor' : ''}
                                                maxLength={9} id='ArrestNumberTo' value={value.ArrestNumberTo} onChange={onChangeArrestNumber} />
                                        </div> */}
                                        <div className="col-3 col-md-3 col-lg-2  mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest # From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                            <input type="text" id='ArrestNumber' name='ArrestNumber' value={value?.ArrestNumber} onChange={Handlechange}
                                                autoComplete='off'
                                            // maxLength={9}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4  mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest # To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                            <input type="text" id='ArrestNumberTo' name='ArrestNumberTo' value={value?.ArrestNumberTo}
                                                disabled={!value?.ArrestNumber}
                                                className={!value?.ArrestNumber ? 'readonlyColor' : ''}
                                                autoComplete='off'
                                                onChange={Handlechange}
                                            // maxLength={9}

                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest From Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                id='ArrestDtTm'
                                                name='ArrestDtTm'
                                                dateFormat="MM/dd/yyyy"
                                                isClearable={value?.ArrestDtTm ? true : false}
                                                // selected={ArrestDtTm}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                                selected={value?.ArrestDtTm && new Date(value?.ArrestDtTm)}
                                                // ref={startRef}
                                                // onKeyDown={onKeyDown}
                                                onChange={(date) => { setValue({ ...value, ['ArrestDtTm']: date ? getShowingDateText(date) : null, ['ArrestDtTmTo']: null }) }}
                                                timeInputLabel
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Arrest To Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                name='ArrestDtTmTo'
                                                id='ArrestDtTmTo'
                                                onChange={(date) => { setValue({ ...value, ['ArrestDtTmTo']: date ? getShowingDateText(date) : null }) }}
                                                selected={value?.ArrestDtTmTo && new Date(value?.ArrestDtTmTo)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                // ref={startRef1}
                                                // onKeyDown={onKeyDown}
                                                isClearable={value?.ArrestDtTmTo ? true : false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                                minDate={new Date(value?.ArrestDtTm)}
                                                disabled={value?.ArrestDtTm ? false : true}
                                                className={!value?.ArrestDtTm && 'readonlyColor'}
                                            />
                                        </div>

                                        {/* <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Incident Number</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='IncidentNumber' id='IncidentNumber' maxLength={9} className='' value={value?.IncidentNumber} onChange={Handlechange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Name Number</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='NameIDNumber' id='NameIDNumber' maxLength={11} className='' value={value?.NameIDNumber} onChange={Handlechange} />
                                        </div> */}
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Officer</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-10  mt-1 ">
                                            <Select
                                                styles={customStylesWithOutColor}
                                                name='PrimaryOfficerID'
                                                value={headOfAgency?.filter((obj) => obj.value === value?.PrimaryOfficerID)}
                                                isClearable
                                                options={headOfAgency}
                                                onChange={(e) => ChangeDropDown(e, 'PrimaryOfficerID')}
                                                placeholder="Select..."
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'> NIBRS Code/Name</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-3 mt-1">
                                            <Select
                                                name='FBIID'
                                                value={fbiCodesDrpData?.filter((obj) => obj.value === value?.FBIID)}
                                                isClearable
                                                options={fbiCodesDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'FBIID')}
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Offense Code</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-3  mt-1 ">
                                            <Select
                                                name='RMSCFSCodeList'
                                                styles={customStylesWithOutColor}
                                                value={rmsCfsID?.filter((obj) => obj.value === value?.RMSCFSCodeList)}
                                                isClearable
                                                options={rmsCfsID}
                                                onChange={(e) => ChangeDropDown(e, 'RMSCFSCodeList')}
                                                placeholder="Select..."
                                                isDisabled={!value?.FBIID}
                                                className={!value?.FBIID ? 'readonlyColor' : ''}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend>Name Information</legend>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>MNI</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='NameIDNumber' maxLength={11} value={value?.NameIDNumber} onChange={onChangeNameIDNum} id='NameIDNumber' className='' autoComplete='off' />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Last Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-2">
                                            <input type="text" name='LastName' id='LastName' value={value?.LastName}
                                                onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>First Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='FirstName' id='FirstName' value={value?.FirstName}
                                                onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Middle&nbsp;Name</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                            <input type="text" name='MiddleName' id='MiddleName' value={value?.MiddleName} onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Gender</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3  mt-1 ">
                                            <Select
                                                name='SexID'
                                                value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                options={sexIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                isClearable
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Age From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='AgeFrom' id='AgeFrom' value={value?.AgeFrom} onChange={Handlechange} autoComplete='off' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Age To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                            <input type="text" name='AgeTo' id='AgeTo' value={value?.AgeTo} onChange={Handlechange}
                                                disabled={!value?.AgeFrom}
                                                className={!value?.AgeFrom ? 'readonlyColor' : ''} autoComplete='off'
                                            />
                                        </div>
                                    </div>

                                </fieldset>
                                <div className="col-12 col-md-12 col-lg-12 mt-2 pt-1 text-right">
                                    <button className="btn btn-sm bg-green text-white px-2 py-1" onClick={() => { get_ArrestSearchData(); }} >Show Report</button>
                                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { setverifyArrestMaster(false); resetFields(); }}>Clear</button>
                                    <Link to={'/Reports'}>
                                        <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                verifyArrestMaster &&
                <>
                    <div className="col-12 col-md-12 col-lg-12 pt-2  px-2" >
                        <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                            <p className="p-0 m-0 d-flex align-items-center">Arrest Summary Report</p>
                            <div style={{ marginLeft: 'auto' }}>
                                <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                    <i className="fa fa-print" onClick={printForm}></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-1" ref={componentRef}>
                        <div className="row" style={{ border: '1px solid #80808085' }}>
                            <>
                                <div className="col-4 col-md-3 col-lg-2 pt-1 ml-3">
                                    <div className="main">
                                        <img src={multiImage} className="" alt='Agency_Photo' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                    </div>
                                </div>
                                {/* <div className="col-7  col-md-7 col-lg-9 mt-2 pt-1 ml-5">
                                    <div className="main">
                                        <h5 className='text-dark text-bold'>{arestReportData?.Agency_Name} Test Test Test</h5>
                                        <p className='text-p'>Address: <span className=''>{arestReportData?.Agency_Address1}Test</span></p>
                                        <div className='d-flex '>
                                            <p className='text-p'>State: <span className='new-span '>{arestReportData?.StateName}</span>
                                            </p>
                                            <p className='text-p ml-5 pl-1'>City: <span className='new-span  '>{arestReportData?.CityName}</span>
                                            </p>
                                            <p className='text-p ml-2'>Zip: <span className='new-span  '>{arestReportData?.Agency_ZipId}</span>
                                            </p>
                                        </div>
                                        <div className='d-flex'>
                                            <p className='text-p'>Phone: <span className='new-span  '>{arestReportData?.Agency_Phone}</span></p>
                                            <p className='text-p ml-3 '>Fax: <span className='new-span  '> {arestReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-7 col-md-7 col-lg-9 mt-2">
                                    <div className="main">
                                        <h5 className='text-dark font-weight-bold'>{arestReportData?.Agency_Name}</h5>
                                        <p className='text-p'>Address: <span className='text-address'>{arestReportData?.Agency_Address1}</span></p>
                                        <div className='d-flex justify-content-start flex-wrap'>
                                            <p className='text-p'>City: <span className='text-gray ml-2'>{arestReportData?.CityName}</span></p>
                                            <p className='text-p mb-1 ml-3'>State: <span className='text-gray'>{arestReportData?.StateName}</span></p>
                                            <p className='text-p mb-1 ml-3'>Zip: <span className='text-gray'>{arestReportData?.Zipcode}</span></p>
                                        </div>
                                        <div className='d-flex justify-content-start flex-wrap'>
                                            <p className='text-p mb-1'>Phone: <span className='text-gray ml-1'>{arestReportData?.Agency_Phone}</span></p>
                                            <p className='text-p mb-1 ml-4'>Fax: <span className='text-gray'>{arestReportData?.Agency_Fax}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </>
                            <div className="col-12">
                                <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                <h5 className=" text-white text-bold bg-green py-1 px-3 text-center">Arrest Summary Report</h5>
                            </div>
                            <div className="col-12">
                                <fieldset>
                                    <legend>Search Criteria</legend>
                                    <div className="row">
                                        {showFields.showArrestNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Number From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showArrestNumberTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Number To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestNumberTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showArrestDtTm && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Date From</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestDtTm || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showArrestDtTmTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Arrest Date To</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.ArrestDtTmTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showNameIDNumber && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>MNI</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.NameIDNumber || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showPrimaryOfficerID && searchValue.PrimaryOfficerID && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Officer</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={headOfAgency.find((obj) => obj.value === searchValue.PrimaryOfficerID)?.label || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {showFields.showLastName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Last Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.LastName || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showFirstName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>First Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.FirstName || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showMiddleName && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Middle Name</label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.MiddleName || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                        {
                                            showFields.showSexID && searchValue.SexID && (

                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Gender</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={sexIdDrp.find((obj) => obj.value === searchValue.SexID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            showFields.showFBIID && searchValue.FBIID && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>NIBRS</label>
                                                    </div>

                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={fbiCodesDrpData.find((obj) => obj.value === searchValue.FBIID)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                        {
                                            showFields.showRMSCFSCodeList && searchValue.RMSCFSCodeList && (
                                                <>
                                                    <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                        <label className='new-label'>Offense Code</label>
                                                    </div>
                                                    <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                        <input type="text" className='readonlyColor' value={rmsCfsID.find((obj) => obj.value === searchValue.RMSCFSCodeList)?.label || ''} readOnly />
                                                    </div>
                                                </>
                                            )}
                                        {showFields.showAgeFrom && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Age From </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.AgeFrom || ''} readOnly />
                                                </div>
                                            </>
                                        )}
                                        {showFields.showAgeTo && (
                                            <>
                                                <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                    <label className='new-label'>Age To </label>
                                                </div>
                                                <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                    <input type="text" className='readonlyColor' value={searchValue.AgeTo || ''} readOnly />
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </fieldset>
                            </div>
                            <div className="container mt-1" style={{ pageBreakAfter: 'always' }}>
                                <div className="col-12">
                                    {
                                        arestReportData?.Incident?.map((obj) =>
                                            <>
                                                <div className="container">

                                                    <h5 className=" text-white text-bold bg-green py-1 px-3"> Incident Number:- {obj.IncidentNumber}</h5>
                                                    <table className="table table-bordered">
                                                        <thead className='text-dark master-table'>
                                                            <tr>
                                                                <th className='' style={{ width: '150px' }}>Incident Number:</th>
                                                                <th className='' style={{ width: '150px' }}>Reported Date/Time:</th>
                                                                <th className='' style={{ width: '150px' }}>RMS Disposition:</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className='master-tbody'>
                                                            <tr>
                                                                <td className='text-list' style={{ width: '150px' }}>{obj?.IncidentNumber}</td>
                                                                <td className='text-list' style={{ width: '150px' }}>{obj?.ReportedDate && getShowingDateText(obj?.ReportedDate)}</td>
                                                                <td className='text-list' style={{ width: '150px' }}>{obj?.RMSDisposition}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    {
                                                        JSON.parse(obj?.Arrest)?.length > 0 ?
                                                            <>
                                                                <div className="table-responsive" >
                                                                    <table className="table table-bordered" >
                                                                        <hr />
                                                                        <tbody className='master-tbody'>
                                                                            {
                                                                                JSON.parse(obj?.Arrest)?.map((item, key) => (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Arrest Number:</h6>
                                                                                                <p className='text-list'>{item.ArrestNumber}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Arrest Date/Time</h6>
                                                                                                <p className='text-list'>{item?.ArrestDtTm && getShowingDateText(item?.ArrestDtTm)}</p>
                                                                                            </td>
                                                                                            <td >
                                                                                                <h6 className='text-dark text-bold'>Officer:</h6>
                                                                                                <p className='text-list'>{item.PrimaryOfficer_Name}</p>
                                                                                            </td>

                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Arrestee:</h6>
                                                                                                <p className='text-list'>{item.Arrestee_Name}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Gender:</h6>
                                                                                                <p className='text-list'>{item.Gender}</p>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h6 className='text-dark text-bold'>Age</h6>
                                                                                                <p className='text-list'>{item.Years ? `${item.Years} years` : 'N/A'}</p>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td colSpan={12}>
                                                                                                <h6 className='text-dark text-bold'>Charge</h6>
                                                                                                {
                                                                                                    (JSON.parse(obj?.Arrest || "[]") || []).map((arrest, arrestKey) => (
                                                                                                        (JSON.parse(arrest?.Charge || "[]") || []).map((charge, chargeKey) => (
                                                                                                            <p className='text-list' key={`${arrestKey}-${chargeKey}`} >
                                                                                                                {charge.ChargeCode_Description}
                                                                                                            </p>
                                                                                                        ))
                                                                                                    ))
                                                                                                }
                                                                                            </td>
                                                                                        </tr>

                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </tbody>


                                                                        {/* <table className="table table-bordered">
                                                                            <thead className='text-dark master-table'>
                                                                                <tr>
                                                                                    <th className=''>Charge</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='master-tbody'>
                                                                                {
                                                                                    (JSON.parse(obj?.Arrest || "[]") || []).map((arrest, arrestKey) => (
                                                                                        (JSON.parse(arrest?.Charge || "[]") || []).map((charge, chargeKey) => (
                                                                                            <tr key={`${arrestKey}-${chargeKey}`} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                <td>{charge.ChargeCode_Description}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    ))
                                                                                }

                                                                            </tbody>
                                                                        </table> */}
                                                                    </table>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    }


                                                    <div className="table-responsive" >
                                                        {/* {
                                                            JSON.parse(obj?.Arrest).length > 0 ?
                                                                <>
                                                                    <table className="table table-bordered">
                                                                        <thead className='text-dark master-table'>
                                                                            <tr>
                                                                                <th className='' style={{ width: '150px' }}>Arrest Number</th>
                                                                                <th className='' style={{ width: '150px' }}>Arrest Date/Time</th>
                                                                                <th className='' style={{ width: '150px' }}>Officer</th>
                                                                                <th className='' style={{ width: '150px' }}>Arrestee</th>
                                                                                <th className='' style={{ width: '150px' }}>Gender</th>
                                                                                <th className='' style={{ width: '150px' }}>Age</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='master-tbody'>
                                                                            {
                                                                                JSON.parse(obj?.Arrest || obj?.Charge)?.map((item, key) => (
                                                                                    <>
                                                                                        <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                            <td style={{ width: '150px' }}>{item.ArrestNumber}</td>
                                                                                            <td style={{ width: '150px' }}>{item?.ArrestDtTm && getShowingDateText(item?.ArrestDtTm)}</td>
                                                                                            <td style={{ width: '150px' }}>{item.PrimaryOfficer_Name}</td>
                                                                                            <td style={{ width: '150px' }}>{item.Arrestee_Name}</td>
                                                                                            <td style={{ width: '150px' }}>{item.Gender}</td>
                                                                                            <td style={{ width: '150px' }}>{item.Years ? `${item.Years} years` : 'N/A'}</td>
                                                                                        </tr>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <table className="table table-bordered">
                                                                        <thead className='text-dark master-table'>
                                                                            <tr>
                                                                                <th className=''>Charge</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='master-tbody'>
                                                                            {
                                                                                (JSON.parse(obj?.Arrest || "[]") || []).map((arrest, arrestKey) => (
                                                                                    (JSON.parse(arrest?.Charge || "[]") || []).map((charge, chargeKey) => (
                                                                                        <tr key={`${arrestKey}-${chargeKey}`} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                            <td>{charge.ChargeCode_Description}</td>
                                                                                        </tr>
                                                                                    ))
                                                                                ))
                                                                            }

                                                                        </tbody>
                                                                    </table>

                                                                </>
                                                                :
                                                                <></>
                                                        } */}
                                                    </div>

                                                </div >
                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </div >
                </>
            }
            {loder && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
        </>
    )
}

export default ArrestSummary

export const changeArrayFormat = (data, type) => {
    if (type === 'zip') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.zipId, label: sponsor.Zipcode })
        )
        return result
    }
    if (type === 'state') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.StateID, label: sponsor.StateName })
        )
        return result
    }
    if (type === 'city') {
        const result = data?.map((sponsor) =>
            ({ value: sponsor.CityID, label: sponsor.CityName })
        );
        return result
    }
}
