import React, { memo, useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { tableCustomStyles } from "../../Components/Common/Utility";
import BoloSearchModal from "../BoloSearchModal";
import CallTakerModal from "../CallTakerModal";
import { colorLessStyle_Select } from "../Utility/CustomStylesForReact";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import { useQuery } from "react-query";


const BoloModal = (props) => {
    const { openBoloModal, setOpenBoloModal } = props;
    const [selectedImages, setSelectedImages] = useState([]);
    const [boloDisposition, setBoloDisposition] = useState([]);
    const [typeOFBOLO, setTypeOFBOLO] = useState([]);
    const [showPage, setShowPage] = useState("home")
    const [openBoloSearchModal, setOpenBoloSearchModal] = useState(false);
    const [openCallTakerModal, setCallTakerModal] = useState(false);
    const fileInputRef = useRef(null);
    const iconHome = <i className="fa fa-home" style={{ fontSize: '20px' }}></i>


    const getBoloDispositionKey = `/CAD/Monitor/MasterBoloDispositionGet/${parseInt(1)}`;
    const { data: getBoloDispositionData, isSuccess: isFetchBoloDisposition } = useQuery(
        [getBoloDispositionKey, {
            "IsActive": 1,
        },],
        MasterTableListServices.getBoloDisposition,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloModal,
        }
    )

    const getBoloTypeKey = `/CAD/MasterBoloType/GetBoloType/${parseInt(1)}`;
    const { data: getBoloTypeData, isSuccess: isFetchBoloType, refetch } = useQuery(
        [getBoloTypeKey, {
            "Action": "GET",
            "IsActive": 1
        },],
        MasterTableListServices.getBoloType,
        {
            refetchOnWindowFocus: false,
            enabled: openBoloModal,
        }
    );


    useEffect(() => {
        if (getBoloTypeData && isFetchBoloType) {
            const data = getBoloTypeData?.data?.data;
            setTypeOFBOLO(data?.Table)
        } else {
            setTypeOFBOLO([])
        }
    }, [getBoloTypeData, isFetchBoloType])

    useEffect(() => {
        if (getBoloDispositionData && isFetchBoloDisposition) {
            const data = JSON.parse(getBoloDispositionData?.data?.data);
            setBoloDisposition(data?.Table)
        } else {
            setBoloDisposition([])
        }
    }, [getBoloDispositionData, isFetchBoloDisposition])

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const filteredImages = files.filter((file) =>
            file.type === 'image/png' || file.type === 'image/jpeg'
        );
        setSelectedImages((prevImages) => [...prevImages, ...filteredImages]);
    };

    const removeImage = (index) => {
        setSelectedImages((prevImages) => {
            const updatedImages = prevImages.filter((_, i) => i !== index);
            if (updatedImages.length === 0 && fileInputRef.current) {
                fileInputRef.current.value = null;
            }
            return updatedImages;
        });
    };

    const columns2 = [

        {
            name: "Column Name",
            selector: (row) => (row.MiddleName ? row.MiddleName : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.MiddleName, rowB.MiddleName),
        },
        {
            name: "Old Value",
            selector: (row) => (row.FirstName ? row.FirstName : ""),

            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.FirstName, rowB.FirstName),
        },
        {
            name: "New Value",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Change Date",
            selector: (row) =>
                row.PhoneNo || "",
            sortable: true,
        },
    ];
    const columns = [
        {
            name: "View",
            selector: (row) => (row.MiddleName ? row.MiddleName : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.MiddleName, rowB.MiddleName),
        },
        {
            name: "Priority",
            selector: (row) => (row.FirstName ? row.FirstName : ""),

            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.FirstName, rowB.FirstName),
        },
        {
            name: "CAD Event #",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Date/Time",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Elapsed Days",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Message",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Dispatcher",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },
        {
            name: "Delete After",
            selector: (row) =>
                row.PhoneNo || "",
            sortable: true,
        },
    ];

    const contactList = [];
    return (
        <>
            {openBoloModal ? (
                <dialog
                    className="modal fade modal-cad"
                    style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                    id="BoloModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    data-backdrop="false"
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content modal-content-cad">
                            <div className="modal-body">
                                {/* Modal Header */}
                                <div className="row pb-2">
                                    <div className="col-12 ">
                                        <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                            <p
                                                className="p-0 m-0 font-weight-medium"
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: 500,
                                                    letterSpacing: 0.5,
                                                }}
                                            >
                                                BOLO
                                            </p>
                                            <div class="notification_dropdown">
                                                <a id="dLabel" role="button" data-toggle="dropdown" data-target="#" href="/page.html">
                                                    <i class="fa fa-bell"></i>
                                                </a>

                                                <ul class="dropdown-menu notifications" role="menu" aria-labelledby="dLabel">
                                                    <div class="notifications-wrapper">
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                        <div class="notification-item border-0">
                                                            <span class="item-title">Evaluation Deadline 1 · day ago</span>
                                                        </div>
                                                    </div>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 name-tab mx-1">
                                    <ul className='nav nav-tabs mx-1'>
                                        <span
                                            className={`nav-item ${showPage === 'home' ? 'active' : ''}`}
                                            style={{ color: showPage === 'home' ? 'Red' : '#000' }}
                                            aria-current="page"
                                            onClick={() => { setShowPage('home') }}

                                        >
                                            {iconHome}
                                        </span>
                                        <span
                                            className={`nav-item border-0 ${showPage === 'auditLog' ? 'active' : ''}`}
                                            style={{ color: showPage === 'auditLog' ? 'Red' : '#000' }}
                                            aria-current="page"
                                            onClick={() => { setShowPage('auditLog') }}

                                        >
                                            Change Log
                                        </span>
                                    </ul>
                                </div>
                                {showPage === 'home' &&
                                    <>
                                        {/* Form Area */}
                                        <div className="m-1">
                                            <fieldset style={{ border: "1px solid gray" }}>
                                                <div className="tab-form-container">
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Type of BOLO</label>
                                                        </div>
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            name="Resource1"
                                                            styles={colorLessStyle_Select}
                                                            maxMenuHeight={180}
                                                            options={typeOFBOLO}
                                                            getOptionLabel={(v) => v?.Description}
                                                            getOptionValue={(v) => v?.BoloTypeID}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isMulti
                                                            isSearchable={true}
                                                        />
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">CAD Event #</label>
                                                        </div>
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            name="Resource1"
                                                            styles={colorLessStyle_Select}
                                                            maxMenuHeight={180}
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isMulti
                                                            isSearchable={true}
                                                        />
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-border ml-1"
                                                            style={{ width: "85px" }}
                                                            data-toggle="modal"
                                                            data-target="#CallTakerModal"
                                                            onClick={() => setCallTakerModal(true)}
                                                        >
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Priority</label>
                                                        </div>
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            name="Resource1"
                                                            styles={colorLessStyle_Select}
                                                            maxMenuHeight={180}
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isMulti
                                                            isSearchable={true}
                                                        />
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Zone</label>
                                                        </div>
                                                        <Select
                                                            isClearable
                                                            placeholder="Select..."
                                                            name="Resource1"
                                                            styles={colorLessStyle_Select}
                                                            maxMenuHeight={180}
                                                            getOptionLabel={(v) => v?.ResourceNumber}
                                                            getOptionValue={(v) => v?.ResourceID}
                                                            onInputChange={(inputValue, actionMeta) => {
                                                                if (inputValue.length > 12) {
                                                                    return inputValue.slice(0, 12);
                                                                }
                                                                return inputValue;
                                                            }}
                                                            isMulti
                                                            isSearchable={true}
                                                        />
                                                    </div>
                                                    {/* Message Field */}
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Message</label>
                                                        </div>
                                                        <div className="col-11">
                                                            <textarea
                                                                name="comments"
                                                                placeholder="Enter Message"
                                                                rows='4'
                                                                className="form-control py-1 new-input"
                                                                style={{ height: '60px' }}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* Image Upload Section */}
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Upload File</label>
                                                        </div>
                                                        <div className="col-11 text-field">
                                                            <input
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                multiple
                                                                onChange={handleImageChange}
                                                                ref={fileInputRef}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Additional Form Fields */}
                                                    <div className="tab-form-row">
                                                        <div className="col-1 d-flex justify-content-end mt-1">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Delete After</label>
                                                        </div>
                                                        <div className="col-1 text-field">
                                                            <input type="text" className="" name="DeleteAfter" required />
                                                        </div>
                                                        <div className="col-1 d-flex justify-content-start mt-1">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Days</label>
                                                        </div>
                                                        <div className="col-1 d-flex justify-content-end mt-2">
                                                            <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">BOLO Disposition</label>
                                                        </div>
                                                        <div className="col-2 mt-2">
                                                            <Select
                                                                isClearable
                                                                placeholder="Select..."
                                                                name="Resource1"
                                                                styles={colorLessStyle_Select}
                                                                options={boloDisposition}
                                                                getOptionLabel={(v) => `${v?.DispositionCode} | ${v?.Description}`}
                                                                getOptionValue={(v) => v?.DispositionCode}
                                                                formatOptionLabel={(option, { context }) => {
                                                                    return context === 'menu'
                                                                        ? `${option?.DispositionCode} | ${option?.Description}`
                                                                        : option?.DispositionCode;
                                                                }}
                                                                maxMenuHeight={180}
                                                                onInputChange={(inputValue, actionMeta) => {
                                                                    if (inputValue.length > 12) {
                                                                        return inputValue.slice(0, 12);
                                                                    }
                                                                    return inputValue;
                                                                }}
                                                                isMulti
                                                                isSearchable={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="tab-form-row col-12 offset-1">
                                                        <div className="cad-images image-preview">
                                                            {selectedImages.map((image, index) => (
                                                                <div key={index} className="cad-images image-container">
                                                                    <img
                                                                        src={URL.createObjectURL(image)}
                                                                        alt={`Selected ${index}`}
                                                                        width="100"
                                                                        height="100"
                                                                    />
                                                                    <button onClick={() => removeImage(index)}>
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {/* Action Buttons */}
                                                    <div className="row">
                                                        <div className="col-12 p-0">
                                                            <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                                <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                                    <button type="button" className="save-button ml-2" data-toggle="modal"
                                                                        data-target="#BoloSearchModal" onClick={() => setOpenBoloSearchModal(true)}>Search</button>
                                                                    <button type="button" className="save-button">Save</button>
                                                                    <button type="button" data-dismiss="modal" className="cancel-button" onClick={() => setOpenBoloModal(false)}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="dropdown d-flex justify-content-end align-items-center">
                                            <button class="btn border dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Filter
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <span class="dropdown-item" href="#">Priority</span>
                                                <span class="dropdown-item" href="#">Type Of BOLO</span>
                                            </div>
                                        </div>
                                        {/* Data Table */}
                                        <div className="table-responsive modal-table">
                                            <DataTable
                                                dense
                                                columns={columns}
                                                data={contactList}
                                                customStyles={tableCustomStyles}
                                                pagination
                                                responsive
                                                striped
                                                highlightOnHover
                                                fixedHeader
                                                selectableRowsHighlight
                                                fixedHeaderScrollHeight="190px"
                                                persistTableHead={true}
                                            />
                                        </div>
                                    </>}
                                {showPage === 'auditLog' && <div className="table-responsive modal-table mt-2">
                                    <DataTable
                                        dense
                                        columns={columns2}
                                        data={contactList}
                                        customStyles={tableCustomStyles}
                                        pagination
                                        responsive
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        selectableRowsHighlight
                                        fixedHeaderScrollHeight="190px"
                                        persistTableHead={true}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div >
                </dialog >
            ) : null}
            <BoloSearchModal {...{ openBoloSearchModal, setOpenBoloSearchModal }} />
            <CallTakerModal
                {...{
                    openCallTakerModal,
                    setCallTakerModal,
                    // isIncidentDispatch,
                    // setIsIncidentDispatch
                }}
            />
        </>
    );
};

export default memo(BoloModal);
