import React, { useEffect, useContext, useState } from 'react'
import Select from "react-select";
import DatePicker from "react-datepicker";
import MobileTab from '../../../MobileUtility/MobileTab';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { Decrypt_Id_Name, getShowingDateText, getShowingMonthDateYear, getYearWithOutDateTime } from '../../../../Common/Utility';
import { AddDeleteUpadate, fetchData, fetchPostData } from '../../../../hooks/Api';
import { Comman_changeArrayFormat, threeColArray } from '../../../../Common/ChangeArrayFormat';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import { RequiredFieldIncident } from '../../../../Pages/Utility/Personnel/Validation';
import IdentifyFieldColor from '../../../../Common/IdentifyFieldColor';

const VehicleHome = ({ setStatus }) => {

    const { get_vehicle_Count, updateCount, setUpdateCount, setChangesStatus, changesStatusCount, localStoreArray, get_LocalStorage, setVehicleStatus, storeData } = useContext(AgencyContext)

    const navigate = useNavigate();
    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get('page');

    const { setShowPage } = useContext(AgencyContext);
    const [reportedDate, setReportedDate] = useState();
    const [destoryDate, setDestoryDate] = useState();
    const [plateExpDate, setPlateExpDate] = useState();
    const [manufactureDate, setManufactureDate] = useState();
    const [inspectionExpDate, setInspectionExpDate] = useState();
    //Ids
    const [vehicleID, setVehicleID] = useState('')
    const [masterPropertyID, setMasterPropertyID] = useState('');
    //img
    //--dropDown
    const [propertyLossCodeData, setPropertyLossCodeData] = useState([]);
    const [categoryIdDrp, setCategoryIdDrp] = useState([])
    const [plateTypeIdDrp, setPlateTypeIdDrp] = useState([]);
    const [classificationID, setClassificationID] = useState([])
    const [styleIdDrp, setStyleIdDrp] = useState([])
    const [makeIdDrp, setMakeIdDrp] = useState([])
    const [modalIdDrp, setModalIdDrp] = useState([])
    const [arresteeDrpData, setArresteeDrpData] = useState([])
    const [primaryOfficerID, setPrimaryOfficerID] = useState([])
    const [colorDrp, setColorDrp] = useState([])
    const [VodIdData, setVodIdData] = useState([])
    const [stateList, setStateList] = useState([]);
    const [editval, setEditval] = useState([]);

    const [mainIncidentID, setMainIncidentID] = useState('');
    const [loginAgencyID, setLoginAgencyID] = useState('');
    const [loginPinID, setLoginPinID] = useState('');

    const [value, setValue] = useState({
        'IncidentID': '',
        'VehicleID': vehicleID,
        'PropertyID': '',
        'MasterPropertyID': '',
        'CreatedByUserFK': '',
        'VehicleNumber': 'Auto Generated',
        'ReportedDtTm': '',
        'LossCodeID': '',
        'CategoryID': '',
        'PlateID': '',
        'VehicleNo': '',
        'PlateTypeID': '',
        'ClassificationID': '',
        'VIN': '',
        'VODID': '',
        'PlateExpireDtTm': '',
        'OANID': '',
        'StyleID': '',
        'MakeID': '',
        'ModelID': '',
        'ManufactureYear': '',
        'Weight': '',
        'OwnerID': '',
        'PrimaryColorID': '',
        'SecondaryColorID': '',
        'Value': '',
        'Inspection_Sticker': '',
        'InspectionExpiresDtTm': '',
        'PrimaryOfficerID': '',
        'InProfessionOf': '',
        'TagID': '',
        'NICBID': '',
        'DestroyDtTm': '',
        'Description': '',
        'IsEvidence': '',
        'IsPropertyRecovered': '',
        'IsImmobalizationDevice': '',
        'IsEligibleForImmobalization': '',
        'ModifiedByUserFK': "",
        'ArrestID': "",   // not in use  
    })
    const [errors, setErrors] = useState({
        'ReportedDtTmError': '', 'LossCodeIDError': '', 'CategoryIDError': '', 'RecoveryTypeIDError': '', 'PlateTypeIDError': '',
    })

    // const localStore = {
    //     Value: "",
    //     UniqueId: sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '',
    //     Key: JSON.stringify({ AgencyID: "", PINID: "", IncidentID: '', VehicleID: '', MasterPropertyID: '', }),
    // }

    useEffect(() => {
        if (!localStoreArray?.AgencyID || !localStoreArray?.PINID) {
            get_LocalStorage();
        }
    }, []);

    // Onload Function
    useEffect(() => {
        if (localStoreArray) {
            if (localStoreArray?.AgencyID && localStoreArray?.PINID) {
                setLoginAgencyID(localStoreArray?.AgencyID);
                setLoginPinID(localStoreArray?.PINID);
                if (localStoreArray.VehicleID) {
                    setVehicleID(localStoreArray?.VehicleID);setMasterPropertyID(localStoreArray?.MasterPropertyID);
                } else {
                    setVehicleID('');
                }
                setMainIncidentID(localStoreArray?.IncidentID);
                get_vehicle_Count(localStoreArray?.VehicleID)
            }
        }
    }, [localStoreArray])

    useEffect(() => {
        setValue({
            ...value,
            'IncidentID': localStoreArray?.IncidentID, 'CreatedByUserFK': localStoreArray?.PINID, 'PropertyID': localStoreArray?.PropertyID, 'MasterPropertyID': localStoreArray?.MasterPropertyID, 'AgencyID': localStoreArray?.AgencyID,
            'ReportedDtTm': getShowingMonthDateYear(openPage === 'masterProperty' ? new Date() : localStoreArray.ReportedDate ? localStoreArray?.ReportedDate : new Date())
        });
    }, [mainIncidentID])



    //validaction
    const check_Validation_Error = (e) => {
        if (RequiredFieldIncident(value.LossCodeID)) {
            setErrors(prevValues => { return { ...prevValues, ['LossCodeIDError']: RequiredFieldIncident(value.LossCodeID) } })
        }
        if (RequiredFieldIncident(value.CategoryID)) {
            setErrors(prevValues => { return { ...prevValues, ['CategoryIDError']: RequiredFieldIncident(value.CategoryID) } })
        }
        if (RequiredFieldIncident(value.ReportedDtTm)) {
            setErrors(prevValues => { return { ...prevValues, ['ReportedDtTmError']: RequiredFieldIncident(value.ReportedDtTm) } })
        }
        if (RequiredFieldIncident(value.PlateTypeID)) {
            setErrors(prevValues => { return { ...prevValues, ['PlateTypeIDError']: RequiredFieldIncident(value.PlateTypeID) } })
        }
    }

    // Check All Field Format is True Then Submit 
    const { LossCodeIDError, CategoryIDError, ReportedDtTmError, RecoveryTypeIDError, PlateTypeIDError, } = errors



    useEffect(() => {
        if (LossCodeIDError === 'true' && CategoryIDError === 'true' && ReportedDtTmError === 'true' && PlateTypeIDError === 'true') {
            if (openPage === 'VehicleSearch') {
                if (masterPropertyID) { Update_Vehicle(); }
                else { Insert_Vehicle(); }
            } else {
                if (vehicleID && masterPropertyID) { Update_Vehicle(); }
                else { Insert_Vehicle() }
            }
        } else if (LossCodeIDError === 'Required *' || CategoryIDError === 'Required *' || ReportedDtTmError === 'Required *' || PlateTypeIDError === 'Required *') toastifyError('Please Fill All Required Field')
    }, [LossCodeIDError, CategoryIDError, ReportedDtTmError, RecoveryTypeIDError, PlateTypeIDError])

    //-------------Get-Single-Data----------------------
    useEffect(() => {
        if (vehicleID) {
            GetSingleData(vehicleID)
        } else {
            reset();
        }
    }, [vehicleID, updateCount]);

    const GetSingleData = (vehicleID) => {
        const val = {
            'VehicleID': vehicleID,
        }
        fetchPostData('PropertyVehicle_FRW/GetSingleData_Vehicle_FRW', val).then((res) => {
     
            if (res.length > 0) {
                setEditval(res);
            }
            else {
                setEditval([])
            }
        })
    }

    useEffect(() => {
  
        if ((vehicleID) && editval.length > 0) {
            get_Classification_Drp(editval[0]?.CategoryID);
            setValue({
                ...value,
                'LossCodeID': editval[0]?.LossCodeID, 'NICBID': editval[0]?.NICBID, 'TagID': editval[0]?.TagID, 'PrimaryOfficerID': editval[0]?.PrimaryOfficerID, 'SecondaryColorID': editval[0]?.SecondaryColorID,
                'PrimaryColorID': editval[0]?.PrimaryColorID, 'OwnerID': editval[0]?.OwnerID, 'ModelID': editval[0]?.ModelID, 'MakeID': editval[0]?.MakeID, 'StyleID': editval[0]?.StyleID, 'OANID': editval[0]?.OANID, 'VODID': editval[0]?.VODID,
                'ClassificationID': editval[0]?.ClassificationID, 'PlateTypeID': editval[0]?.PlateTypeID, 'PlateID': editval[0]?.PlateID, 'CategoryID': editval[0]?.CategoryID, 'VehicleNumber': editval[0]?.VehicleNumber,
                'ReportedDtTm': getShowingDateText(editval[0]?.ReportedDtTm), 'IsEligibleForImmobalization': editval[0]?.IsEligibleForImmobalization, 'IsImmobalizationDevice': editval[0]?.IsImmobalizationDevice, 'IsPropertyRecovered': editval[0]?.IsPropertyRecovered,
                'IsEvidence': editval[0]?.IsEvidence, 'InProfessionOf': editval[0]?.InProfessionOf, 'Description': editval[0]?.Description, 'DestroyDtTm': editval[0]?.DestroyDtTm, 'InspectionExpiresDtTm': editval[0]?.InspectionExpiresDtTm,
                'Inspection_Sticker': editval[0]?.Inspection_Sticker, 'Value': editval[0]?.Value, 'Weight': editval[0]?.Weight, 'ManufactureYear': editval[0]?.ManufactureYear,
                'VIN': editval[0]?.VIN, 'VehicleNo': editval[0]?.VehicleNo, 'PlateExpireDtTm': editval[0]?.PlateExpireDtTm,
                'ModifiedByUserFK': loginPinID, 'VehicleID': editval[0]?.VehicleID,
            })
            get_ModalId_Drp(editval[0]?.MakeID);
            setVehicleID(editval[0]?.VehicleID);
            setReportedDate(editval[0]?.ReportedDtTm ? new Date(editval[0]?.ReportedDtTm) : '');
            setDestoryDate(editval[0]?.DestroyDtTm ? new Date(editval[0]?.DestroyDtTm) : '');
            setInspectionExpDate(editval[0]?.InspectionExpiresDtTm ? new Date(editval[0]?.InspectionExpiresDtTm) : '');
            setManufactureDate(editval[0]?.ManufactureYear ? new Date(editval[0]?.ManufactureYear) : '');
            setPlateExpDate(editval[0]?.PlateExpireDtTm ? new Date(editval[0]?.PlateExpireDtTm) : '');
        }
    }, [editval, updateCount, changesStatusCount])


    //-----Reset
    const reset = () => {
        setValue({
            ...value,
            'VehicleNumber': '', 'LossCodeID': '', 'CategoryID': '', 'PlateID': '', 'VehicleNo': '', 'PlateTypeID': '', 'ClassificationID': '',
            'VIN': '', 'VODID': '', 'PlateExpireDtTm': '', 'OANID': '', 'StyleID': '', 'MakeID': '', 'ModelID': '', 'ManufactureYear': '',
            'Weight': '', 'OwnerID': '', 'PrimaryColorID': '', 'SecondaryColorID': '', 'Value': '', 'Inspection_Sticker': '', 'InspectionExpiresDtTm': '',
            'PrimaryOfficerID': '', 'InProfessionOf': '', 'TagID': '', 'NICBID': '', 'DestroyDtTm': '', 'Description': '',
            'IsEvidence': '', 'IsPropertyRecovered': '', 'IsImmobalizationDevice': '', 'IsEligibleForImmobalization': '',
        })
        setPlateExpDate(); setManufactureDate(); setInspectionExpDate(); setDestoryDate();
    }




    useEffect(() => {
        get_PropertyLoos_Drp(loginAgencyID); get_CategoryId_Drp(); get_PlateType_Drp(loginAgencyID); get_StyleId_Drp(loginAgencyID); get_MakeId_Drp(loginAgencyID); get_Arrestee_Drp_Data(mainIncidentID); PropertyType(loginAgencyID)
        get_Head_Of_Agency(loginAgencyID); get_ColorDrp(loginAgencyID); get_Data_VODID(loginAgencyID);
        getCountryID();
    }, [loginAgencyID])

    const get_Data_VODID = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('VehicleVOD/GetDataDropDown_VehicleVOD', val).then((res) => {
            if (res) {
                setVodIdData(Comman_changeArrayFormat(res, 'VehicleVODID', 'Description'));
            } else {
                setVodIdData([]);
            }
        })
    }

    const PropertyType = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('PropertyCategory/GetDataDropDown_PropertyCategory', val).then((data) => {
            if (data) {
                const res = data?.filter((val) => {
                    if (val.PropertyCategoryCode === "V") return val
                })
                if (res.length > 0) {
                    get_CategoryId_Drp(res[0]?.PropertyCategoryID)
                }
            } else {
            }
        })
    }

    const get_ColorDrp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('PropertyVehicle/GetDataDropDown_Color', val).then((data) => {
            if (data) {
                setColorDrp(Comman_changeArrayFormat(data, 'ColorID', 'ColorDescription'));
            }
            else {
                setColorDrp([])
            }
        })
    };

    const get_Head_Of_Agency = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('DropDown/GetData_HeadOfAgency', val).then((data) => {
            if (data) {
                setPrimaryOfficerID(Comman_changeArrayFormat(data, 'PINID', 'HeadOfAgency'));
            }
            else {
                setPrimaryOfficerID([])
            }
        })
    };

    const get_Arrestee_Drp_Data = (mainIncidentID) => {
        const val = {
            'IncidentID': mainIncidentID
        }
        fetchPostData('Arrest/GetDataDropDown_Arrestee', val).then((data) => {
            if (data) {
                setArresteeDrpData(Comman_changeArrayFormat(data, 'NameID', 'Arrestee_Name'));
            }
            else {
                setArresteeDrpData([])
            }
        })
    };

    const get_ModalId_Drp = (PropertyVehicleMakeID) => {
        const val = {
            AgencyID: loginAgencyID,
            'PropertyVehicleMakeID': PropertyVehicleMakeID
        }
        fetchPostData('PropertyVehicleModel/GetDataDropDown_PropertyVehicleModel', val).then((data) => {
            if (data) {

                setModalIdDrp(Comman_changeArrayFormat(data, 'PropertyVehicleModelID', 'Description'))
            } else {
                setModalIdDrp([]);
            }
        })
    }

    const get_MakeId_Drp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('PropertyVehicleMake/GetDataDropDown_PropertyVehicleMake', val).then((data) => {
            if (data) {
                setMakeIdDrp(Comman_changeArrayFormat(data, 'PropertyVehicleMakeID', 'Description'))
            } else {
                setMakeIdDrp([]);
            }
        })
    }

    const get_StyleId_Drp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('PropertyVehicleStyle/GetDataDropDown_PropertyVehicleStyle', val).then((data) => {
            if (data) {
                setStyleIdDrp(Comman_changeArrayFormat(data, 'VehicleStyleID', 'Description'))
            } else {
                setStyleIdDrp([]);
            }
        })
    }

    const get_Classification_Drp = (PropertyDescID) => {
        const val = {
            PropertyDescID: PropertyDescID,
        }
        fetchPostData('Property/GetDataDropDown_PropertyClassification', val).then((data) => {
            if (data) {
                setClassificationID(Comman_changeArrayFormat(data, 'PropertyClassificationID', 'Description'))
            } else {
                setClassificationID([]);
            }
        })
    }

    const get_PlateType_Drp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('PropertyVehiclePlateType/GetDataDropDown_PropertyVehiclePlateType', val).then((data) => {
            if (data) {
                setPlateTypeIdDrp(Comman_changeArrayFormat(data, 'PlateTypeID', 'Description'))
            } else {
                setPlateTypeIdDrp([]);
            }
        })
    }

    const getCountryID = async () => {
        fetchData("State_City_ZipCode/GetData_State").then((data) => {
            if (data) {
                setStateList(Comman_changeArrayFormat(data, "StateID", "StateName"));
            } else {
                setStateList([]);
            }
        });
    };

    const get_PropertyLoos_Drp = (loginAgencyID) => {
        const val = {
            AgencyID: loginAgencyID,
        }
        fetchPostData('PropertyVehicle/GetDataDropDown_PropertyVehiclePropertyReasonCode', val).then((data) => {
            if (data) {
  
                setPropertyLossCodeData(threeColArray(data, 'PropertyReasonCodeID', 'Description', 'PropertyReasonsCode'))
            } else {
                setPropertyLossCodeData([]);
            }
        })
    }

    const get_CategoryId_Drp = (CategoryID) => {
        const val = {
            CategoryID: CategoryID,
        }
        fetchPostData('Property/GetDataDropDown_PropertyType', val).then((data) => {
            if (data) {
                setCategoryIdDrp(threeColArray(data, 'PropertyDescID', 'Description', 'CategoryID'))
            } else {
                setCategoryIdDrp([]);
            }
        })
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'MakeID') {
                get_ModalId_Drp(e.value)
                setValue({
                    ...value,
                    ['MakeID']: e.value
                })
            }
            if (name === 'CategoryID') { get_Classification_Drp(e.value) }
            setValue({
                ...value,
                [name]: e.value
            })
        } else if (e === null) {
            if (name === 'CategoryID') {
                setValue({
                    ...value,
                    ['CategoryID']: '',
                    ['ClassificationID']: ''
                })
                setClassificationID([]);
            }
            if (name === 'MakeID') {
                setValue({
                    ...value,
                    ['MakeID']: '',
                    ['ModelID']: ''
                })
                setModalIdDrp([]);
            }
            setValue({
                ...value,
                [name]: null
            })
        } else {
            setValue({
                ...value,
                [name]: null
            })
        }
    }
    const HandleChanges = (e) => {
        if (e.target.name === 'IsEvidence' || e.target.name === 'IsPropertyRecovered' || e.target.name === 'IsImmobalizationDevice' || e.target.name === 'IsEligibleForImmobalization') {
            setValue({
                ...value,
                [e.target.name]: e.target.checked
            })
        }
        else if (e.target.name === 'Value') {
            const ele = e.target.value.replace(/[^0-9\.]/g, "")
            setValue({
                ...value,
                [e.target.name]: ele
            });
            // if (ele.length === 16) {
            //     setValue({
            //         ...value,
            //         [e.target.name]: ele
            //     });
            // } else {
            //     setValue({
            //         ...value,
            //         [e.target.name]: ele
            //     });
            // }
        } else if (e.target.name === 'Weight') {
            const checkNumber = e.target.value.replace(/[^0-9\s]/g, "");
            setValue({ ...value, [e.target.name]: checkNumber })
        }
        else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    //-------Insert-Update-Delete-----------
    const Insert_Vehicle = () => {
        AddDeleteUpadate('PropertyVehicle_FRW/Insert_Vehicle_FRW', value).then((res) => {
            if (res.success) {
                toastifySuccess(res.Message);
                setErrors({ ...errors, ['LossCodeIDError']: '' })
                if (res.VehicleID) {
      
                    setChangesStatus(false)
                    setVehicleStatus(true);
                    setVehicleID(res.VehicleID);
                    storeData({ 'VehicleID': res?.VehicleID, 'VehicleStatus': true })
                }
                setUpdateCount(updateCount + 1)
                setChangesStatus(false)
                setStatus(true)
            } else {
                toastifyError('Error')
            }
        })
    }

    const Update_Vehicle = () => {
        AddDeleteUpadate('PropertyVehicle_FRW/Update_Vehicle_FRW', value).then((res) => {
            if (res.success) {
                toastifySuccess(res.Message);
                setChangesStatus(false)
                setErrors({ ...errors, ['LossCodeIDError']: '' });
                setUpdateCount(updateCount + 1)
            } else {
                toastifyError('Error')
            }
        })
    }

    const OnClose = () => {
        navigate('/vehicle-main');
    }

    // Custom Style
    const colourStyles = {
        control: (styles) => ({
            ...styles, backgroundColor: "#fce9bf",
            height: 20,
            minHeight: 36,
            fontSize: 14,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const customStylesWithOutColor = {
        control: base => ({
            ...base,
            height: 20,
            minHeight: 36,
            fontSize: 18,
            margintop: 2,
            boxShadow: 0,
        }),
    };

    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
        }
    };

    return (
        <>

            <div className=" ">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12 px-2">
                        <div className="card Agency " style={{ borderRight: 'none', borderLeft: 'none', borderRadius: '0' }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className={`col-12 col-md-12`}>
                                        <div className="row ">
                                            <div className="col-12  mobile__tabs" style={{ marginTop: '-18px', marginBottom: '-20px' }}>
                                                <MobileTab />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        vehicleID &&

                        <Link to='/vehicle-tabs'>
                            <div data-toggle="modal" data-target="#myModal" style={{ fontSize: 18, cursor: 'pointer', fontWeight: 'bold', background: 'cadetblue', position: 'absolute', padding: '7px 10px', right: '0px', color: '#434A54', top: '0px' }} onKeyDown={''} onClick={setShowPage('MobileAdditionalInformation')}>
                                <i className='fa fa-arrow-left' style={{ fontSize: '18px' }}></i>
                                <span >Tabs</span>
                            </div>
                        </Link>

                    }
                    <div className="card Agency  " style={{ borderRight: 'none', borderLeft: 'none', borderRadius: '0', marginTop: '-10px', borderTop: 'none' }}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12  p-0 " >
                                    <div className="bg-line  py-1  px-0  d-flex justify-content-between align-items-center " style={{ marginTop: '-12px' }}>
                                        <p className="p-0 m-0 pl-3 py-1" style={{ fontSize: '18px' }}>Vehicle</p>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 col-md-4 col-lg-2 " style={{ marginTop: '8px' }}>
                                        <div className="text-mobile">
                                            <input type="text" name='VehicleNumber' id='VehicleNumber' value={value?.VehicleNumber} onChange={HandleChanges} className='readonlyColor' required />
                                            <label style={{ paddingTop: '2px' }}>Vehicle Number</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 pt-1 ">
                                        <div className="text__dropdwon">
                                            <DatePicker
                                                open={false}
                                                id='ReportedDtTm'
                                                name='ReportedDtTm'
                                                ref={startRef}
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => { setReportedDate(date); setValue({ ...value, ['ReportedDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                                className='requiredColor name-datepicker'
                                                isClearable
                                                selected={reportedDate}
                                                dateFormat="MM/dd/yyyy HH:mm"
                                            />
                                            <label htmlFor="" className='pt-2'>Reported Date/Time<span className='text-danger pl-1'>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-4 mt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='CategoryID'
                                                value={categoryIdDrp?.filter((obj) => obj.value === value?.CategoryID)}
                                                styles={colourStyles}
                                                options={categoryIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'CategoryID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Category<span className='text-danger pl-1'>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='ClassificationID'
                                                value={classificationID?.filter((obj) => obj.value === value?.ClassificationID)}
                                                styles={customStylesWithOutColor}
                                                options={classificationID}
                                                onChange={(e) => ChangeDropDown(e, 'ClassificationID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Classification</label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 mt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='LossCodeID'
                                                value={propertyLossCodeData?.filter((obj) => obj.value === value?.LossCodeID)}
                                                styles={colourStyles}
                                                options={propertyLossCodeData}
                                                onChange={(e) => ChangeDropDown(e, 'LossCodeID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Loss Code <span className='text-danger pl-1'>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 d-flex px-0">
                                        <div className="col-6 col-md-8 col-lg-8 mt-1 ">
                                            <div className=" text__dropdwon">
                                                <Select
                                                    name='PlateID'
                                                    value={stateList?.filter((obj) => obj.value === value?.PlateID)}
                                                    styles={customStylesWithOutColor}
                                                    // styles={colourStyles}
                                                    options={stateList}
                                                    onChange={(e) => ChangeDropDown(e, 'PlateID')}
                                                    isClearable
                                                    placeholder="Select..."
                                                />
                                                <label htmlFor="" className='pl-0 pt-2'>Plate Id</label>
                                            </div>
                                        </div>
                                        <span className='' style={{ marginLeft: '-10px', marginTop: '4px' }}>
                                            <div className="col-md-12 col-lg-12 pt-1 ">
                                                <div className="text-mobile  ">
                                                    <input type="text" name='' id='' maxLength={8} onChange={HandleChanges} placeholder='1234' className='' required />
                                                </div>
                                            </div>
                                        </span>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-3 mt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='PlateTypeID'
                                                value={plateTypeIdDrp?.filter((obj) => obj.value === value?.PlateTypeID)}
                                                styles={colourStyles}
                                                options={plateTypeIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'PlateTypeID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Plate Type <span className='text-danger pl-1'>*</span>
                                            </label>
                                        </div>

                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-1  pt-1 d-flex ">
                                        <div className="text-mobile">
                                            <input type="text" name='VIN' id='VIN' maxLength={17} value={value?.VIN} onChange={HandleChanges} className='' required />
                                            <label className="pt-1">VIN</label>
                                        </div>
                                        <Link to={''} className='mt-2 pt-2'>
                                            <span className='  col-1 col-md-1 col-lg-1'>
                                                <i className='fa fa-search btn btn-sm bg-green text-white px-2 py-2'></i>
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-1 pt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='VODID'
                                                value={VodIdData?.filter((obj) => obj.value === value?.VODID)}
                                                styles={customStylesWithOutColor}
                                                options={VodIdData}
                                                onChange={(e) => ChangeDropDown(e, 'VODID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>VOD</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3" style={{ marginTop: '8px' }}>
                                        <div className="text__dropdwon">
                                            <DatePicker
                                                id='PlateExpireDtTm'
                                                name='PlateExpireDtTm'
                                                ref={startRef1}
                                                className='name-datepicker'
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => { setPlateExpDate(date); setValue({ ...value, ['PlateExpireDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                                dateFormat="MM/dd/yyyy"
                                                isClearable
                                                selected={plateExpDate}
                                                placeholderText={value?.PlateExpireDtTm ? value?.PlateExpireDtTm : 'Select...'}
                                                autoComplete="nope"
                                                showYearDropdown
                                                showMonthDropdown
                                                dropdownMode="select"
                                                popperPlacement='right'
                                            />
                                            <label htmlFor="" className='pt-2'>Plate Expires</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3  " style={{ marginTop: '10px' }}>
                                        <div className="text-mobile">
                                            <input type="text" name='OANID' id='OANID' value={value?.OANID} onChange={HandleChanges} className='' required />
                                            <label className='pt-1'>OAN Id</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 pt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='StyleID'
                                                value={styleIdDrp?.filter((obj) => obj.value === value?.StyleID)}
                                                styles={customStylesWithOutColor}
                                                options={styleIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'StyleID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Style</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-1">
                                        <div className="text__dropdwon">
                                            <Select
                                                name='MakeID'
                                                value={makeIdDrp?.filter((obj) => obj.value === value?.MakeID)}
                                                styles={customStylesWithOutColor}
                                                options={makeIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'MakeID')}
                                                isClearable
                                                placeholder="Select..."
                                                menuPlacement='top'
                                            />
                                            <label htmlFor="" className='pt-2'>Make</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-6 col-lg-3 mt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='ModelID'
                                                value={modalIdDrp?.filter((obj) => obj.value === value?.ModelID)}
                                                styles={customStylesWithOutColor}
                                                options={modalIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'ModelID')}
                                                isClearable
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Model</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-2 pt-1">
                                        <div className="text__dropdwon">
                                            <DatePicker
                                                name='ManufactureYear'
                                                id='ManufactureYear'
                                                selected={manufactureDate}
                                                onChange={(date) => { setManufactureDate(date); setValue({ ...value, ['ManufactureYear']: date ? getYearWithOutDateTime(date) : null }) }}
                                                showYearPicker
                                                dateFormat="yyyy"
                                                yearItemNumber={9}
                                                ref={startRef2}
                                                onKeyDown={onKeyDown}
                                                autoComplete="nope"
                                                showYearDropdown
                                                showMonthDropdown
                                                dropdownMode="select"
                                                maxDate={new Date()}
                                                className='name-datepicker'
                                            />
                                            <label htmlFor="" className='pt-2'>Manufacture Year</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-2 col-lg-1 " style={{ marginTop: '7px' }}>
                                        <div className="text-mobile">
                                            <input type="text" name='Weight' id='Weight' maxLength={4} value={value?.Weight} onChange={HandleChanges} className='' required />
                                            <label className='pt-1'>Weight</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-6 col-lg-3 mt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='OwnerID'
                                                value={arresteeDrpData?.filter((obj) => obj.value === value?.OwnerID)}
                                                styles={customStylesWithOutColor}
                                                options={arresteeDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'OwnerID')}
                                                isClearable
                                                placeholder="Select..."
                                                menuPlacement='top'
                                            />
                                            <label htmlFor="" className='pt-2'>Owner</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-6 col-lg-3 mt-1 pt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='PrimaryColorID'
                                                value={colorDrp?.filter((obj) => obj.value === value?.PrimaryColorID)}
                                                styles={customStylesWithOutColor}
                                                options={colorDrp}
                                                onChange={(e) => ChangeDropDown(e, 'PrimaryColorID')}
                                                isClearable
                                                menuPlacement='top'
                                                placeholder="Select..."
                                            />
                                            <label htmlFor="" className='pt-2'>Primary Color</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-3 mt-1 pt-1">
                                        <div className=" text__dropdwon">
                                            <Select
                                                name='SecondaryColorID'
                                                value={colorDrp?.filter((obj) => obj.value === value?.SecondaryColorID)}
                                                styles={customStylesWithOutColor}
                                                options={colorDrp}
                                                onChange={(e) => ChangeDropDown(e, 'SecondaryColorID')}
                                                isClearable
                                                placeholder="Select..."
                                                menuPlacement='top'
                                            />
                                            <label htmlFor="" className='pt-2'>Secondary Color</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-2   " style={{ marginTop: '12px' }}>
                                        <div className="text-mobile">
                                            <input type="text" name='Value' id='Value' maxLength={20} value={'$' + value?.Value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ',')} onChange={HandleChanges} className='' required />
                                            <label className='pt-1'>Value</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-4 col-lg-4  " style={{ marginTop: '11px' }}>
                                        <div className="text-mobile">
                                            <input type="text" name='Inspection_Sticker' id='Inspection_Sticker' value={value?.Inspection_Sticker} onChange={HandleChanges} className='' required />
                                            <label className='pt-1'>Inspection Sticker</label>
                                        </div>
                                    </div>
                                    <div className="col-4 col-md-5 col-lg-4  mt-1">
                                        <div className="text__dropdwon">
                                            <DatePicker
                                                id='InspectionExpiresDtTm'
                                                name='InspectionExpiresDtTm'
                                                ref={startRef3}
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => { setInspectionExpDate(date); setValue({ ...value, ['InspectionExpiresDtTm']: date ? getShowingMonthDateYear(date) : null }) }}
                                                dateFormat="MM/dd/yyyy"
                                                isClearable
                                                className='name-datepicker'
                                                selected={inspectionExpDate}
                                                placeholderText={value?.InspectionExpiresDtTm ? value?.InspectionExpiresDtTm : 'Select...'}
                                                autoComplete="nope"
                                                showYearDropdown
                                                showMonthDropdown
                                                dropdownMode="select"
                                            />
                                            <label htmlFor="" className='pt-2'>Inspection Expires</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12  text-right " style={{ marginTop: '-25px', marginBottom: '-10px' }}>
                                {
                                    vehicleID ?

                                        <button type="button" className="btn btn-sm btn-success  new-button mr-1" onClick={(e) => { check_Validation_Error(); }}>Update</button>

                                        :

                                        <button type="button" className="btn btn-sm btn-success  new-button  mr-1" onClick={(e) => { check_Validation_Error(); }}>Save</button>

                                }
                                <button type="button" className="btn btn-sm btn-success  new-button mr-1" data-dismiss="modal" onClick={() => { OnClose() }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <IdentifyFieldColor />

        </>
    )
}

export default VehicleHome