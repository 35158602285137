import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { Decrypt_Id_Name, colourStyles, customStylesWithOutColor, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, } from '../../../../Common/Utility';
import { Link } from 'react-router-dom';
import { fetchPostData } from '../../../../hooks/Api';
import { useReactToPrint } from 'react-to-print';
import { toastifyError } from '../../../../Common/AlertMsg';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import Location from '../../../../Location/Location';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { get_Incident_Drp_Data } from '../../../../../redux/actions/DropDownsData';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
// import { get_AgencyOfficer_Data } from '../../../../redux/actions/DropDownsData';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DOMPurify from 'dompurify';
import Loader from '../../../../Common/Loader';

const MasterIncident = () => {

    const dispatch = useDispatch();
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const cadCfsCodeDrpData = useSelector((state) => state.DropDown.cadCfsCodeDrpData);
    const fbiCodesDrpData = useSelector((state) => state.DropDown.fbiCodesDrpData);

    const [multiImage, setMultiImage] = useState([]);
    const [verifyIncident, setverifyIncident] = useState(false);
    const [reportedData, setReportedData] = useState([]);
    const [incidentData, setIncidentData] = useState([]);
    const [masterReportData, setMasterReportData] = useState([]);
    const [rmsCfsID, setRmsCfsID] = useState([]);

    const [LoginAgencyID, setLoginAgencyID] = useState('');
    const [LoginPinID, setLoginPinID,] = useState('');
    const [locationStatus, setLocationStatus] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(0);
    const [PropertyPhoto, setPropertyPhoto] = useState([]);
    const [loder, setLoder] = useState(false);

    const [propertyID, setPropertyID] = useState('');
    const [masterPropertyID, setMasterPropertyID] = useState('');
    const [value, setValue] = useState({
        'IncidentNumber': '', 'IncidentNumberTo': '', 'ReportedDate': null, 'ReportedDateTo': null, 'OccurredFrom': null, 'OccurredTo': null, 'Location': '', 'AgencyID': '', 'FBIID': null, 'RMSCFSCodeList': '',
    });

    const [searchValue, setSearchValue] = useState({
        IncidentNumber: '',
        IncidentNumberTo: '',
        ReportedDate: '',
        ReportedDateTo: '',
        OccurredFrom: '',
        OccurredTo: '',
        Location: '',
        FBIID: null,
        RMSCFSCodeList: null
    });
    const [showFields, setShowFields] = useState({
        showIncidentNumber: false,
        showIncidentNumberTo: false,
        showReportedDateFrom: false,
        showReportedDateTo: false,
        showOccurredFrom: false,
        showOccurredTo: false,
        showLocation: false,
        showFBIID: false,
        showRMSCFSCodeList: false,
    });

    useEffect(() => {
        setShowFields({
            showIncidentNumber: searchValue.IncidentNumber,
            showIncidentNumberTo: searchValue.IncidentNumberTo,
            showReportedDateFrom: searchValue.ReportedDate,
            showReportedDateTo: searchValue.ReportedDateTo,
            showOccurredFrom: searchValue.OccurredFrom,
            showOccurredTo: searchValue.OccurredTo,
            showLocation: searchValue.Location,
            showFBIID: searchValue.FBIID !== null,
            showRMSCFSCodeList: searchValue.RMSCFSCodeList !== null,
        });
    }, [searchValue]);
    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);
    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);
    // Onload Function
    useEffect(() => {
        if (LoginAgencyID) {
            // setLoginAgencyID(localStoreData?.AgencyID);
            // setLoginPinID(localStoreData?.PINID);
            getAgencyImg(LoginAgencyID);
            getpPropertyImg()
            // get_Incident_Report(localStoreData?.AgencyID)
            if (fbiCodesDrpData?.length === 0) { dispatch(get_Incident_Drp_Data(LoginAgencyID)) }
        }
    }, [LoginAgencyID])

    const get_Incident_Report = () => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Report/GetData_ReportIncident', val).then((res) => {
            if (res.length > 0) {
                // setReportedData(res);
                // setverifyIncident(false);
            } else {
                // setverifyIncident(false);
                // setReportedData([]);
            }
        })
    }


    useEffect(() => {
        if (incidentData?.length > 0) {
            setverifyIncident(true);
        }
    }, [incidentData]);

    const getIncidentCurrentData = async () => {
        const val = {
            'ReportedDate': getShowingWithOutTime(new Date()),
            'ReportedDateTo': getShowingWithOutTime(new Date()),
            'IncidentNumber': null, 'IncidentNumberTo': null,
            'OccurredFrom': null, 'OccurredTo': null,
            'AgencyID': LoginAgencyID
        }
        fetchPostData('Report/GetData_MasterReport', val).then((res) => {
            if (res.length > 0) {
                // console.log(JSON.parse(res[0]?.Incident[0]?.Offence))
                // setIncidentData(res[0].Incident);
                //  setMasterReportData(res[0])
            } else {
            }
        });
    }

    const getRMSCFSCodeList = (LoginAgencyID,FBIID) => {
        const val = { 'FBIID': FBIID, 'AgencyID': LoginAgencyID, }
        fetchPostData('ChargeCodes/GetDataDropDown_ChargeCodes', val).then((data) => {
            if (data) {
                setRmsCfsID(Comman_changeArrayFormat(data, 'ChargeCodeID', 'Description'))
            } else {
                setRmsCfsID([]);
            }
        })
    }

    const getIncidentSearchData = async () => {
        setLoder(true);
    
        // Check if any fields in value are filled
        const hasValidInput = 
            value?.IncidentNumber?.trim() || 
            value?.IncidentNumberTo?.trim() || 
            value?.ReportedDate?.trim() || 
            value?.ReportedDateTo?.trim() || 
            value?.OccurredFrom?.trim() || 
            value?.OccurredTo?.trim() || 
            value?.FBIID !== null || 
            value?.RMSCFSCodeList !== null || 
            value?.Location?.trim();
    
        if (hasValidInput) {
            const { IncidentNumber, IncidentNumberTo, ReportedDate, ReportedDateTo, OccurredFrom, OccurredTo, FBIID, RMSCFSCodeList, Location } = value;
            const val = {
                IncidentNumber,
                IncidentNumberTo,
                ReportedDate,
                ReportedDateTo,
                OccurredFrom,
                OccurredTo,
                AgencyID: LoginAgencyID,
                FBIID,
                RMSCFSCodeList,
                Location,
            };
    
            try {
                const res = await fetchPostData('Report/GetData_MasterReport', val);
                if (res.length > 0) {
                    setIncidentData(res[0].Incident);
                    setMasterReportData(res[0]);
                    getpPropertyImg(masterPropertyID, propertyID);
                    getAgencyImg(LoginAgencyID);
                    setSearchValue(value);
                } else {
                    toastifyError("Data Not Available");
                    setIncidentData([]);
                }
            } catch (error) {
                toastifyError("Data Not Available");
                setverifyIncident(false);
            }
        } else {
            toastifyError("Please Enter Details");
        }
    
        setLoder(false);
    };
    
    // const getIncidentSearchData = async () => {
    //     setLoder(true);
    //     if (value?.IncidentNumber?.trim()?.length > 0 || value?.ReportedDate?.trim()?.length > 0 || value?.ReportedDateTo?.trim()?.length > 0 || value?.OccurredFrom?.trim()?.length > 0 || value?.OccurredTo?.trim()?.length > 0 || value?.IncidentNumberTo?.trim()?.length > 0 || value?.FBIID !== null || value?.RMSCFSCodeList !== null || value?.Location?.trim()?.length > 0) {
    //         const { IncidentNumber, IncidentNumberTo, ReportedDate, ReportedDateTo, OccurredFrom, OccurredTo, AgencyID, FBIID, RMSCFSCodeList, Location } = value
    //         const val = {
    //             'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo, 'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'OccurredFrom': OccurredFrom, 'OccurredTo': OccurredTo, 'AgencyID': LoginAgencyID, 'FBIID': FBIID, 'RMSCFSCodeList': RMSCFSCodeList, 'Location': Location
    //         }
    //         try {
    //             const res = await fetchPostData('Report/GetData_MasterReport', val);
    //             if (res.length > 0) {
    //                 setIncidentData(res[0].Incident);
    //                 setMasterReportData(res[0]);
    //                 getpPropertyImg(masterPropertyID, propertyID)
    //                 getAgencyImg(LoginAgencyID)
    //                 setSearchValue(value);
    //                 setLoder(false);
    //             } else {
    //                 toastifyError("Data Not Available");
    //                 setIncidentData([]);
    //                 setLoder(false);

    //             }
    //         } catch (error) {
    //             toastifyError("Data Not Available");
    //             setverifyIncident(false);
    //             setLoder(false);
    //         }
    //     } else {
    //         toastifyError("Please Enter Details");
    //         setLoder(false);
    //     }
    // }
    // const getIncidentSearchData = async () => {
    //     if (value?.IncidentNumber?.trim()?.length > 0 || value?.ReportedDate?.trim()?.length > 0 || value?.ReportedDateTo?.trim()?.length > 0 || value?.OccurredFrom?.trim()?.length > 0 || value?.OccurredTo?.trim()?.length > 0 || value?.IncidentNumberTo?.trim()?.length > 0 || value?.FBIID !== null || value?.RMSCFSCodeList !== null || value?.Location?.trim()?.length > 0) {
    //         const { IncidentNumber, IncidentNumberTo, ReportedDate, ReportedDateTo, OccurredFrom, OccurredTo, AgencyID, FBIID, RMSCFSCodeList, Location } = value
    //         const val = {
    //             'IncidentNumber': IncidentNumber, 'IncidentNumberTo': IncidentNumberTo, 'ReportedDate': ReportedDate, 'ReportedDateTo': ReportedDateTo, 'OccurredFrom': OccurredFrom, 'OccurredTo': OccurredTo, 'AgencyID': LoginAgencyID, 'FBIID': FBIID, 'RMSCFSCodeList': RMSCFSCodeList, 'Location': Location
    //         }
    //         // console.log(val)
    //         fetchPostData('Report/GetData_MasterReport', val).then((res) => {
    //             if (res.length > 0) {


    //                 setIncidentData(res[0].Incident);
    //                 setMasterReportData(res[0]);
    //                 getpPropertyImg(masterPropertyID, propertyID)
    //                 getAgencyImg(LoginAgencyID)
    //                 setSearchValue(value);

    //             } else {
    //                 toastifyError("Data Not Available");
    //                 setIncidentData([]);
    //                 setverifyIncident(false);
    //             }
    //         });
    //     } else {
    //         toastifyError("Please Enter Details");
    //     }
    // }

    const handleChange = (e) => {
        if (e.target.name === 'IncidentNumber' || e.target.name === 'IncidentNumberTo') {
            var ele = e.target.value.replace(/[^a-zA-Z\s^0-9\s]/g, '');
            if (ele.length === 8) {
                var cleaned = ('' + ele).replace(/[^a-zA-Z\s^0-9\s]/g, '');
                var match = cleaned.match(/^(\d{2})(\d{6})$/);
                if (match) {
                    // console.log(match)
                    setValue({
                        ...value,
                        [e.target.name]: match[1] + '-' + match[2]
                    })
                }
            } else {
                ele = e.target.value.split("'").join('').replace(/[^0-9\s]/g, '');
                setValue({
                    ...value,
                    [e.target.name]: ele
                })
                if (ele?.length == 0) { e.target.name == 'IncidentNumber' && setValue({ ...value, ['IncidentNumberTo']: "", [e.target.name]: ele }) }
            }
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    }

    const ChangeDropDown = (e, name) => {
        if (e) {
            if (name === 'FBIID') {
                getRMSCFSCodeList(LoginAgencyID,e.value)
                setValue({
                    ...value,
                    [name]: e.value,
                    ['RMSCFSCodeList']: "",
                })
            } else {
                setValue({
                    ...value,
                    [name]: e.value
                })
            }
        } else {
            if (name === 'FBIID') {
                setRmsCfsID([]);
                setValue({
                    ...value,
                    ['FBIID']: "",
                    ['RMSCFSCodeList']: "",
                })
            } else {
                setValue({
                    ...value,
                    [name]: null
                })
            }
        }
    }

    const resetFields = () => {
        setLocationStatus(true);
        setValue({
            ...value,
            'IncidentNumber': "", 'IncidentNumberTo': "", 'ReportedDate': "",
            'ReportedDateTo': "", 'OccurredFrom': "", 'OccurredTo': "", 'Location': "", 'FBIID': null, 'RMSCFSCodeList': null,
        });
        setUpdateStatus(updateStatus + 1);
    }

    const columns = [
        {
            width: '140px',
            name: 'Incident',
            selector: (row) => row.IncidentNumber,
            sortable: true
        },
        {
            width: '180px',
            name: 'Occured To',
            selector: (row) => row.OccurredTo ? getShowingMonthDateYear(row.OccurredTo) : " ",
        },
        {
            width: '180px',
            name: 'Report Date/Time',
            selector: (row) => row.ReportedDate ? getShowingMonthDateYear(row.ReportedDate) : " ",
            sortable: true
        },
        {
            width: '140px',
            name: 'RMS CFS',
            selector: (row) => <>{row?.RMSCFSCode_Description ? row?.RMSCFSCode_Description.substring(0, 20) : ''}{row?.RMSCFSCode_Description?.length > 40 ? '  . . .' : null} </>,
            sortable: true
        },
        {
            name: 'Location',
            selector: (row) => <>{row?.CrimeLocation ? row?.CrimeLocation.substring(0, 20) : ''}{row?.CrimeLocation?.length > 20 ? '  . . .' : null} </>,
            sortable: true
        },
    ]

    const componentRef = useRef();

    // const printForm = useReactToPrint({
    //     content: () => componentRef.current,
    //     onBeforeGetContent: () => {
    //         setLoder(true);
    //     },
    //     documentTitle: 'Data',
    //     // onAfterPrint: () => { '' }
    //     onAfterPrint: () => {
    //         setLoder(false);
    //     },
    // }
    // )
    // const printForm = useReactToPrint({
    //     content: () => {
    //         setLoder(true); 
    //         return componentRef.current;
    //     },
    //     documentTitle: 'Data',
    //     onAfterPrint: () => {
    //         setLoder(false); 
    //     }
    // });
    const printForm = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Data',
        onBeforeGetContent: () => {
            setLoder(true);
        },
        onAfterPrint: () => {
            setLoder(false);
        }
    });
    // const handlePrint = () => {
    //     printForm();
    // };
    const startRef = React.useRef();
    const startRef1 = React.useRef();
    const startRef2 = React.useRef();
    const startRef3 = React.useRef();
    const startRef4 = React.useRef();
    const startRef5 = React.useRef();
    const startRef6 = React.useRef();
    const startRef7 = React.useRef();
    const startRef8 = React.useRef();

    const onKeyDown = (e) => {
        if (e.keyCode === 9 || e.which === 9) {
            startRef.current.setOpen(false);
            startRef1.current.setOpen(false);
            startRef2.current.setOpen(false);
            startRef3.current.setOpen(false);
            startRef4.current.setOpen(false);
            startRef5.current.setOpen(false);
            startRef6.current.setOpen(false);
            startRef7.current.setOpen(false);
            startRef8.current.setOpen(false);
        }
    };

    const getAgencyImg = (LoginAgencyID) => {
        const val = { 'AgencyID': LoginAgencyID }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setMultiImage(imgUrl);
            }
            else { console.log("errror") }
        })
    }

    const getpPropertyImg = (propertyID, masterPropertyID) => {
        const val = { 'PropertyID': propertyID, 'MasterPropertyID': masterPropertyID, }
        fetchPostData('Agency/GetDataAgencyPhoto', val).then((res) => {
            if (res) {
                let imgUrl = `data:image/png;base64,${res[0]?.Agency_Photo}`;
                setPropertyPhoto(imgUrl);
            }
            else { console.log("errror") }
        })
    }

    return (
        <>
            <div class="section-body view_page_design pt-1">
                <div className="row clearfix">
                    <div className="col-12 col-sm-12">
                        <div className="card Agency">
                            <div className="card-body">
                                <fieldset>
                                    <legend>Incident Master Report</legend>
                                    <div className="row">
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Incident # From</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='IncidentNumber' maxLength={9} id='IncidentNumber' value={value.IncidentNumber} onChange={handleChange} className='' />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Incident # To</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1 ">
                                            <input type="text" name='IncidentNumberTo'
                                                // disabled={!value.IncidentNumber}
                                                disabled={!value?.IncidentNumber}
                                                className={!value?.IncidentNumber ? 'readonlyColor' : ''}
                                                maxLength={9} id='IncidentNumberTo' value={value.IncidentNumberTo} onChange={handleChange} />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Reported From Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                name='ReportedDate'
                                                id='ReportedDate'
                                                ref={startRef}
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => {
                                                    if (date) {
                                                        setValue({ ...value, ['ReportedDate']: date ? getShowingDateText(date) : null })
                                                    } else {
                                                        setValue({ ...value, ['ReportedDate']: null, ['ReportedDateTo']: null })
                                                    }
                                                }}
                                                selected={value?.ReportedDate && new Date(value?.ReportedDate)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                isClearable={value?.ReportedDate ? true : false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                // disabled
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Reported To Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                name='ReportedDateTo'
                                                id='ReportedDateTo'
                                                onChange={(date) => { setValue({ ...value, ['ReportedDateTo']: date ? getShowingDateText(date) : null }) }}
                                                selected={value?.ReportedDateTo && new Date(value?.ReportedDateTo)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                ref={startRef1}
                                                onKeyDown={onKeyDown}
                                                isClearable={value?.ReportedDateTo ? true : false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                // disabled={value?.ReportedDate ? false : true}
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                                minDate={new Date(value?.ReportedDate)}
                                                disabled={value?.ReportedDate ? false : true}
                                                className={!value?.ReportedDate && 'readonlyColor'}

                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Occurred From Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                name='OccurredFrom'
                                                id='OccurredFrom'
                                                ref={startRef2}
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => {
                                                    if (date) {
                                                        setValue({ ...value, ['OccurredFrom']: date ? getShowingDateText(date) : null })
                                                    } else {
                                                        setValue({ ...value, ['OccurredFrom']: null, ['OccurredTo']: null })
                                                    }
                                                }}
                                                selected={value?.OccurredFrom && new Date(value?.OccurredFrom)}
                                                dateFormat="MM/dd/yyyy"
                                                timeInputLabel
                                                isClearable={value?.OccurredFrom ? true : false}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                // disabled
                                                maxDate={new Date()}
                                                placeholderText='Select...'
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'>Occurred To Date</label>
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-3">
                                            <DatePicker
                                                id='OccurredTo'
                                                name='OccurredTo'
                                                ref={startRef3}
                                                onKeyDown={onKeyDown}
                                                onChange={(date) => { setValue({ ...value, ['OccurredTo']: date ? getShowingMonthDateYear(date) : null }) }}
                                                dateFormat="MM/dd/yyyy"
                                                isClearable={value?.OccurredTo ? true : false}
                                                // disabled={value?.OccurredFrom ? false : true}
                                                selected={value?.OccurredTo && new Date(value?.OccurredTo)}
                                                minDate={new Date(value?.OccurredFrom)}
                                                maxDate={new Date()}
                                                placeholderText={'Select...'}
                                                showDisabledMonthNavigation
                                                autoComplete="off"
                                                showYearDropdown
                                                showMonthDropdown
                                                dropdownMode="select"
                                                disabled={value?.OccurredFrom ? false : true}
                                                className={!value?.OccurredFrom && 'readonlyColor'}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'> NIBRS Code/Name</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-3 mt-1">
                                            <Select
                                                name='FBIID'
                                                value={fbiCodesDrpData?.filter((obj) => obj.value === value?.FBIID)}
                                                isClearable
                                                options={fbiCodesDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'FBIID')}
                                                placeholder="Select..."
                                                styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-4 mt-2 ">
                                            <label htmlFor="" className='new-label'> Offense Code</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-3 mt-1">
                                            <Select
                                                name='RMSCFSCodeList'
                                                styles={colourStyles}
                                                value={rmsCfsID?.filter((obj) => obj.value === value?.RMSCFSCodeList)}
                                                isClearable
                                                options={rmsCfsID}
                                                onChange={(e) => ChangeDropDown(e, 'RMSCFSCodeList')}
                                                placeholder="Select..."
                                                isDisabled={!value?.FBIID}
                                                className={!value?.FBIID ? 'readonlyColor' : ''}
                                            />
                                        </div>
                                        <div className="col-3 col-md-3 col-lg-2 mt-2 ">
                                            <label htmlFor="" className='new-label'>Location</label>
                                        </div>
                                        <div className="col-9 col-md-9 col-lg-10 mt-1 text-field">
                                            <input type="text" name='Location' value={value?.Location} onChange={handleChange} id='Location' className='' />

                                            {/* <Location {...{ setValue, value, locationStatus, setLocationStatus, updateStatus, setOnSelectLocation }} col='Location' locationID='crimelocationid' check={true} verify={true} /> */}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div className="col-12 col-md-12 col-lg-12 mt-1 text-right mb-1">
                                <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { getIncidentSearchData(); }}>Show Report</button>
                                <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" onClick={() => { setverifyIncident(false); setIncidentData([]); resetFields(); }}>Clear</button>
                                <Link to={`${reportedData.length > 0 ? '/incidenttab' : '/Reports'}`}>
                                    <button className="btn btn-sm bg-green text-white px-2 py-1 ml-2" >Close</button>
                                </Link>
                            </div>
                            {/* <DataTable
                                        columns={columns}
                                        dense
                                        data={incidentData}
                                        pagination
                                        paginationPerPage={'10'}
                                        paginationRowsPerPageOptions={[10, 15, 20]}
                                        highlightOnHover
                                        subHeader
                                        responsive
                                        showPaginationBottom={10}
                                        subHeaderAlign='left'
                                    /> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* for 1 table */}
            {
                verifyIncident ?

                    incidentData?.length > 0 ?
                        <>
                            <div className="col-12 col-md-12 col-lg-12 pt-2  px-2" >
                                <div className="bg-line  py-1 px-2 mt-1 d-flex justify-content-between align-items-center">
                                    <p className="p-0 m-0 d-flex align-items-center">Incident Master Report</p>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Link to={''} className="btn btn-sm bg-green  mr-2 text-white px-2 py-0"  >
                                            <i className="fa fa-print" onClick={printForm}></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="container mt-1" ref={componentRef} >
                                <div className="row" >
                                    <>
                                        <div className="col-4 col-md-3 col-lg-2 pt-1 ml-3">
                                            {/* <div className="main">
                                                    <img src={masterReportData?.Agency_Photo} alt='Agency_Photo' className="img-fluid" style={{ border: '1px solid aliceblue', marginTop: '4px', width: '150px' }} />
                                                </div> */}
                                            <div className="img-box " >
                                                <img src={multiImage} className='' style={{ marginTop: '4px', width: '150px', height: '150px' }} />
                                            </div>

                                        </div>
                                        {/* <div className="col-7  col-md-7 col-lg-9 mt-2">
                                            <div className="main">
                                                <h5 className='text-dark text-bold'>{masterReportData?.Agency_Name}</h5>
                                                <p className='text-p'>Address: <span className='text-address'>{masterReportData?.Agency_Address1}</span></p>
                                                <div className='d-flex '>
                                                    <p className='text-p'>City: <span className='text-gray  ml-4'>{masterReportData?.CityName}</span>
                                                    </p>
                                                    <p className='text-p  ml-5 pl-4'>State: <span className='text-gray '>{masterReportData?.StateName}</span>
                                                    </p>
                                                    <p className='text-p ml-2'>Zip: <span className='text-gray  '>{masterReportData?.Zipcode}</span>
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='text-p'>Phone: <span className='text-gray ml-1 '>{masterReportData?.Agency_Phone}</span></p>
                                                    <p className='text-p ml-5 '>Fax: <span className='text-gray  '> {masterReportData?.Agency_Fax}</span></p>
                                                </div>

                                            </div>
                                        </div> */}
                                        <div className="col-7 col-md-7 col-lg-9 mt-2">
                                            <div className="main">
                                                <h5 className='text-dark font-weight-bold'>{masterReportData?.Agency_Name}</h5>
                                                <p className='text-p'>Address: <span className='text-address'>{masterReportData?.Agency_Address1}</span></p>
                                                <div className='d-flex justify-content-start flex-wrap'>
                                                    <p className='text-p'>City: <span className='text-gray ml-2'>{masterReportData?.CityName}</span></p>
                                                    <p className='text-p mb-1 ml-3'>State: <span className='text-gray'>{masterReportData?.StateName}</span></p>
                                                    <p className='text-p mb-1 ml-3'>Zip: <span className='text-gray'>{masterReportData?.Zipcode}</span></p>
                                                </div>
                                                <div className='d-flex justify-content-start flex-wrap'>
                                                    <p className='text-p mb-1'>Phone: <span className='text-gray ml-1'>{masterReportData?.Agency_Phone}</span></p>
                                                    <p className='text-p mb-1 ml-4'>Fax: <span className='text-gray'>{masterReportData?.Agency_Fax}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    <div className="col-12">
                                        <hr style={{ border: '1px solid rgb(3, 105, 184)' }} />
                                        <h5 className="text-center text-white text-bold bg-green  py-1" >Master Incident Report</h5>

                                    </div>
                                    <div className="col-12 bb">
                                        <fieldset>
                                            <legend>Search Criteria</legend>

                                            <div className="row">
                                                {showFields.showIncidentNumber && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Incident Number</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.IncidentNumber || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}

                                                {showFields.showIncidentNumberTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Incident Number To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.IncidentNumberTo || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showReportedDateFrom && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Reported Date From</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor'
                                                                value={searchValue.ReportedDate && getShowingWithOutTime(searchValue.ReportedDate)}
                                                                readOnly />
                                                            {/* <input type="text" className='readonlyColor' value={searchValue.ReportedDate ? getShowingWithOutTime(searchValue.ReportedDate) : ''} readOnly /> */}
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showReportedDateTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Reported Date To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor'
                                                                //  value={searchValue.ReportedDateTo || ''}
                                                                value={searchValue.ReportedDateTo && getShowingWithOutTime(searchValue.ReportedDateTo)}
                                                                readOnly />
                                                            {/* <input type="text" className='readonlyColor' value={searchValue.ReportedDateTo ? getShowingWithOutTime(searchValue.ReportedDateTo) : ''} readOnly /> */}
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showOccurredFrom && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Occurred Date From </label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.OccurredFrom ? getShowingWithOutTime(searchValue.OccurredFrom) : ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showOccurredTo && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Occurred Date To</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.OccurredTo ? getShowingWithOutTime(searchValue.OccurredTo) : ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showLocation && (
                                                    <>
                                                        <div className="col-3 col-md-3 col-lg-2 mt-2">
                                                            <label className='new-label'>Location</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-2 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={searchValue.Location || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}

                                                {showFields.showFBIID && searchValue.FBIID && (
                                                    <>
                                                        <div className="col-1 col-md-1 col-lg-1 mt-2">
                                                            <label className='new-label'>NIBRS</label>
                                                        </div>
                                                        <div className="col-3 col-md-3 col-lg-3 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={fbiCodesDrpData.find((obj) => obj.value === searchValue.FBIID)?.label || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                                {showFields.showRMSCFSCodeList && searchValue.RMSCFSCodeList && (
                                                    <>
                                                        <div className="col-2 col-md-2 col-lg-2 mt-2">
                                                            <label className='new-label'>Offense Code</label>
                                                        </div>
                                                        <div className="col-6 col-md-6 col-lg-6 text-field mt-1">
                                                            <input type="text" className='readonlyColor' value={rmsCfsID.find((obj) => obj.value === searchValue.RMSCFSCodeList)?.label || ''} readOnly />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </fieldset>
                                    </div>
                                    {
                                        masterReportData?.Incident?.map((obj) =>
                                            <>
                                                <div className="container" style={{ pageBreakAfter: 'always' }}>
                                                    <h5 className=" text-white text-bold bg-green py-1 px-3"> Incident Number:- {obj.IncidentNumber}</h5>

                                                    {/* <div className="col-12  ">
                                                        <div className="row px-3">
                                                            <div className="col-2 col-md-2 col-lg-2 mt-1">
                                                                <label htmlFor="" className='new-summary'>Incident Number:</label>
                                                            </div>
                                                            <div className="col-10 col-md-10 col-lg-10 mt-1">
                                                                <label htmlFor="" className='text-gray'>{obj.IncidentNumber}</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* type of report */}
                                                    {/* <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        <div className="container bb">
                                                            <div className="col-12 mb-2">
                                                                <p className='new-summary'>Type Of Report</p>
                                                                <div className="row" >
                                                                    <div className="col-4 col-md-4 col-lg-4">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input " type="checkbox" name='IsJuvenileArrest' id="flexCheckDefault"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                Offense Report
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 col-lg-4">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input " type="checkbox" name='IsJuvenileArrest' id="flexCheckDefault1"
                                                                            />
                                                                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                                                Supplement Report
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 col-md-4 col-lg-3">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input " type="checkbox" name='IsJuvenileArrest' id="flexCheckDefault2"
                                                                                checked />
                                                                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                                                                                Incident Report
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* incident */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        <div className="container bb">
                                                            <div className="col-12 mb-2">
                                                                <h6 className=' text-dark mt-2'>Incident Information</h6>
                                                                <div className="row px-3" >
                                                                    <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.ReportedDate ? getShowingDateText(obj.ReportedDate) : ''}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>Reported Date</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2"></div>
                                                                    <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.OccurredFrom ? getShowingDateText(obj.OccurredFrom) : ''}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>Occurred From</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-1"></div>
                                                                    <div className="col-3 col-md-3 col-lg-3 mt-2 ">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.OccurredTo ? getShowingDateText(obj.OccurredTo) : ''}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>Occurred To</label>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.FBI_Description}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>FBI Code</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2"></div>
                                                                    <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.RMSCFSCode_Description}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>RMS CFS Description</label>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.RMS_Disposition}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>RMS Disposition</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-2"></div>
                                                                    <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.DispositionDate ? getShowingDateText(obj.DispositionDate) : ''}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>Disposition Date</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                        <div className='text-field'>
                                                                            <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                value={obj.CrimeLocation}
                                                                            />
                                                                            <label htmlFor="" className='new-summary'>Crime Location</label>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>Reported Date:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.ReportedDate ? getShowingDateText(obj.ReportedDate) : ''}</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-1 mt-1">
                                                                        <label htmlFor="" className='new-summary'>Occurred&nbsp;From:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                                        <label htmlFor="" className='text-gray'>{obj.OccurredFrom ? getShowingDateText(obj.OccurredFrom) : ''}</label>
                                                                    </div> */}
                                                                    {/* <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>Occurred&nbsp;To:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                                        <label htmlFor="" className='text-gray'>{obj.OccurredTo ? getShowingDateText(obj.OccurredTo) : ''}</label>
                                                                    </div> */}
                                                                    {/* <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>RMS Disposition:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-6 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.RMS_Disposition}</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>Disposition Date:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                                        <label htmlFor="" className='text-gray'>{obj.DispositionDate ? getShowingDateText(obj.DispositionDate) : ''}</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>CAD CFS Description:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.CADCFSCode_Description}</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>RMS CFS Description:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.RMSCFSCode_Description}</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>Crime Location:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.CrimeLocation}</label>
                                                                    </div>

                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>FBI Code:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-6 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.FBI_Description}</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='new-summary'>CAD Disposition:</label>
                                                                    </div>
                                                                    <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                        <label htmlFor="" className='text-gray'>{obj.CADDispositions_Description}</label>
                                                                    </div> */}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Narrative */}
                                                    {/* <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Narrative)?.length > 0 ?
                                                                <>
                                                                    {
                                                                        JSON.parse(obj?.Narrative)?.map((item, key) => (
                                                                            <div className="container bb">
                                                                                <div className="col-12">
                                                                                    <h6 className=' text-dark mt-2'>Narrative Information</h6>
                                                                                    <div className="row  mb-2 px-3" >
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-1">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.AsOfDate ? getShowingDateText(obj.AsOfDate) : ''}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>As Of Date</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-2"></div>
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-2">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.Description}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>Type</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.ReportedBy}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>Reported By</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-2"></div>
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-4 ">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.Comments}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>Comments</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div> */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Narrative)?.length > 0 ?
                                                                <>
                                                                    <div className="container bb">
                                                                        <h6 className=' text-dark mt-2'>Narrative Information</h6>
                                                                        <div className="col-12 ">
                                                                            {
                                                                                JSON.parse(obj?.Narrative)?.map((item, key) => (
                                                                                    <div className="row bb px-3 ">
                                                                                        <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                                                                                            <div className="col-10 col-md-10 col-lg-10 mt-4 ">
                                                                                                <div className=" text-field">
                                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                        value={item?.PropertyNumber}
                                                                                                    />
                                                                                                    <label htmlFor="" className='new-summary'>Report No.</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-10 mb-2">
                                                                                            <div className="row ">
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.AsOfDate ? getShowingDateText(obj.AsOfDate) : ''}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>As Of Date</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Type</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-1"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1  ">
                                                                                                    <div className=" text-field">
                                                                                                        <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                            value={item.ReportedBy}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Reported By</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-md-12 col-lg-12 mt-4">
                                                                                                    <div>
                                                                                                        <label htmlFor="" className='new-summary'>Comments</label>
                                                                                                        <div
                                                                                                            className="readonlyColor text-justify"
                                                                                                            style={{
                                                                                                                border: '1px solid #ccc',
                                                                                                                borderRadius: '4px',
                                                                                                                padding: '10px',
                                                                                                                minHeight: '100px',
                                                                                                                backgroundColor: '#f9f9f9', // Light background for readability
                                                                                                                overflowY: 'auto', // Allows scrolling if content overflows
                                                                                                            }}
                                                                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.CommentsDoc) }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div >

                                                    {/* offense */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Offence)?.length > 0 ?
                                                                <>
                                                                    {
                                                                        JSON.parse(obj?.Offence)?.map((item, key) => (
                                                                            <div className="container bb">
                                                                                <div className="col-12">
                                                                                    <h6 className=' text-dark mt-2'>Offense Information</h6>
                                                                                    <div className="row  mb-2 px-3" >
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-1">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.FBIID_Description}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>NIBRS Code</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-2"></div>
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-2">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.OffenseName_Description}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>Offense Code/Name</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-4">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.PrimaryLocation}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>Primary Location</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-2"></div>
                                                                                        <div className="col-5 col-md-5 col-lg-5 mt-4 ">
                                                                                            <div className='text-field'>
                                                                                                <input type="text" className='readonlyColor' name='DocFileName' required readOnly
                                                                                                    value={item.SecondaryLocation}
                                                                                                />
                                                                                                <label htmlFor="" className='new-summary'>Secondary Location</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                                                                            {
                                                                                                item.AttemptComplete == "A" ?
                                                                                                    <div className="form-check  ">
                                                                                                        <input className="form-check-input mt-1" type="checkbox" checked={true} name="AttemptComplete" id="flexRadioDefault1" />
                                                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                                            Attempted
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="form-check  ">
                                                                                                        <input className="form-check-input mt-1" type="checkbox" value={false} checked={false} onChange={(e) => { e.preventDefault(); }} name="AttemptComplete" id="flexRadioDefault1" />
                                                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                                            Attempted
                                                                                                        </label>
                                                                                                    </div>
                                                                                            }
                                                                                            {/* <div className="form-check ">
                                                                                                {
                                                                                                    item.AttemptComplete === "A" ?
                                                                                                        <input className="form-check-input mt-1" type="radio" value="A" checked={item.AttemptComplete === "A"} name="AttemptComplete" id="flexRadioDefault1" />
                                                                                                        :
                                                                                                        <input className="form-check-input mt-1" type="radio" value="A" name="AttemptComplete" id="flexRadioDefault1" />
                                                                                                }
                                                                                                <input className="form-check-input mt-1" type="radio" name="AttemptComplete" value={"A"} checked={item.AttemptComplete === "A"} id="flexRadioDefault1" />
                                                                                                <label className="form-check-label " htmlFor="flexRadioDefault1" >
                                                                                                    Attempted
                                                                                                </label>
                                                                                            </div> */}
                                                                                        </div>
                                                                                        <div className="col-2 col-md-2 col-lg-2 mt-2 pt-1">
                                                                                            {
                                                                                                item.AttemptComplete === "C" ?
                                                                                                    <div className="form-check  ">
                                                                                                        <input className="form-check-input mt-1" type="checkbox" checked={true} name="AttemptComplete" id="flexRadioDefault2" />
                                                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                                            Completed
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="form-check  ">
                                                                                                        <input className="form-check-input mt-1" type="checkbox" value={false} checked={false} onChange={(e) => { e.preventDefault(); }} name="AttemptComplete" id="flexRadioDefault2" />
                                                                                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                                            Completed
                                                                                                        </label>
                                                                                                    </div>
                                                                                            }
                                                                                            {/* <div className="form-check  ">
                                                                                                {
                                                                                                    item.AttemptComplete === "C" ?
                                                                                                        <input className="form-check-input mt-1" type="radio" value="C" checked={item.AttemptComplete === "C"} name="AttemptComplete" id="flexRadioDefault2" />
                                                                                                        :
                                                                                                        <input className="form-check-input mt-1" type="radio" value="C" name="AttemptComplete" id="flexRadioDefault2" />
                                                                                                }
                                                                                                <input className="form-check-input" type="radio" name="AttemptComplete" value={"C"} checked={item.AttemptComplete === "A"}  id="flexRadioDefault2" />
                                                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                                                    Completed
                                                                                                </label>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    {/* name */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Name)?.length > 0 ?
                                                                <>
                                                                    <div className="container bb">
                                                                        <h6 className=' text-dark mt-2'>Name Information Person</h6>

                                                                        <div className="col-12 mb-2 ">
                                                                            {
                                                                                JSON.parse(obj?.Name).filter(item => item.NameType_Description !== 'Business')
                                                                                    .map((item, key) => (

                                                                                        // (JSON.parse(obj?.Name) || [])
                                                                                        // .filter(item => item.NameType_Description !== 'Business')
                                                                                        // .map((item, key) => {
                                                                                        <div className="row px-3 bb">
                                                                                            <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                                                                                                <div className="col-10 col-md-10 col-lg-10 mt-4 ">
                                                                                                    <div className=" text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.NameIDNumber}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>MNI:</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-10 mb-2">
                                                                                                <div className="row ">
                                                                                                    <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.LastName && item?.FirstName && item?.MiddleName ?
                                                                                                                    item?.LastName + '  ' + item?.FirstName + '  ' + item?.MiddleName
                                                                                                                    :
                                                                                                                    item?.LastName && item?.FirstName ?
                                                                                                                        item?.LastName + '  ' + item?.FirstName
                                                                                                                        :
                                                                                                                        item?.LastName && item?.MiddleName ?
                                                                                                                            item?.LastName + '  ' + item?.MiddleName
                                                                                                                            :
                                                                                                                            item?.LastName ?
                                                                                                                                item?.LastName
                                                                                                                                :
                                                                                                                                ""}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Name:</label>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-1"></div>
                                                                                                    <div className="col-6 col-md-6 col-lg-6 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.NameType_Description}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Name Type</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-md-12 col-lg-12 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Address}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Address</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Suffix_Description}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Suffix</label>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Gender_Description}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Gender</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-1"></div>

                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.DateOfBirth && getShowingWithOutTime(item?.DateOfBirth)}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>DOB</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.SSN}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>SSN</label>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.DLNumber}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>DL Number</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-5 col-md-5 col-lg-5 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.NameReasonCode_Description}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Reason Code</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-1"></div>
                                                                                                    <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Hair_Color}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Hair Color</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Eye_Color}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Eye Color</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-4 col-md-4 col-lg-4 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Contact}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Contact</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.HeightFrom}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Height From</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.HeightTo}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Height To</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.WeightFrom}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Weight From</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-2 col-md-2 col-lg-2 mt-4 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.WeightTo}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Weight To</label>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {/* <div className="col-4 col-md-3 col-lg-2">
                                                                                                    <div className="img-box">
                                                                                                        {item.NamePhoto && item.NamePhoto !== "[]" ? (
                                                                                                            JSON.parse(item.NamePhoto).map((photo, index) => (
                                                                                                                <img key={index} src={photo.Path} className='picture' style={{ marginTop: '4px', width: '150px' }} />
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            null
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                    <div className="container bb">
                                                                                                        {/* <tbody className=''>
                                                                                                                <div className="row">

                                                                                                                    {item.NamePhoto ? (
                                                                                                                        JSON.parse(item.NamePhoto).map((photo, index) => (
                                                                                                                            <img
                                                                                                                                key={index} src={photo.Path} className=' mr-1'
                                                                                                                                alt={`${index + 1}`}
                                                                                                                            />
                                                                                                                        ))
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </tbody> */}
                                                                                                        <div className="col-12">
                                                                                                            <div className="row">

                                                                                                                {item.NamePhoto ? (
                                                                                                                    JSON.parse(item.NamePhoto).map((photo, index) => (
                                                                                                                        <div className="col-3 mb-1 mt-1 "  key={index}>
                                                                                                                            <img
                                                                                                                                src={photo.Path} className=' mr-1'
                                                                                                                                alt={`${index + 1}`}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                ) : null}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div >
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Name)?.length > 0 ?
                                                                <>
                                                                    <div className="container bb">

                                                                        <div className="col-12 mb-2 ">
                                                                            {
                                                                                JSON.parse(obj?.Name).filter(item => item.NameType_Description === 'Business')
                                                                                    .map((item, key) => (

                                                                                        // (JSON.parse(obj?.Name) || [])
                                                                                        // .filter(item => item.NameType_Description !== 'Business')
                                                                                        // .map((item, key) => {
                                                                                        <div className="row px-3 bb">
                                                                                            <h6 className=' text-dark mt-2 col-12'>Name Information Business</h6>
                                                                                            <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                                                                                                <div className="col-10 col-md-10 col-lg-10 mt-4 ">
                                                                                                    <div className=" text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.NameIDNumber}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>MNI:</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-10 mb-2">
                                                                                                <div className="row ">
                                                                                                    <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.LastName && item?.FirstName && item?.MiddleName ?
                                                                                                                    item?.LastName + '  ' + item?.FirstName + '  ' + item?.MiddleName
                                                                                                                    :
                                                                                                                    item?.LastName && item?.FirstName ?
                                                                                                                        item?.LastName + '  ' + item?.FirstName
                                                                                                                        :
                                                                                                                        item?.LastName && item?.MiddleName ?
                                                                                                                            item?.LastName + '  ' + item?.MiddleName
                                                                                                                            :
                                                                                                                            item?.LastName ?
                                                                                                                                item?.LastName
                                                                                                                                :
                                                                                                                                ""}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Business Name:</label>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-1"></div>
                                                                                                    <div className="col-6 col-md-6 col-lg-6 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.NameType_Description}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Name Type</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-6 col-md-6 col-lg-6 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.OwnerName}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Owner Name</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-6 col-md-6 col-lg-6 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.OwnerPhoneNumber}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Owner Phone Number</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-6 col-md-6 col-lg-6 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.OwnerFaxNumber}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Business Fax Number</label>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-6 col-md-6 col-lg-6 pt-1 mt-2 ">
                                                                                                        <div className="text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Contact}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Contact</label>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                    <div className="col-12 col-md-12 col-lg-12 mt-2 pt-1  ">
                                                                                                        <div className=" text-field">
                                                                                                            <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                                value={item?.Address}
                                                                                                            />
                                                                                                            <label htmlFor="" className='new-summary'>Address</label>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="container bb">
                                                                                                        {/* <div className="col-12 mb-2">
                                                                                                            <tbody className=''>
                                                                                                                <div className="row"> */}

                                                                                                        {/* {item.NamePhoto ? (
                                                                                                            JSON.parse(item.NamePhoto).map((photo, index) => (
                                                                                                                <img
                                                                                                                    key={index} src={photo.Path} className=' mr-1'
                                                                                                                    alt={`${index + 1}`}
                                                                                                                />
                                                                                                            ))
                                                                                                        ) : null} */}
                                                                                                        <div className="col-12">
                                                                                                            <div className="row">

                                                                                                                {item.NamePhoto ? (
                                                                                                                    JSON.parse(item.NamePhoto).map((photo, index) => (
                                                                                                                        <div className="col-3 mb-1 mt-1 "       key={index}>
                                                                                                                            <img
                                                                                                                           src={photo.Path} className=' mr-1'
                                                                                                                                alt={`${index + 1}`}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                ) : null}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* </div> */}
                                                                                                        {/* </tbody> */}
                                                                                                    </div>
                                                                                                </div>



                                                                                                {/* </div> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div >
                                                    {/* property */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Property)?.length > 0 ?
                                                                <>
                                                                    <div className="container bb">
                                                                        <h6 className=' text-dark mt-2'>Property Information</h6>
                                                                        <div className="col-12 ">
                                                                            {
                                                                                JSON.parse(obj?.Property)?.map((item, key) => (
                                                                                    <div className="row bb px-3 ">
                                                                                        <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                                                                                            <div className="col-10 col-md-10 col-lg-10 mt-4 ">
                                                                                                <div className=" text-field">
                                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                        value={item?.PropertyNumber}
                                                                                                    />
                                                                                                    <label htmlFor="" className='new-summary'>Property No.</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-10 mb-2">
                                                                                            <div className="row ">
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.PropertyType_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Property Type</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.PropertyClassification_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Property Classification</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-1"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1  ">
                                                                                                    <div className=" text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Officer_Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Officer Name</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.ReportedDtTm && getShowingDateText(item.ReportedDtTm)}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Reported Date/Time</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-3 col-md-3 col-lg-3 mt-4 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Value}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Value</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-1"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-4 ">
                                                                                                    <div className=" text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.PropertyLossCode_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Loss Code</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <div className="col-4 col-md-3 col-lg-2">
                                                                                                    <div className="img-box">
                                                                                                        {item.PropertyPhoto ? (
                                                                                                            JSON.parse(item.PropertyPhoto).map((photo, index) => (
                                                                                                                <img key={index} src={photo.Path} className='picture' style={{ marginTop: '4px', width: '150px' }} />
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            null
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                <div className="container bb">
                                                                                                    {/* <div className="col-12 mb-2">
                                                                                                        <tbody className=''>
                                                                                                            <div className="row ">

                                                                                                                {item.PropertyPhoto ? (
                                                                                                                    JSON.parse(item.PropertyPhoto).map((photo, index) => (
                                                                                                                        <img
                                                                                                                            key={index} src={photo.Path} className=' mr-1'
                                                                                                                            alt={`${index + 1}`}
                                                                                                                        />
                                                                                                                    ))
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </tbody>
                                                                                                    </div> */}

                                                                                                    <div className="col-12">
                                                                                                        <div className="row">

                                                                                                            {item.PropertyPhoto ? (
                                                                                                                JSON.parse(item.PropertyPhoto).map((photo, index) => (
                                                                                                                    <div className="col-3 mb-1 mt-1 "       key={index}>
                                                                                                                        <img
                                                                                                                            src={photo.Path} className=' mr-1'
                                                                                                                            alt={`${index + 1}`}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            ) : null}

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div >
                                                    {/* Arrest */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.Arrest)?.length > 0 ?
                                                                <>
                                                                    <div className="container bb">
                                                                        <h6 className=' text-dark mt-2'>Arrest Information</h6>

                                                                        <div className="col-12 mb-2">
                                                                            {
                                                                                JSON.parse(obj?.Arrest)?.map((item, key) => (
                                                                                    <div className="row bb px-3">
                                                                                        <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                                                                                            <div className="col-12 col-md-12 col-lg-12 mt-4 ">
                                                                                                <div className=" text-field">
                                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                        value={item?.ArrestNumber}
                                                                                                    />
                                                                                                    <label htmlFor="" className='new-summary'>Arrest No.</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-10 mb-2">
                                                                                            <div className="row ">
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Agency_Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Agency Name</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Arrestee_Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Arrestee Name</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.ArrestDtTm && getShowingDateText(item.ArrestDtTm)}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Arrest Date/Time</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Supervisor_Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Supervisor Name</label>

                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="container bb">
                                                                                                    {/* <div className="col-12 mb-2">
                                                                                                        <tbody className=''>
                                                                                                            <div className="row ">

                                                                                                                {item.ArrestPhoto ? (
                                                                                                                    JSON.parse(item.ArrestPhoto).map((photo, index) => (
                                                                                                                        <img
                                                                                                                            key={index} src={photo.Path} className=' mr-1'
                                                                                                                            alt={`${index + 1}`}
                                                                                                                        />
                                                                                                                    ))
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </tbody>
                                                                                                    </div> */}

                                                                                                    <div className="col-12">
                                                                                                        <div className="row">

                                                                                                            {item.ArrestPhoto ? (
                                                                                                                JSON.parse(item.ArrestPhoto).map((photo, index) => (
                                                                                                                    <div className="col-3 mb-1 mt-1 "       key={index}>
                                                                                                                        <img
                                                                                                                           src={photo.Path} className=' mr-1'
                                                                                                                            alt={`${index + 1}`}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            ) : null}

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <div className="row">
                                                                                                    {item.ArrestPhoto ? (
                                                                                                        JSON.parse(item.ArrestPhoto).map((photo, index) => (
                                                                                                            <div className="col-4 col-md-4 col-lg-4">
                                                                                                                <div className="img-box">
                                                                                                                    <img key={index} src={photo.Path} className='picture' style={{ marginTop: '4px', width: '100px', height: '100px' }} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        null
                                                                                                    )}
                                                                                                </div> */}
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div >
                                                    {/* Vehicle */}
                                                    <div className="col-12  " style={{ border: '1px solid #80808085', }}>
                                                        {
                                                            JSON.parse(obj?.PropertyVehicle)?.length > 0 ?
                                                                <>
                                                                    <div className="container bb">
                                                                        <h6 className=' text-dark mt-2'>Vehicle Information</h6>
                                                                        <div className="col-12 mb-2">
                                                                            {
                                                                                JSON.parse(obj?.PropertyVehicle
                                                                                )?.map((item, key) => (
                                                                                    <div className="row bb px-3">
                                                                                        <div className="col-2" style={{ borderRight: '1px solid gray' }}>
                                                                                            <div className="col-10 col-md-10 col-lg-10 mt-4 ">
                                                                                                <div className=" text-field">
                                                                                                    <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                        value={item?.VehicleNumber}
                                                                                                    />
                                                                                                    <label htmlFor="" className='new-summary'>Vehicle No.</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-10 mb-2">
                                                                                            <div className="row ">
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Category_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Property Category</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-2 pt-1 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.PropertyClassification_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Property Classification</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-4 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item?.Officer_Name}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Officer Name</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-4 ">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.ReportedDtTm && getShowingDateText(item.ReportedDtTm)}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Reported Date/Time</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.Value}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Value</label>

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.StatePlateNumber}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Plate State & No.</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.VIN}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>VIN</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.Make_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Make</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.Model_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Model</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.style_Desc}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Style</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.PrimaryColor_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Primary Color</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-2"></div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.SecondaryColor_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Secondary Color</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="col-5 col-md-5 col-lg-5 mt-3">
                                                                                                    <div className="text-field">
                                                                                                        <input type="text" className='readonlyColor' name='' required readOnly
                                                                                                            value={item.LossCode_Description}
                                                                                                        />
                                                                                                        <label htmlFor="" className='new-summary'>Loss Code</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className="col-4 col-md-3 col-lg-2">
                                                                                                <div className="img-box">
                                                                                                    {item.VehiclePhoto ? (
                                                                                                        JSON.parse(item.VehiclePhoto).map((photo, index) => (
                                                                                                            <img key={index} src={photo.Path} className='picture' style={{ marginTop: '4px', width: '150px' }} />
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        null
                                                                                                    )}
                                                                                                </div>
                                                                                            </div> */}
                                                                                            <div className="container bb">
                                                                                                {/* <div className="col-12 mb-2">
                                                                                                    <tbody className=''>
                                                                                                        <div className="row">

                                                                                                            {item.VehiclePhoto ? (
                                                                                                                JSON.parse(item.VehiclePhoto).map((photo, index) => (
                                                                                                                    <img
                                                                                                                        key={index} src={photo.Path} className=' mr-1'
                                                                                                                        alt={`${index + 1}`}
                                                                                                                    />
                                                                                                                ))
                                                                                                            ) : null}
                                                                                                        </div>
                                                                                                    </tbody>
                                                                                                </div> */}

                                                                                                <div className="col-12">
                                                                                                    <div className="row">

                                                                                                        {item.VehiclePhoto ? (
                                                                                                            JSON.parse(item.VehiclePhoto).map((photo, index) => (
                                                                                                                <div className="col-3 mb-1 mt-1 "  key={index}>
                                                                                                                    <img
                                                                                                                        src={photo.Path} className=' mr-1'
                                                                                                                        alt={`${index + 1}`}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : null}

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>

                                                                                ))
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div >
                                                    {/* photo */}
                                                    {/* <div className="col-12">
                                                            {
                                                                JSON.parse(obj?.Path)?.length > 0 ? (
                                                                    <Accordion activeIndex={0}>
                                                                        <AccordionTab header="Property Image">
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    {
                                                                                        JSON.parse(obj?.Path)?.length > 0 ?
                                                                                            JSON.parse(obj?.Path)?.map((item, index) => {
                                                                                                return (
                                                                                                    <div className="col-3">
                                                                                                        <div className="main" key={index}>
                                                                                                            <img src={item.Path} style={{ height: '200px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <>
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </AccordionTab>
                                                                    </Accordion>
                                                                ) : null}
                                                        </div> */}
                                                    <div className="conatiner  mb-1">
                                                        {/* <div className="row px-3">  
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>Incident Number:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.IncidentNumber}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>Reported Date:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-3 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.ReportedDate ? getShowingDateText(obj.ReportedDate) : ''}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-1 mt-1">
                                                                    <label htmlFor="" className='new-summary'>Occurred&nbsp;From:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                                    <label htmlFor="" className='text-gray'>{obj.OccurredFrom ? getShowingDateText(obj.OccurredFrom) : ''}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>Occurred&nbsp;To:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                                    <label htmlFor="" className='text-gray'>{obj.OccurredTo ? getShowingDateText(obj.OccurredTo) : ''}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>RMS Disposition:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-6 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.RMS_Disposition}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>Disposition Date:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1 ">
                                                                    <label htmlFor="" className='text-gray'>{obj.DispositionDate ? getShowingDateText(obj.DispositionDate) : ''}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>CAD CFS Description:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.CADCFSCode_Description}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>RMS CFS Description:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.RMSCFSCode_Description}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>Crime Location:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.CrimeLocation}</label>
                                                                </div>

                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>FBI Code:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-6 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.FBI_Description}</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='new-summary'>CAD Disposition:</label>
                                                                </div>
                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                    <label htmlFor="" className='text-gray'>{obj.CADDispositions_Description}</label>
                                                                </div>
                                                            </div> */}
                                                    </div>
                                                    {/* </div> */}
                                                    {/* Offence */}
                                                    {/* {
                                                        JSON.parse(obj?.Offence)?.length > 0 ?
                                                            <>
                                                                <div className="container">
                                                                    <h5 className=" text-white text-bold bg-green py-1 px-3" > Offense:</h5>
                                                                    <div className="col-12">
                                                                        <div className="table-responsive">
                                                                            <table className="table ">

                                                                                <thead className='text-dark master-table'>
                                                                                    <tr>
                                                                                        <th className=''>NIBRS Code</th>
                                                                                        <th className=''>Offense Code/Name</th>
                                                                                        <th className=''>Damage Property</th>
                                                                                        <th className=''>Primary Location
                                                                                        </th>
                                                                                        <th className=''>Offender Left Scene </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='master-tbody'>
                                                                                    {
                                                                                        JSON.parse(obj?.Offence)?.map((item, key) => (
                                                                                            <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                <td>{item.FBIID_Description}</td>
                                                                                                <td>{item.OffenseName_Description}</td>
                                                                                                <td>{item.DamageProperty}</td>
                                                                                                <td>{item.PrimaryLocation}</td>
                                                                                                <td>{item.OffenseLeftScene_Description}</td>
                                                                                            </tr>

                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    } */}
                                                    {/* Name */}
                                                    {/* {
                                                        JSON.parse(obj?.Name)?.length > 0 ?
                                                            <>
                                                                <div className="container">
                                                                    <h5 className="text-white text-bold bg-green  py-1 px-3">Name Details:</h5>
                                                                    <div className="table-responsive">
                                                                        <table className="table ">
                                                                            <thead className='text-dark master-table'>
                                                                                <div className="col-12 col-md-12 col-lg-12">
                                                                                    <div className="row">
                                                                                        {
                                                                                            JSON.parse(obj?.Name)?.map((item, key) => (
                                                                                                <>
                                                                                                    <tr style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                    
                                                                                                        <div className="col-12 col-md-12 col-lg-12">
                                                                                                            <div className="row">
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Name Number:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-10 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.NameIDNumber}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Name Type:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.NameType_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Name:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className=''> {
                                                                                                                        item?.LastName && item?.FirstName && item?.MiddleName ?
                                                                                                                            <span className='text-gray '>{item?.LastName + '  ' + item?.FirstName + '  ' + item?.MiddleName}</span>
                                                                                                                            :
                                                                                                                            item?.LastName && item?.FirstName ?
                                                                                                                                <span className='text-gray '>{item?.LastName + '  ' + item?.FirstName}</span>
                                                                                                                                :
                                                                                                                                item?.LastName && item?.MiddleName ?
                                                                                                                                    <span className='text-gray '>{item?.LastName + '  ' + item?.MiddleName}</span>
                                                                                                                                    :
                                                                                                                                    item?.LastName ?
                                                                                                                                        <span className='text-gray '>{item?.LastName}</span>
                                                                                                                                        :
                                                                                                                                        <></>
                                                                                                                    }</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Reason Code:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.NameReasonCode_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Suffix:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Suffix_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Gender:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Gender_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>DOB:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.DateOfBirth && getShowingWithOutTime(item?.DateOfBirth)}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Race:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Race_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>SSN:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.SSN}</label>
                                                                                                                </div>

                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Phone Type:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.PhoneType_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Contact:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Contact}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Resident:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Resident_Description}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Address:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Address}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Hair Color:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Hair_Color}</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-2 mt-1">
                                                                                                                    <label htmlFor="" className='new-summary'>Eye Color:</label>
                                                                                                                </div>
                                                                                                                <div className="col-3 col-md-3 col-lg-4 mt-1">
                                                                                                                    <label htmlFor="" className='text-gray'>{item?.Eye_Color}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </div >
                                                                                </div>
                                                                            </thead>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    } */}
                                                    {/* Property */}
                                                    {/* {
                                                            JSON.parse(obj?.Property)?.length > 0 ?
                                                                <>
                                                                    <div className="container">
                                                                        <h5 className="text-white text-bold bg-green py-1 px-3" >Property</h5>
                                                                        <div className="col-12">
                                                                            <div className="table-responsive">
                                                                                <table className="table ">
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Property Number</th>
                                                                                            <th className=''>Property Type</th>
                                                                                            <th className=''>Property Classification</th>
                                                                                            <th className=''>Reported Date</th>
                                                                                            <th className=''>Value</th>
                                                                                            <th className=''>Officer Name</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Property)?.map((item, key) => (
                                                                                                <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                    <td>{item.PropertyNumber}</td>
                                                                                                    <td>{item.PropertyType_Description}</td>
                                                                                                    <td>{item.PropertyClassification_Description}</td>
                                                                                                    <td>{item.ReportedDtTm && getShowingDateText(item.ReportedDtTm)}</td>
                                                                                                    <td>{item.Value}</td>
                                                                                                    <td>{item.Officer_Name}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        } */}
                                                    {/* Arrest */}
                                                    {/* {
                                                            JSON.parse(obj?.Arrest)?.length > 0 ?
                                                                <>
                                                                    <div className="container">
                                                                        <h5 className="text-white text-bold bg-green py-1 px-3" >Arrest</h5>
                                                                        <div className="col-12">
                                                                            <div className="table-responsive">
                                                                                <table className="table ">
                                                                                    <thead className='text-dark master-table'>
                                                                                        <tr>
                                                                                            <th className=''>Agency Name</th>
                                                                                            <th className=''>Arrest Number</th>
                                                                                            <th className=''>Arrest Date Time</th>
                                                                                            <th className=''>Arrestee Name</th>
                                                                                            <th className=''>Supervisor Name</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody className='master-tbody'>
                                                                                        {
                                                                                            JSON.parse(obj?.Arrest)?.map((item, key) => (
                                                                                                <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                    <td>{item.Agency_Name}</td>
                                                                                                    <td>{item.ArrestNumber}</td>
                                                                                                    <td>{item.ArrestDtTm && getShowingDateText(item.ArrestDtTm)}</td>
                                                                                                    <td>{item.Arrestee_Name}</td>
                                                                                                    <td>{item.Supervisor_Name}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <></>
                                                        } */}
                                                    {/* Warrant */}
                                                    {/* {
                                                        JSON.parse(obj?.Warrant)?.length > 0 ?
                                                            <>
                                                                <div className="container">
                                                                    <h5 className="text-white text-bold bg-green py-1 px-3" >Warrant</h5>
                                                                    <div className="col-12">
                                                                        <div className="table-responsive">
                                                                            <table className="table ">
                                                                                <thead className='text-dark master-table'>
                                                                                    <tr>
                                                                                        <th className=''>Warrant Number</th>
                                                                                        <th className=''>Warrant Type</th>
                                                                                        <th className=''>Warrant Classification</th>
                                                                                        <th className=''>Warrant Name</th>
                                                                                        <th className=''>Warrant Status</th>
                                                                                        <th className=''>Date Of Complain</th>
                                                                                        <th className=''>Date/Time Issued</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='master-tbody'>
                                                                                    {
                                                                                        JSON.parse(obj?.Warrant)?.map((item, key) => (
                                                                                            <tr key={key} style={{ borderBottom: '0.2px solid gray' }}>
                                                                                                <td>{item?.WarrantNumber}</td>
                                                                                                <td>{item?.WarrantType_Description}</td>
                                                                                                <td>{item?.WarrantClassification_Description}</td>
                                                                                                <td>{item?.WarrantName}</td>
                                                                                                <td>{item?.WarrantStatus_Description}</td>
                                                                                                <td>{item?.DateOfComplain && getShowingDateText(item?.DateOfComplain)}</td>
                                                                                                <td>{item?.DateTimeIssued && getShowingDateText(item?.DateTimeIssued)}</td>

                                                                                            </tr>

                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            </>
                                                    } */}
                                                </div>


                                            </>
                                        )
                                    }

                                </div>
                            </div>
                        </>
                        :
                        <>
                        </>
                    :
                    <>
                    </>
            }
            {/* {loder && <Loader />} */}
            {loder && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}

        </>
    )
}

export default MasterIncident