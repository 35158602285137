import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Decrypt_Id_Name, filterPassedDateTime, getShowingDateText, getShowingMonthDateYear, tableCustomStyles } from '../../../../Common/Utility';
import { fetchPostData, AddDeleteUpadate } from '../../../../hooks/Api';
import DataTable from 'react-data-table-component';
import { toastifyError, toastifySuccess } from '../../../../Common/AlertMsg';
import DeletePopUpModal from '../../../../Common/DeleteModal';
import { AgencyContext } from '../../../../../Context/Agency/Index';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Comman_changeArrayFormat } from '../../../../Common/ChangeArrayFormat';
import { RequiredFieldIncident, Space_NotAllow, } from '../../../Utility/Personnel/Validation';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { get_LocalStoreData } from '../../../../../redux/actions/Agency';
import { get_ScreenPermissions_Data } from '../../../../../redux/actions/IncidentAction';
import ChangesModal from '../../../../Common/ChangesModal';
import { get_AgencyOfficer_Data } from '../../../../../redux/actions/DropDownsData';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const Narrative = (props) => {

  const { DecArrestId } = props
  const dispatch = useDispatch();
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
  const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);
  const incReportedDate = useSelector((state) => state.Agency.incReportedDate);
  const agencyOfficerDrpData = useSelector((state) => state.DropDown.agencyOfficerDrpData);

  const { get_Arrest_Count, get_LocalStorage, setChangesStatus } = useContext(AgencyContext)
  const [clickedRow, setClickedRow] = useState(null);
  const [narrativeData, setNarrativeData] = useState([])
  const [upDateCount, setUpDateCount] = useState(0)
  const [status, setStatus] = useState(false)
  const [modal, setModal] = useState(false);
  const [arrestNarrativeID, setArrestNarrativeID] = useState('')
  const [loder, setLoder] = useState(false)
  //screen permission 

  const [arrestID, setArrestID] = useState('');
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID,] = useState('');
  const [editval, setEditval] = useState();
  const [narrativeDtTm, setNarrativeDtTm] = useState()
  const [narrativeTypeList, setNarrativeTypeList] = useState([])
  const [statesChangeStatus, setStatesChangeStatus] = useState(false);

  const [value, setValue] = useState({
    'CommentsDoc': '', 'NarrativeComments': '', 'NarrativeDtTm': '', 'NarrativeTypeID': '', 'ReportedByID': '', 'ModifiedByUserFK': '',
    'CreatedByUserFK': '', 'ArrestID': '', 'ArrestNarrativeID': '',
  })

  useEffect(() => {
    if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
      if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
    }
  }, []);

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(parseInt(localStoreData?.PINID)); setLoginAgencyID(parseInt(localStoreData?.AgencyID));
      dispatch(get_ScreenPermissions_Data("A072", localStoreData?.AgencyID, localStoreData?.PINID));
    }
  }, [localStoreData]);

  useEffect(() => {
    if (loginPinID) {
      setValue({
        ...value,
        'CommentsDoc': '', 'NarrativeComments': '', 'NarrativeDtTm': '', 'NarrativeTypeID': '', 'ReportedByID': loginPinID, 'ModifiedByUserFK': '', 'ArrestNarrativeID': '',
        'ArrestID': '', 'CreatedByUserFK': loginPinID,
      });
      get_Narrative_Type(loginAgencyID);
      if (agencyOfficerDrpData?.length === 0) dispatch(get_AgencyOfficer_Data(loginAgencyID));
    }
  }, [loginPinID]);

  useEffect(() => {
    if (DecArrestId) { get_NarrativesData(DecArrestId); setArrestID(DecArrestId); }
  }, [DecArrestId]);

  const get_NarrativesData = (ArrestID) => {
    const val = { 'ArrestID': ArrestID }
    fetchPostData('ArrestNarrative/GetData_ArrestNarrative', val)
      .then(res => {
        if (res) { setNarrativeData(res); setLoder(true) }
        else { setNarrativeData([]); setLoder(true) }
      })
  }

  const columns = [
    {
      name: 'Date',
      selector: (row) => getShowingDateText(row.NarrativeDtTm),
      sortable: true
    },
    {
      name: 'Narrative',
      selector: (row) => row?.NarrativeComments || '',
      format: (row) => (
        <>{row?.NarrativeComments ? row?.NarrativeComments.substring(0, 70) : ''}{row?.NarrativeComments?.length > 40 ? '  . . .' : null} </>
      ),
      // selector: (row) => <>{row?.NarrativeComments ? row?.NarrativeComments?.trim()?.substring(0, 50) : ''}{row?.NarrativeComments?.trim()?.length > 40 ? '  . . .' : null} </>,
      sortable: true
    },
    {
      name: 'Reported By', selector: (row) => row.ReportedBy_Description, sortable: true
    },
    {
      name: 'Type', selector: (row) => row.NarrativeDescription, sortable: true
    },
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px', right: '10px' }}>Delete</p>,
      cell: row =>
        <div style={{ position: 'absolute', top: 4, right: 5 }}>

          {
            effectiveScreenPermission ? effectiveScreenPermission[0]?.DeleteOK ?
              <span to={`#`} onClick={(e) => setArrestNarrativeID(row.ArrestNarrativeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
              : <></>
              : <span to={`#`} onClick={(e) => setArrestNarrativeID(row.ArrestNarrativeID)} className="btn btn-sm bg-green text-white px-1 py-0 mr-1" data-toggle="modal" data-target="#DeleteModal">
                <i className="fa fa-trash"></i>
              </span>
          }
        </div>

    }
  ]

  const [errors, setErrors] = useState({
    'NarrativeDtTmError': '', 'ArrestNarrativeIDError': '', 'NarrativeCommentsError': '', 'ReportedByIDError': '',
  })

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  // useEffect(() => {
  //   if (arrestNarrativeID && status) {
  //     GetSingleData(arrestNarrativeID)
  //   }
  // }, [upDateCount, arrestNarrativeID])

  const GetSingleData = (arrestNarrativeID) => {
    const val = { 'ArrestNarrativeID': arrestNarrativeID }
    fetchPostData('ArrestNarrative/GetSingleData_ArrestNarrative', val).then((res) => {
      if (res) { setEditval(res) }
      else { setEditval() }
    })
  }

  useEffect(() => {
    if (status) {
      setValue({
        ...value,
        "ArrestNarrativeID": arrestNarrativeID, 'NarrativeDtTm': editval[0].NarrativeDtTm ? getShowingDateText(editval[0].NarrativeDtTm) : '',
        'NarrativeTypeID': editval[0].NarrativeTypeID, 'ReportedByID': editval[0].ReportedByID, 'CreatedByUserFK': editval[0].CreatedByUserFK,
        'NarrativeComments': editval[0].NarrativeComments, 'ModifiedByUserFK': loginPinID, 'CommentsDoc': editval[0].CommentsDoc,
      });
      if (editval[0].CommentsDoc?.trim()) {
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(editval[0].CommentsDoc ? editval[0].CommentsDoc?.trim().replace(/\\/g, '') : <p></p>))));
      }
      setNarrativeDtTm(editval[0]?.narrativeDtTm ? new Date(editval[0]?.narrativeDtTm) : null);
    } else {
      setValue({
        ...value,
        'NarrativeComments': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'ArrestNarrativeID': '', 'NarrativeDtTm': '', 'NarrativeTypeID': '', 'ReportedByID': loginPinID, 'CreatedByUserFK': loginPinID,
      });
      setEditorState(() => EditorState.createEmpty(),);
    }
  }, [editval])

  const get_Narrative_Type = (loginAgencyID) => {
    const val = { AgencyID: loginAgencyID }
    fetchPostData('NarrativeType/GetDataDropDown_NarrativeType', val)
      .then((res) => {
        if (res) { setNarrativeTypeList(Comman_changeArrayFormat(res, 'NarrativeTypeID', 'Description')) }
        else { setNarrativeTypeList([]) }
      })
  }

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") { reset() }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const ChangeDropDown = (e, name) => {
    if (e) {
      setValue({ ...value, [name]: e.value })
      setChangesStatus(true); setStatesChangeStatus(true)
    } else {
      setValue({ ...value, [name]: null })
      setChangesStatus(true); setStatesChangeStatus(true)
    }
  }

  const handleEditorChange = (state) => {
    setEditorState(state); convertContentToHTML(state);
  }

  const convertContentToHTML = (state) => {
    let currentContentAsHTML = draftToHtml(convertToRaw(state.getCurrentContent()));
    setValue({ ...value, 'CommentsDoc': currentContentAsHTML })
  }

  // const getValueNarrative = (e) => {
  //   setChangesStatus(true); setStatesChangeStatus(true)
  //   for (let key in e.blocks) {
  //     if (e.blocks[key]?.text) { setValue({ ...value, ['NarrativeComments']: e.blocks[key]?.text }) }
  //   }
  // }

  const getValueNarrative = (e) => {
    setChangesStatus(true); setStatesChangeStatus(true)
    for (let key in e.blocks) {
      let combinedText = '';
      if (e.blocks[key]?.text) {
        combinedText += e.blocks[key].text + ' ';
      }
      setValue({ ...value, ['NarrativeComments']: combinedText })
    }
  }

  const reset = (e) => {
    setValue({
      ...value, 'NarrativeTypeID': '', 'NarrativeComments': '', 'NarrativeDtTm': '', 'CommentsDoc': '', 'ModifiedByUserFK': '', 'ArrestNarrativeID': '',
      'headOfAgencyName': '', 'ReportedByID': loginPinID,
    });
    setErrors({
      ...errors,
      'ReportedByPinError': '', 'NarrativeDtTmError': '', 'ArrestNarrativeIDError': '', 'NarrativeCommentsError': '', 'ReportedByIDError': '',
    });
    setNarrativeDtTm(''); setEditorState(() => EditorState.createEmpty(),);
  }

  const check_Validation_Error = (e) => {
    if (RequiredFieldIncident(value.NarrativeDtTm)) {
      setErrors(prevValues => { return { ...prevValues, ['NarrativeDtTmError']: RequiredFieldIncident(value.NarrativeDtTm) } })
    }
    if (RequiredFieldIncident(value.NarrativeTypeID)) {
      setErrors(prevValues => { return { ...prevValues, ['ArrestNarrativeIDError']: RequiredFieldIncident(value.NarrativeTypeID) } })
    }
    if (Space_NotAllow(value.NarrativeComments)) {
      setErrors(prevValues => { return { ...prevValues, ['NarrativeCommentsError']: Space_NotAllow(value.NarrativeComments) } })
    }
    if (RequiredFieldIncident(value.ReportedByID)) {
      setErrors(prevValues => { return { ...prevValues, ['ReportedByIDError']: RequiredFieldIncident(value.ReportedByID) } })
    }
  }

  // Check All Field Format is True Then Submit 
  const { NarrativeDtTmError, ArrestNarrativeIDError, NarrativeCommentsError, ReportedByIDError } = errors

  useEffect(() => {
    if (NarrativeDtTmError === 'true' && ArrestNarrativeIDError === 'true' && NarrativeCommentsError === 'true' && ReportedByIDError === 'true') {
      if (status) { updateNarrative() }
      else { submit() }
    }
  }, [NarrativeDtTmError, ArrestNarrativeIDError, NarrativeCommentsError, ReportedByIDError])

  const submit = () => {
    const result = narrativeData?.find(item => {
      if (item.NarrativeComments) {
        if (item.NarrativeComments.toLowerCase() === value.NarrativeComments.toLowerCase()) {
          return item.NarrativeComments.toLowerCase() === value.NarrativeComments.toLowerCase()
        } else return item.NarrativeComments.toLowerCase() === value.NarrativeComments.toLowerCase()
      }
    }
    );
    if (result) {
      toastifyError('Comments Already Exists')
      setErrors({ ...errors, ['NarrativeCommentsError']: '' })
    } else {
      const { CommentsDoc, NarrativeComments, NarrativeDtTm, NarrativeTypeID, ReportedByID, ModifiedByUserFK,
        ArrestNarrativeID, ArrestID, CreatedByUserFK } = value;
      const val = {
        'CommentsDoc': CommentsDoc, 'NarrativeComments': NarrativeComments, 'NarrativeDtTm': NarrativeDtTm, 'NarrativeTypeID': NarrativeTypeID, 'ReportedByID': ReportedByID,
        'ArrestNarrativeID': ArrestNarrativeID, 'ArrestID': DecArrestId, 'CreatedByUserFK': loginPinID, 'ModifiedByUserFK': loginPinID,
      }
      AddDeleteUpadate('ArrestNarrative/Insert_Narrative', val).then((res) => {
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message); get_Arrest_Count(arrestID); setModal(false); setChangesStatus(false);
        get_NarrativesData(arrestID);; reset(); setStatesChangeStatus(false)
        setErrors({ ['NarrativeDtTmError']: '', })
      })
    }
  }

  const updateNarrative = (e) => {
    const result = narrativeData?.find(item => {
      if (item.NarrativeComments) {
        if (item.ArrestNarrativeID != value.ArrestNarrativeID) {
          if (item.NarrativeComments.toLowerCase() === value.NarrativeComments.toLowerCase()) {
            return item.NarrativeComments.toLowerCase() === value.NarrativeComments.toLowerCase()
          } else return item.NarrativeComments.toLowerCase() === value.NarrativeComments.toLowerCase()
        }
      }
    }
    );
    if (result) {
      toastifyError('Code Already Exists')
      setErrors({ ...errors, ['NarrativeCommentsError']: '' })
    } else {
      AddDeleteUpadate('ArrestNarrative/Update_ArrestNarrative', value)
        .then((res) => {
          const parsedData = JSON.parse(res.data);
          const message = parsedData.Table[0].Message;
          toastifySuccess(message); setChangesStatus(false); get_NarrativesData(arrestID);
          setModal(false); setStatusFalse(); reset(); setStatesChangeStatus(false)
          setErrors({ ['NarrativeDtTmError']: '', })
        })
    }
  }

  const DeleteNarratives = () => {
    const val = {
      'ArrestNarrativeID': arrestNarrativeID,
      'DeletedByUserFK': loginPinID,
    }
    AddDeleteUpadate('ArrestNarrative/Delete_ArrestNarrative', val).then((res) => {
      if (res.success) {
        const parsedData = JSON.parse(res.data);
        const message = parsedData.Table[0].Message;
        toastifySuccess(message);
        get_Arrest_Count(arrestID); reset()
        get_NarrativesData(arrestID); setStatus(false);
      } else { console.log("Somthing Wrong"); }
    })
  }

  const startRef = React.useRef();

  const onKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9) {
      startRef.current.setOpen(false);
    }
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      height: 20,
      minHeight: 30,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  }
  const setStatusFalse = (e) => {
    setClickedRow(null); setStatus(false); setModal(true); reset();
  }

  const editNarratives = (row) => {
    setArrestNarrativeID(row.ArrestNarrativeID);
    setUpDateCount(upDateCount + 1); setStatesChangeStatus(false)
    setStatus(true)
    setModal(true); setErrors('')
    GetSingleData(row.ArrestNarrativeID)

  }

  const conditionalRowStyles = [
    {
      when: row => row === clickedRow,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    },
  ];

  return (
    <>
      <div className="row mt-1">
        <div className="col-12 col-md-12 col-lg-12 px-0 pl-0">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            onChange={getValueNarrative}
            editorStyle={{ height: '15vh' }}
            toolbar={{
              options: ['inline', 'blockType', 'fontFamily', 'list', 'textAlign', 'history'],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline', 'monospace',],
              },
              list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['unordered', 'ordered',],

              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['left', 'center', 'right', 'justify'],
              },
            }}
          />
          {errors.NarrativeCommentsError !== 'true' ? (
            <span style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NarrativeCommentsError}</span>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-3">
                <span className='new-label'>
                  Reported By {errors.ReportedByIDError !== 'true' ? (
                    <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ReportedByIDError}</p>
                  ) : null}
                </span>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='ReportedByID'
                  isClearable
                  styles={colourStyles}
                  value={agencyOfficerDrpData?.filter((obj) => obj.value === value?.ReportedByID)}
                  options={agencyOfficerDrpData}
                  onChange={(e) => ChangeDropDown(e, 'ReportedByID')}
                  placeholder="Select.."
                  menuPlacement="top"
                />

              </div>
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Date/Time{errors.NarrativeDtTmError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.NarrativeDtTmError}</p>
                ) : null}</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <DatePicker
                  ref={startRef}
                  onKeyDown={(e) => {
                    if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Delete' || e.key === ':' || e.key === '/' || e.key === ' ')) {
                      e?.preventDefault();
                    } else { onKeyDown(e); }
                  }}
                  dateFormat="MM/dd/yyyy HH:mm"
                  timeFormat="HH:mm "
                  is24Hour
                  timeInputLabel
                  isClearable
                  className='requiredColor'
                  name='NarrativeDtTm'
                  id='NarrativeDtTm'
                  onChange={(date) => {
                    if (date) {
                      if (date >= new Date()) {
                        setNarrativeDtTm(new Date()); setStatesChangeStatus(true); setValue({ ...value, ['NarrativeDtTm']: new Date() ? getShowingMonthDateYear(new Date()) : null })
                      } else if (date <= new Date(incReportedDate)) {
                        setNarrativeDtTm(incReportedDate); setStatesChangeStatus(true); setValue({ ...value, ['NarrativeDtTm']: incReportedDate ? getShowingMonthDateYear(incReportedDate) : null })
                      } else {
                        setNarrativeDtTm(date); setStatesChangeStatus(true); setValue({ ...value, ['NarrativeDtTm']: date ? getShowingMonthDateYear(date) : null })
                      }
                    } else {
                      setNarrativeDtTm(null); setStatesChangeStatus(true); setValue({ ...value, ['NarrativeDtTm']: null })
                    }
                  }}
                  selected={value.NarrativeDtTm ? new Date(value.NarrativeDtTm) : null}
                  placeholderText={'Select...'}
                  showTimeSelect
                  filterTime={(time) => filterPassedDateTime(time, narrativeDtTm, incReportedDate)}
                  timeIntervals={1}
                  dropdownMode="select"
                  timeCaption="Time"
                  popperPlacement="top-end"
                  maxDate={new Date()}
                  minDate={new Date(incReportedDate)}
                  autoComplete='off'
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4 col-md-4 col-lg-4 mt-2 pt-2">
                <label htmlFor="" className='new-label'>Narrative Type/Report Type {errors.ArrestNarrativeIDError !== 'true' ? (
                  <p style={{ color: 'red', fontSize: '13px', margin: '0px', padding: '0px' }}>{errors.ArrestNarrativeIDError}</p>
                ) : null}</label>
              </div>
              <div className="col-7 col-md-7 col-lg-7 mt-2 ">
                <Select
                  name='NarrativeTypeID'
                  isClearable
                  styles={colourStyles}
                  value={narrativeTypeList?.filter((obj) => obj.value === value?.NarrativeTypeID)}
                  options={narrativeTypeList}
                  onChange={(e) => ChangeDropDown(e, 'NarrativeTypeID')}
                  placeholder="Select.."
                  menuPlacement="top"
                />

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 text-right mt-3 ">
        <button type="button" className="btn btn-sm btn-success mr-1 " onClick={() => { setStatusFalse(); }}>New</button>
        {
          status ?
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.Changeok ?
                <button type="button" onClick={() => check_Validation_Error()} disabled={!statesChangeStatus} className="btn btn-sm btn-success pl-2">Update</button>
                :
                <>
                </>
              :
              <button type="button" onClick={() => check_Validation_Error()} disabled={!statesChangeStatus} className="btn btn-sm btn-success pl-2">Update</button>
            :
            effectiveScreenPermission ?
              effectiveScreenPermission[0]?.AddOK ?
                <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2">Save</button>
                :
                <>
                </>
              :
              <button type="button" onClick={() => check_Validation_Error()} className="btn btn-sm btn-success pl-2">Save</button>
        }
      </div>
      <div className="col-12 mt-2">
        <DataTable
          dense
          columns={columns}
          data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? narrativeData : '' : narrativeData}
          selectableRowsHighlight
          highlightOnHover
          onRowClicked={(row) => {
            setClickedRow(row);
            editNarratives(row);
          }}
          fixedHeaderScrollHeight='250px'
          conditionalRowStyles={conditionalRowStyles}
          fixedHeader
          persistTableHead={true}
          customStyles={tableCustomStyles}
          noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
        />
      </div>
      <DeletePopUpModal func={DeleteNarratives} />
      <ChangesModal func={check_Validation_Error} setToReset={setStatusFalse} />
    </>
  )
}
export default Narrative;