import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { AgencyContext } from '../../../Context/Agency/Index';
import { Decrypt_Id_Name, getShowingDateText, getShowingWithOutTime, tableCustomStyles } from '../../Common/Utility';
import { fetchPostData } from '../../hooks/Api';
import Tab from '../../Utility/Tab/Tab';
import ChangesModal from '../../Common/ChangesModal';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { get_ScreenPermissions_Data } from '../../../redux/actions/IncidentAction';
import { get_LocalStoreData } from '../../../redux/actions/Agency';
import { Link, useLocation } from 'react-router-dom';

const Log = ({ url, ParentId, para, scrCode, MstParentId, masterPara, IsMaster }) => {

    const [logData, setLogData] = useState([]);
    const dispatch = useDispatch()
    const uniqueId = sessionStorage.getItem('UniqueUserID') ? Decrypt_Id_Name(sessionStorage.getItem('UniqueUserID'), 'UForUniqueUserID') : '';
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const effectiveScreenPermission = useSelector((state) => state.Incident.effectiveScreenPermission);

    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    const query = useQuery();
    let MstPage = query?.get('page');

    useEffect(() => {
        if (!localStoreData?.AgencyID || !localStoreData?.PINID) {
            if (uniqueId) dispatch(get_LocalStoreData(uniqueId));
        }
    }, []);

    useEffect(() => {
        if (localStoreData) {
            dispatch(get_ScreenPermissions_Data(scrCode, localStoreData?.AgencyID, localStoreData?.PINID));
        }
    }, [localStoreData]);

    // Onload Function
    useEffect(() => {
        if (ParentId || MstParentId) {
            get_LogData(para, url, ParentId, masterPara, MstParentId, IsMaster);
        }
    }, [ParentId, MstParentId])

    const get_LogData = (parameter, url, ParentId, masterPara, MstParentId, IsMaster) => {
        const val = { [parameter]: ParentId, 'IsMaster': IsMaster, }
        const val2 = { [parameter]: 0, [masterPara]: MstParentId, 'IsMaster': IsMaster, }
        fetchPostData(url, MstPage ? val2 : val).then((res) => {
            if (res) {
       
                setLogData(res);
            } else {
                setLogData([]);
            }
        })
    }

    const columns = [
        {
            width: '180px',
            name: 'Column Name',
            selector: (row) => row.ColumnName,
            sortable: true
        },
        {
            width: '140px',
            name: 'Old Value',
            selector: (row) => row.OldValue,
            sortable: true
        },
        {
            width: '180px',
            name: 'New Value',
            selector: (row) => {
                const isDateLike = (value) => {
                    return value && /^\d{4}-\d{2}-\d{2}$/.test(value);
                };
                if (isDateLike(row.NewValue)) {
                    return getShowingWithOutTime(row.NewValue);
                } else {
                    return row.NewValue || " ";
                }
            },
            sortable: true
        },

        {
            name: 'Change Date',
            selector: (row) => row.ChangeDate,
            selector: (row) => row.ChangeDate ? getShowingDateText(row.ChangeDate) : " ",
            sortable: true
        },
        {
            name: 'Officer Name',
            selector: (row) => row.Officer_Name,
            sortable: true
        },
        {
            name: 'Module',
            selector: (row) => row.Module,
            sortable: true
        },
        {
            name: 'Status',
            selector: (row) => row.Status,
            sortable: true
        },
    ]

    return (
        <>
            <div className="col-12 mt-2">
                <DataTable
                    dense
                    columns={columns}
                    data={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? logData : [] : logData}
                    noDataComponent={effectiveScreenPermission ? effectiveScreenPermission[0]?.DisplayOK ? "There are no data to display" : "You don’t have permission to view data" : 'There are no data to display'}
                    // data={logData ? logData : []}
                    selectableRowsHighlight
                    highlightOnHover
                    customStyles={tableCustomStyles}
                    persistTableHead={true}
                    pagination
                    paginationPerPage={'100'}
                    paginationRowsPerPageOptions={[100, 150, 200, 500]}
                    showPaginationBottom={100}
                    fixedHeader
                    fixedHeaderScrollHeight='440px'
                />
            </div>
            <ChangesModal />
        </>
    )
}

export default Log