import React from 'react';
import './index.css';

const TitleCmp = ({ title }) => {
  return (
    <div className='header-Container'>
      <span>{title}</span>
    </div>
  );
}

export default TitleCmp;
