import React, { memo } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../Components/Common/Utility";
import Select from "react-select";
import { colorLessStyle_Select } from "../Utility/CustomStylesForReact";


const BoloSearchModal = (props) => {
    const { openBoloSearchModal, setOpenBoloSearchModal } = props;


    const columns = [
        {
            name: (
                <p className="text-end" style={{ position: "absolute", top: "7px" }}>
                    Action
                </p>
            ),
            cell: (row) => <button className="d-flex justify-content-end btn btn-sm bg-green text-white px-1 py-0 mr-1">
                <i className="fa fa-view"></i>
            </button>,
            selector: (row) => (row.LastName ? row.LastName : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.LastName, rowB.LastName),
        },
        {
            name: "Message",
            selector: (row) => (row.MiddleName ? row.MiddleName : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.MiddleName, rowB.MiddleName),
        },
        {
            name: "Dispatcher",
            selector: (row) => (row.FirstName ? row.FirstName : ""),

            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.FirstName, rowB.FirstName),
        },
        {
            name: "Date/Time",
            selector: (row) => (row.PhoneType ? row.PhoneType : ""),
            sortable: true,
            // sortFunction: (rowA, rowB) => compareStrings(rowA.PhoneType, rowB.PhoneType),
        },

    ];

    const contactList = [];
    return (
        <>
            {openBoloSearchModal ? (
                <dialog
                    className="modal fade modal-cad"
                    style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
                    id="BoloSearchModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    data-backdrop="false"
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content modal-content-cad">
                            <div className="modal-body">
                                {/* Modal Header */}
                                <div className="row pb-2">
                                    <div className="col-12 ">
                                        <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                            <p
                                                className="p-0 m-0 font-weight-medium"
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: 500,
                                                    letterSpacing: 0.5,
                                                }}
                                            >
                                                Search
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Form Area */}
                                <div className="m-1">
                                    <fieldset style={{ border: "1px solid gray" }}>
                                        <div className="tab-form-container">
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Type of BOLO</label>
                                                </div>
                                                <div className="col-4">
                                                    <Select
                                                        styles={colorLessStyle_Select}
                                                        placeholder="Select"
                                                        isSearchable
                                                        getOptionLabel={(i) => i.hospitalstatuscode}
                                                        getOptionValue={(i) => i.HospitalStatusID}
                                                    />
                                                </div>
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Bolo Disposition</label>
                                                </div>
                                                <div className="col-4">
                                                    <Select
                                                        styles={colorLessStyle_Select}
                                                        placeholder="Select"
                                                        isSearchable
                                                        getOptionLabel={(i) => i.hospitalstatuscode}
                                                        getOptionValue={(i) => i.HospitalStatusID}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Zone</label>
                                                </div>
                                                <div className="col-4">
                                                    <Select
                                                        styles={colorLessStyle_Select}
                                                        placeholder="Select"
                                                        isSearchable
                                                        getOptionLabel={(i) => i.hospitalstatuscode}
                                                        getOptionValue={(i) => i.HospitalStatusID}
                                                    />
                                                </div>
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Priority</label>
                                                </div>
                                                <div className="col-4">
                                                    <Select
                                                        styles={colorLessStyle_Select}
                                                        placeholder="Select"
                                                        isSearchable
                                                        getOptionLabel={(i) => i.hospitalstatuscode}
                                                        getOptionValue={(i) => i.HospitalStatusID}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Start Date/Time</label>
                                                </div>
                                                <div className="col-4">
                                                    <input
                                                        type="date"
                                                        className="form-control py-1 new-input"
                                                        placeholder="placeholder"
                                                    />
                                                </div>
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">End Date/Time</label>
                                                </div>
                                                <div className="col-4">
                                                    <input
                                                        type="date"
                                                        className="form-control py-1 new-input"
                                                        placeholder="placeholder"
                                                    />
                                                </div>
                                            </div>

                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 mt-2 text-nowrap">Dispatcher</label>
                                                </div>
                                                <div className="col-4">
                                                    <Select
                                                        styles={colorLessStyle_Select}
                                                        placeholder="Select"
                                                        isSearchable
                                                        getOptionLabel={(i) => i.hospitalstatuscode}
                                                        getOptionValue={(i) => i.HospitalStatusID}
                                                    />
                                                </div>
                                            </div>
                                            <div className="tab-form-row">
                                                <div className="col-1 d-flex justify-content-end">
                                                    <label className="tab-form-label d-flex justify-content-end mr-1 text-nowrap">Message</label>
                                                </div>
                                                <div className="col-11">
                                                    <textarea
                                                        name="comments"
                                                        placeholder="Enter Message"
                                                        rows='4'
                                                        className="form-control py-1 new-input"
                                                        style={{ height: '60px' }}
                                                    />
                                                </div>
                                            </div>
                                            {/* Additional Form Fields */}
                                            <div className="tab-form-row">
                                                <div className="col-3 d-flex justify-content-start offset-1">
                                                    <input type="checkbox" name="question" value="14" class="clickable mr-2" id="2" />
                                                    <label for="2" className="mt-2">Closed</label>
                                                </div>

                                            </div>

                                            {/* Action Buttons */}
                                            <div className="row">
                                                <div className="col-12 p-0">
                                                    <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                                        <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                            <button type="button" className="save-button ml-2">Search</button>
                                                            <button type="button" data-dismiss="modal" className="cancel-button" onClick={() => setOpenBoloSearchModal(false)}>Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                            <div className="d-flex justify-content-end tab-form-row-gap my-1">
                                                <button type="button" className="save-button ml-2"> <i className="fa fa-print" /> Print Previous</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Data Table */}
                                <div className="table-responsive modal-table">
                                    <DataTable
                                        dense
                                        columns={columns}
                                        data={contactList}
                                        customStyles={tableCustomStyles}
                                        pagination
                                        responsive
                                        striped
                                        highlightOnHover
                                        fixedHeader
                                        selectableRowsHighlight
                                        fixedHeaderScrollHeight="190px"
                                        persistTableHead={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </dialog>
            ) : null}
        </>
    );
};

export default memo(BoloSearchModal);
