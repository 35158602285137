import axios from "axios";
class ResourcesStatusServices {

    getIncidents = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/CAD/CallTakerIncident/GetData_Incident_dp`);

    };

    getHospitalNameCode = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalNamecode/InsertMasterHospitalNamecode`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.getHospitalNameCode");
        return null;
    };

    insertHospitalNameCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalNamecode/InsertMasterHospitalNamecode`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.insertHospitalNameCode");
        return null;
    };

    changeStatusHospitalNameCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalNamecode/change_status`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.changeStatusHospitalNameCode");
        return null;
    };

    insertDispatcher = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/Dispatcher/Dispatcher`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.insertDispatcher");
        return null;
    };

    incidentRecourseStatus = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/Enroute/IncidentRecourseStatus`, payload);
        }
        console.warn("payload not provided, ResourcesStatusServices.incidentRecourseStatus");
        return null;
    };

}

const instance = new ResourcesStatusServices();

export default instance;
