import React, { memo } from 'react';
import Vehicle_Add_Up from '../../../../Components/Pages/Vehicle/Vehicle_Add_Up';

const VehicleTabSectionModal = () => {

    return (
        <>
            <Vehicle_Add_Up isCad />
        </>
    );
};

export default memo(VehicleTabSectionModal);
