import React, { memo, useRef, useEffect } from "react";
import {
    useLoadScript,
    GoogleMap,
    useJsApiLoader,
} from "@react-google-maps/api";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { useLocation } from "react-router-dom";
import { SplitAddress } from "../../../Components/Common/SplitAddress";
import { zoneDropDownData } from "../../../CADUtils/constant";
import GeoServices from "../../../CADServices/APIs/geo";

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const libraries = ["places"];

function Location({
    setValue,
    value,
    col = "location",
    setOnSelectLocation = () => { },
    locationStatus,
    setLocationStatus = () => { },
    updateStatus,
    check,
    ref,
    locationID = "",
    verify = true,
    locationList,
    locationData,
    setContactList = () => { },
    setIsSelectLocation = () => { },
    setGeoLocationID = () => { },
    setContactInformation = () => { },
    geoZoneDropDown = [],
    flagDropDown = [],
    premiseDropDown = [],
    otherZoneDropDown = [],
    emsZoneDropDown = [],
    fireZoneDropDown = [],
    setIsChangeFields = () => { },
    isGEO = false,
    setZoom = () => { },
    isDropDown = false,
    setIsEditMode = () => { },
    setIsGoogleLocation = () => { }
}) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
        libraries,
    });
    const [markers, setMarkers] = React.useState([]);
    const mapRef = React.useRef();

    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
        setMarkers((current) => [
            ...current,
            {
                lat,
                lng,
                time: new Date(),
            },
        ]);
    }, []);

    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return (
        <div>
            <Search
                panTo={panTo}
                set={setValue}
                val={value}
                col={col}
                setOnSelectLocation={setOnSelectLocation}
                LoStatus={locationStatus}
                updCount={updateStatus}
                setLoSta={setLocationStatus}
                check={check}
                ref={ref}
                locationID={locationID}
                verify={verify}
                locationList={locationList}
                setContactList={setContactList}
                setGeoLocationID={setGeoLocationID}
                setContactInformation={setContactInformation}
                geoZoneDropDown={geoZoneDropDown}
                flagDropDown={flagDropDown}
                premiseDropDown={premiseDropDown}
                otherZoneDropDown={otherZoneDropDown}
                emsZoneDropDown={emsZoneDropDown}
                fireZoneDropDown={fireZoneDropDown}
                setZoom={setZoom}
                setIsSelectLocation={setIsSelectLocation}
                isGEO={isGEO}
                setIsChangeFields={setIsChangeFields}
                isDropDown={isDropDown}
                locationData={locationData}
                setIsEditMode={setIsEditMode}
                setIsGoogleLocation={setIsGoogleLocation}
            />
        </div>
    );
}

export default memo(Location);

function Search({
    panTo,
    set,
    val,
    col,
    setOnSelectLocation,
    LoStatus,
    updCount,
    setLoSta,
    check = { check },
    ref,
    locationID,
    verify,
    locationList,
    setContactList,
    setGeoLocationID,
    setContactInformation,
    geoZoneDropDown,
    flagDropDown,
    premiseDropDown,
    otherZoneDropDown,
    emsZoneDropDown,
    fireZoneDropDown,
    setZoom,
    setIsSelectLocation,
    isGEO,
    setIsChangeFields,
    isDropDown,
    locationData,
    setIsEditMode,
    setIsGoogleLocation = () => { }
}) {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleMapsApiKey,
    });
    const containerStyle = { width: "800px", height: "200px" };

    const center = { lat: -3.745, lng: -38.523 };

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    let storageRef = useRef(verify);

    useEffect(() => {
        if (!storageRef.current || LoStatus) {
            handleInput(null);
        }
    }, [verify, LoStatus, updCount]);

    const useQuery = () => new URLSearchParams(useLocation().search);
    let openPage = useQuery().get("page");

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            // location: { lat: () => 43.6532, lng: () => -79.3832 },
            // radius: 100 * 1000,
            componentRestrictions: { country: "us" },
            strictBounds: false,
            // types: ["establishment"],
            fields: [
                "route",
                "name",
                "geometry",
                "formatted_address",
                "street_number",
                "geocode",
                "address_components",
                "displayName",
                "formattedAddress",
                "location",
            ],
        },
    });
    useEffect(() => {
        if (val[col]) {
            setValue(val[col], false);
        } else {
            setValue("", false);
            setIsGoogleLocation(true);
        }
    }, [val]);

    const handleInput = (e) => {
        setIsSelectLocation(false)
        const inputValue = e ? e.target.value : "";
        setValue(inputValue);

        if (inputValue === "") {

            set((prevState) => ({
                ...prevState,
                [col]: null,
            }));
        } else {
            setOnSelectLocation(true);
            set((prevState) => ({
                ...prevState,
                [col]: inputValue,
            }));
        }
    };

    useEffect(() => {
        if (openPage === "clear") {
        }
    }, [openPage]);
    useEffect(() => {
        if (!value) {
            set((pre) => ({
                ...pre,
                [col]: "",
                Street: "",
                City: "",
                Country: "",
                PremiseNo: "",
                ZipCode: "",
                TypeSufix: "",
                DirectionSufix: "",
                point_of_interest: "",
                neighborhood: "",
                subpremise: "",
                premise: "",
                coordinateX: "",
                coordinateY: "",
                DirectionPrefix: "",
                State: "",
                ApartmentNo: "",
                CommonPlace: "",
                ApartmentType: "",
                Street_Parse: "",
                PremiseNo_Parse: "",
                DirectionPrefix_Parse: "",
                TypeSuffix_Parse: "",
                DirectionSuffix_Parse: "",
                ZipCodeID: "",
                CityID: "",
                CountryID: "",
                stDirection2: "",
                stDirection: "",
                mileMarker: "",
                intersection1: "",
                intersection2: "",
                AltStreet: "",
                commonPlaceName: "",
                currentFlag: [],
                premiseType: [],
                otherZone: [],
                emsZone: [],
                fireZone: [],
                patrolZone: [],
                isStreet: false,
                isCity: false,
                isPremiseNo: false,
                isZipCode: false,
                isMileMarker: false,
                isCommonPlaceName: false,
                isStDirection: false,
                isStDirection2: false,
                isIntersection1: false,
                isIntersection2: false,
                isAltStreet: false,
                isApartmentNo: false,
                isCoordinateX: false,
                isCoordinateY: false,
                isUpdate: false,
            }));
            if (isGEO) {
                setContactList([]);
                setIsSelectLocation(false)
                setIsChangeFields(false)
                setGeoLocationID("");
                setContactInformation({
                    LastName: "",
                    MiddleName: "",
                    FirstName: "",
                    PhoneType: {},
                    PhoneNo: "",
                });
            }
            setZoom(17);
            setIsEditMode(false)

        }
    }, [value]);

    const handleSelect = async (Location, e) => {
        set((pre) => ({
            ...pre,
            [col]: "",
            Street: "",
            City: "",
            Country: "",
            PremiseNo: "",
            ZipCode: "",
            TypeSufix: "",
            DirectionSufix: "",
            point_of_interest: "",
            neighborhood: "",
            subpremise: "",
            premise: "",
            coordinateX: "",
            coordinateY: "",
            DirectionPrefix: "",
            State: "",
            ApartmentNo: "",
            CommonPlace: "",
            ApartmentType: "",
            Street_Parse: "",
            PremiseNo_Parse: "",
            DirectionPrefix_Parse: "",
            TypeSuffix_Parse: "",
            DirectionSuffix_Parse: "",
            ZipCodeID: "",
            CityID: "",
            CountryID: "",
            stDirection2: "",
            stDirection: "",
            mileMarker: "",
            intersection1: "",
            intersection2: "",
            AltStreet: "",
            commonPlaceName: "",
            currentFlag: [],
            premiseType: [],
            otherZone: [],
            emsZone: [],
            fireZone: [],
            patrolZone: [],
            isStreet: false,
            isCity: false,
            isPremiseNo: false,
            isZipCode: false,
            isMileMarker: false,
            isCommonPlaceName: false,
            isStDirection: false,
            isStDirection2: false,
            isIntersection1: false,
            isIntersection2: false,
            isAltStreet: false,
            isApartmentNo: false,
            isCoordinateX: false,
            isCoordinateY: false,
            isUpdate: false,
        }));
        setContactInformation({
            LastName: "",
            MiddleName: "",
            FirstName: "",
            PhoneType: {},
            PhoneNo: "",
        });
        setIsEditMode(false)
        setContactList([]);
        setIsGoogleLocation(true)
        if (locationData?.length > 0 && isGEO) {
            const data = locationData.find((i) => i.Location === Location);
            const response1 = await GeoServices.getLocationDataByID({
                GeoLocationID: data?.ID,
            });
            const data1 = JSON.parse(response1?.data?.data)?.Table || [];

            if (data1.length > 0) {
                const data = data1[0]
                setIsGoogleLocation(false)
                let contactData = [];

                if (data?.GeoLocationContactsJson) {
                    try {
                        contactData = JSON.parse(data.GeoLocationContactsJson);
                    } catch (error) {
                        contactData = [];
                    }
                }

                setContactList(contactData);
                // setIsChangeFields(true);
                const ID = data?.ID;
                setGeoLocationID(ID);
                set((pre) => {
                    return { ...pre, [col]: Location };
                });
                setValue(Location, false);
                clearSuggestions();

                set((pre) => {
                    return {
                        ...pre,
                        IsUsLocation: "Y",
                        Street: data?.Street,
                        Id: data?.ID,
                        City: data?.City,
                        PremiseNo: data?.PremiseNo,
                        ZipCode: data?.ZipCode,
                        mileMarker: data?.MileMaker || "",
                        commonPlaceName: data?.CommonPlace,
                        stDirection: data?.DirectionPrefix,
                        stDirection2: data?.DirectionSufix,
                        intersection1: data?.InterDirectionPrefix || "",
                        intersection2: data?.InterDirectionSufix || "",
                        AltStreet: data?.AltStreet,
                        ApartmentNo: data?.ApartmentNo,
                        coordinateX: data?.Latitude,
                        coordinateY: data?.Longitude,
                        DirectionPrefix: "",
                        CommonPlace: "",
                        ApartmentType: "",
                        Street_Parse: "",
                        PremiseNo_Parse: "",
                        DirectionPrefix_Parse: "",
                        TypeSuffix_Parse: "",
                        DirectionSuffix_Parse: "",
                        ZipCodeID: "",
                        CityID: "",
                        CountryID: "",
                        currentFlag: flagDropDown?.filter((item) =>
                            data?.CurrentFlage?.includes(item.label)
                        ),
                        premiseType: premiseDropDown?.find(
                            (item) => item?.label === data?.PremiseType
                        ),
                        otherZone: geoZoneDropDown?.find(
                            (item) => item?.label === data?.OtherZone
                        ),
                        emsZone: geoZoneDropDown?.find(
                            (item) => item?.label === data?.EMSZone
                        ),
                        fireZone: geoZoneDropDown?.find(
                            (item) => item?.label === data?.FireZone
                        ),
                        patrolZone: geoZoneDropDown?.find(
                            (item) => item?.label === data?.PatrolZone
                        ),

                        isStreet: data?.Street ? true : false,
                        isCity: data?.City ? true : false,
                        isPremiseNo: data?.PremiseNo ? true : false,
                        isZipCode: data?.ZipCode ? true : false,
                        isMileMarker: data?.MileMaker ? true : false,
                        isCommonPlaceName: data?.CommonPlace ? true : false,
                        isStDirection: data?.DirectionPrefix ? true : false,
                        isStDirection2: data?.DirectionSufix ? true : false,
                        isIntersection1: data?.InterDirectionPrefix ? true : false,
                        isIntersection2: data?.InterDirectionSufix ? true : false,
                        isAltStreet: data?.AltStreet ? true : false,
                        isApartmentNo: data?.ApartmentNo ? true : false,
                        isCoordinateX: data?.Latitude ? true : false,
                        isCoordinateY: data?.Longitude ? true : false,
                        isUpdate: true,
                    };
                });
            }

        } else {
            set((pre) => {
                return { ...pre, [col]: Location };
            });
            setValue(Location, false);
            clearSuggestions();
            saveVerifyLocation({ Location, set, val, col, locationID });
            setIsGoogleLocation(true)
        }
        if (isGEO) {
            setIsSelectLocation(true)
        }
        setOnSelectLocation(false);
    };

    return (
        <>
            <div className="search" style={{ pointerEvents: !verify && "none" }}>
                <Combobox onSelect={handleSelect}>
                    <ComboboxInput
                        style={{ background: check ? "#fce9bf" : "", zIndex: 200 }}
                        value={value}
                        onChange={handleInput}
                        disabled={!ready}
                        placeholder="Search your location"
                    />
                    <ComboboxPopover>
                        {verify ? (
                            <ComboboxList>
                                {status === "OK" && (
                                    <>
                                        {locationData?.length > 0
                                            ? locationData.map(({ ID, Location }) => (
                                                <ComboboxOption key={ID} value={Location}>
                                                    {Location}
                                                </ComboboxOption>
                                            ))
                                            : data?.map(({ id, description }) => (
                                                <ComboboxOption key={id} value={description} />
                                            ))}
                                    </>
                                )}
                            </ComboboxList>
                        ) : (
                            <></>
                        )}
                    </ComboboxPopover>
                </Combobox>
            </div>
        </>
        // <GoogleMap
        //     mapContainerStyle={containerStyle}
        //     center={center}
        //     zoom={10}
        //     onLoad={onLoad}
        //     onUnmount={onUnmount}
        // >
        //     <></>
        // </GoogleMap>
    );
}

const saveVerifyLocation = async ({ Location, set, val, col, locationID }) => {
    let geoApiData = await SplitAddress(Location);
    const LocationGeoCode = geoApiData?.geocode?.location;
    let Add = geoApiData?.address?.addressComponents
        ? geoApiData?.address?.addressComponents
        : [];
    let country = Add?.filter((obj) => {
        if (obj?.componentType === "country") {
            return obj.componentName;
        }
    });
    let City = Add?.filter((obj) => {
        if (obj?.componentType === "locality") {
            return obj.componentName;
        }
    });
    let Street = Add?.filter((obj) => {
        if (obj?.componentType === "route") {
            return obj.componentName;
        }
    });
    let street_number = Add?.filter((obj) => {
        if (obj?.componentType === "street_number") {
            return obj.componentName;
        }
    });
    let sublocality_level_1 = Add?.filter((obj) => {
        if (obj?.componentType === "sublocality_level_1") {
            return obj.componentName;
        }
    });
    let administrative_area_level_1 = Add?.filter((obj) => {
        if (obj?.componentType === "administrative_area_level_1") {
            return obj.componentName;
        }
    });
    let postal_code = Add?.filter((obj) => {
        if (obj?.componentType === "postal_code") {
            return obj.componentName;
        }
    });
    let point_of_interest = Add?.filter((obj) => {
        if (obj?.componentType === "point_of_interest") {
            return obj.componentName;
        }
    });
    let neighborhood = Add?.filter((obj) => {
        if (obj?.componentType === "neighborhood") {
            return obj.componentName;
        }
    });
    let subpremise = Add?.filter((obj) => {
        if (obj?.componentType === "subpremise") {
            return obj.componentName;
        }
    });
    let premise = Add?.filter((obj) => {
        if (obj?.componentType === "premise") {
            return obj.componentName;
        }
    });

    set((pre) => {
        let stDirection = null;
        let stDirection2 = null;
        let streetName = Street[0]?.componentName?.text || "";

        if (streetName) {
            let streetParts = streetName.split(" ");
            const possibleDirections = [
                "East",
                "West",
                "North",
                "South",
                "Northeast",
                "Northwest",
                "Northsouth",
                "Southeast",
                "Southwest",
                "Southnorth",
                "Eastwest",
                "Eastsouth",
                "Eastnorth",
                "Westeast",
                "Westsouth",
                "Westnorth",
            ];
            const possibleDirectionPairs = [
                "North East",
                "North West",
                "North South",
                "South East",
                "South West",
                "South North",
                "East West",
                "East South",
                "East North",
                "West East",
                "West South",
                "West North",
            ];

            // Check if the first two words form a direction pair
            const firstTwoWords = streetParts.slice(0, 2).join(" ");
            if (possibleDirectionPairs.includes(firstTwoWords)) {
                stDirection = firstTwoWords; // Assign the first two words as stDirection
                streetParts = streetParts.slice(2); // Remove the first two words
            } else if (possibleDirections.includes(streetParts[0])) {
                // Check if the first word alone is a direction
                stDirection = streetParts[0]; // Assign the first word as stDirection
                streetParts = streetParts.slice(1); // Remove the first word
            }

            // Check if the last two words form a direction pair
            const lastTwoWords = streetParts.slice(-2).join(" ");
            if (possibleDirectionPairs.includes(lastTwoWords)) {
                stDirection2 = lastTwoWords; // Assign the last two words as stDirection2
                streetParts = streetParts.slice(0, -2); // Remove the last two words
            } else if (
                possibleDirections.includes(streetParts[streetParts.length - 1])
            ) {
                // Check if the last word alone is a direction
                stDirection2 = streetParts[streetParts.length - 1]; // Assign the last word as stDirection2
                streetParts = streetParts.slice(0, -1); // Remove the last word
            }

            // Rejoin the remaining street parts to get the cleaned street name
            streetName = streetParts.join(" ");
        }

        return {
            ...pre,
            IsUsLocation: "Y",
            Street: streetName,
            City: City[0]?.componentName?.text
                ? City[0]?.componentName?.text
                : sublocality_level_1[0]?.componentName?.text
                    ? sublocality_level_1[0]?.componentName?.text
                    : neighborhood[0]?.componentName?.text
                        ? neighborhood[0]?.componentName?.text
                        : "",
            Country: country[0]?.componentName?.text
                ? country[0]?.componentName?.text
                : "",
            PremiseNo: street_number[0]?.componentName?.text
                ? street_number[0]?.componentName?.text
                : "",
            ZipCode: postal_code[0]?.componentName?.text
                ? postal_code[0]?.componentName?.text
                : "",
            TypeSufix:
                typeof sublocality_level_1[0]?.componentName?.text === "number"
                    ? sublocality_level_1[0]?.componentName?.text
                    : 0,
            DirectionSufix: administrative_area_level_1[0]?.componentName?.text
                ? administrative_area_level_1[0]?.componentName?.text
                : "",
            point_of_interest: point_of_interest[0]?.componentName?.text
                ? point_of_interest[0]?.PremiseNo?.text
                : "",
            neighborhood: neighborhood[0]?.componentName?.text
                ? neighborhood[0]?.componentName?.text
                : "",
            subpremise: "",
            premise: premise[0]?.componentName?.text
                ? premise[0]?.componentName?.text
                : "",
            coordinateX: LocationGeoCode?.longitude,
            coordinateY: LocationGeoCode?.latitude,
            DirectionPrefix: "",
            ApartmentNo: "",
            commonPlaceName: point_of_interest[0]?.componentName?.text
                ? point_of_interest[0]?.componentName?.text
                : subpremise[0]?.componentName?.text
                    ? subpremise[0]?.componentName?.text
                    : "",
            ApartmentType: "",
            Street_Parse: "",
            PremiseNo_Parse: "",
            DirectionPrefix_Parse: "",
            TypeSuffix_Parse: "",
            DirectionSuffix_Parse: "",
            ZipCodeID: "",
            CityID: "",
            CountryID: "",

            mileMarker: "",
            intersection1: "",
            intersection2: "",
            AltStreet: "",
            stDirection: stDirection || "", // Set the first direction
            stDirection2: stDirection2 || "", // Set the last direction if applicable
            isStreet: streetName ? true : false,
            isCity: City[0]?.componentName?.text ||
                sublocality_level_1[0]?.componentName?.text ||
                neighborhood[0]?.componentName?.text ? true : false,
            isPremiseNo: street_number[0]?.componentName?.text ? true : false,
            isZipCode: postal_code[0]?.componentName?.text ? true : false,
            isCommonPlaceName: point_of_interest[0]?.componentName?.text
                || subpremise[0]?.componentName?.text ? true : false,
            isStDirection: stDirection ? true : false,
            isStDirection2: stDirection2 ? true : false,
            isCoordinateX: LocationGeoCode?.longitude ? true : false,
            isCoordinateY: LocationGeoCode?.latitude ? true : false,
        };
    });
};
