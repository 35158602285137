import React, { memo } from 'react';
import Property_Tabs from '../../../../Components/Pages/Property/Property_Tabs';

const PropertyTabSectionModal = () => {

    return (
        <>
            <Property_Tabs isCad />
        </>
    );
};

export default memo(PropertyTabSectionModal);
